import React, { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IProfileStatus } from "./@types/users";
import { ModalWindow } from "./components/ModalWindow";
import ModalPrimaryTemplate from "./components/ModalWindow/templates/ModalPrimaryTemplate";
import { PATHNAMES } from "./constants/routes";
import { CompleteProfile } from "./page-components/CompleteProfile";
import CustomHistoryRouter from "./routes/CustomHistoryRouter";
import AppRoutes from "./routes/Router";
import { history } from "./services/history";
import useClientStore from "./store/client-store";

const queryClient = new QueryClient();

function App(): ReactElement {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { user } = useClientStore((state) => state);
  const pathname = window.location.pathname;

  const getClient = useClientStore((state) => state.getClient);

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    if (
      user &&
      pathname !== PATHNAMES.ACCOUNT &&
      pathname !== PATHNAMES.ACCOUNT_PERSONAl_DATA_MOBILE
    ) {
      user.profile_status === IProfileStatus.UNCOMPLETED_PROFILE &&
        setIsModalOpen(true);
    }
  }, [user]);

  const handleCompete = () => {
    setIsModalOpen(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CustomHistoryRouter history={history}>
        <div className="App flex h-screen flex-col overflow-y-auto overflow-x-hidden">
          <AppRoutes />
          <ToastContainer
            position="bottom-right"
            theme="light"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            rtl={false}
            transition={Bounce}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <ModalWindow
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isShownCloseButton={false}
          className="flex justify-center"
        >
          <ModalPrimaryTemplate
            onClose={() => setIsModalOpen(false)}
            className="w-[1200px]"
          >
            <CompleteProfile handleCompleteProfile={handleCompete} />
          </ModalPrimaryTemplate>
        </ModalWindow>
      </CustomHistoryRouter>
    </QueryClientProvider>
  );
}

export default App;
