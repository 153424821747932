import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionHeader } from "src/components/SectionHeader";
import SwiperBlock1Image from "src/assets/images/about-us/swipper/block1.png";
import SwiperBlock2Image from "src/assets/images/about-us/swipper/block2.png";

const WhyChooseUs = () => {
  return (
    <section className="container flex flex-col items-center gap-20 lg:flex-row">
      <SectionHeader
        titleClassName="text-start"
        title="Why Choose Us?"
        subtitle="We are the best"
        textClassName="!text-start"
        description="Sparkle the Cleaning Service has built a reputation for reliability and quality. Our clients are our biggest advocates, and we strive to earn their trust through exceptional service and attention to detail. Each team member is trained to uphold the highest standards, ensuring every job is completed to perfection. We take the time to understand your specific needs, delivering personalized solutions that fit your lifestyle. Experience the Sparkle difference, where your satisfaction is our top priority!"
      />

      <div className="w-full lg:w-1/2">
        <Swiper
          slidesPerView="auto"
          spaceBetween={40}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
        >
          <SwiperSlide className="h-auto w-full lg:h-[443px]">
            <img className="h-full" src={SwiperBlock1Image} alt="welcome" />
          </SwiperSlide>
          <SwiperSlide className="h-auto w-full lg:h-[443px]">
            <img className="h-full" src={SwiperBlock2Image} alt="welcome" />
          </SwiperSlide>
          <SwiperSlide className="lg:h-[443px]] h-auto w-full">
            <img className="h-full" src={SwiperBlock1Image} alt="welcome" />
          </SwiperSlide>
          <SwiperSlide className="h-auto w-full lg:h-[443px]">
            <img className="h-full" src={SwiperBlock2Image} alt="welcome" />
          </SwiperSlide>
        </Swiper>
        <div className={"mt-10 flex items-center justify-start gap-2"}>
          <div className="swiper-pagination" />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
