// import React, { FC, useEffect } from "react";
// import {
//   Form,
//   FormikConfig,
//   FormikProvider,
//   useFormik,
//   useFormikContext,
// } from "formik";
// import useClientStore from "src/store/client-store";
// import { Button } from "src/components/Button";
// import { ButtonVariants } from "src/components/Button/types";
// import { RenderFormFields } from "src/components/RenderFormFields";
// import { Sizes } from "src/@types/sizes";
// import {
//   RESET_PASSWORD_FORM_FIELDS,
//   RESET_PASSWORD_FORM_VALIDATION_SCHEMA,
//   RESET_PASSWORD_INITIAL_VALUES,
// } from "./constants";
// import { IFormikValues } from "./types";
// interface Props {
//   setResetPasswordFields: React.Dispatch<React.SetStateAction<any>>;
// }
// export const ResetPassword: FC<Props> = ({ setResetPasswordFields }) => {
//   const { isChangingPersonalData } = useClientStore();
//   const formik = useFormik({
//     initialValues: RESET_PASSWORD_INITIAL_VALUES,
//     validationSchema: RESET_PASSWORD_FORM_VALIDATION_SCHEMA,
//     onSubmit: (values) => {
//       console.log("Form submitted: ", values);
//     },
//   });
//   const { values } = useFormikContext<IFormikValues>();
//   useEffect(() => {
//     if (values) {
//       setResetPasswordFields({
//         old_password: values.current_password,
//         password: values.new_password,
//         password_confirmation: values.confirm_password,
//       });
//     }
//   }, [values, setResetPasswordFields]);
//   console.log("Form values: ", values);
//   return (
//     <FormikProvider value={formik}>
//       <Form className="mt-10">
//         <div className="flex gap-6">
//           <RenderFormFields
//             disabled={!isChangingPersonalData}
//             fields={RESET_PASSWORD_FORM_FIELDS}
//           />
//         </div>
//       </Form>
//     </FormikProvider>
//   );
// };
import React, { FC, useEffect, useState } from "react";
import useClientStore from "src/store/client-store";
import { TextInput } from "src/ui/TextInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Sizes } from "src/@types/sizes";
import { RESET_PASSWORD_INITIAL_VALUES } from "./constants";

interface Props {
  setResetPasswordFields: React.Dispatch<React.SetStateAction<any>>;
}

export const ResetPassword: FC<Props> = ({ setResetPasswordFields }) => {
  const { isChangingPersonalData } = useClientStore();
  const [formValues, setFormValues] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Здесь можно добавить валидацию, если нужно
  };

  useEffect(() => {
    if (formValues) {
      setResetPasswordFields({
        old_password: formValues.current_password,
        password: formValues.new_password,
        password_confirmation: formValues.confirm_password,
      });
    }
  }, [formValues, setResetPasswordFields]);

  return (
    <form onSubmit={handleSubmit} className="mt-10 px-4 lg:px-0">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        <TextInput
          name="current_password"
          type="text"
          label="Current password"
          value={isChangingPersonalData ? formValues.current_password : ""}
          onChange={handleChange}
          disabled={!isChangingPersonalData}
          className="col-span-1 !rounded-[10px]"
        />
        <TextInput
          name="new_password"
          type="text"
          label="New password"
          value={formValues.new_password}
          onChange={handleChange}
          disabled={!isChangingPersonalData}
          className="col-span-1 !rounded-[10px]"
        />
        <TextInput
          name="confirm_password"
          type="text"
          label="Password confirmation"
          value={formValues.confirm_password}
          onChange={handleChange}
          disabled={!isChangingPersonalData}
          className="col-span-1 !rounded-[10px]"
        />
      </div>
    </form>
  );
};
