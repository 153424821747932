import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "src/components/Avatar";
import { Rating } from "src/components/Rating";
import { IReview, IReviewCard } from "./types";

interface Props {
  reviewCard?: any;
}

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const ReviewsCard: FC<Props> = ({ reviewCard }) => {
  const [review, setReview] = useState<IReview>();

  useEffect(() => {
    if (reviewCard) {
      setReview(reviewCard);
    }
  }, [reviewCard]);

  if (!review) {
    return <p>Loading review...</p>;
  }

  return (
    <article className="w-full rounded-xl border pt-8 sm:min-w-[364px]">
      <Rating className="px-8" rating={review?.rating} />

      <p className="border-b px-8 pb-3.5 pt-5">{review?.comment}</p>

      <div className="p-[24px]">
        <Avatar avatarUrl={review?.avatar_url} />
        <div>
          <span className="text-lg font-semibold text-black-medium">
            {review?.from_name}
          </span>
          <p>{formatDate(review.created_at)}</p>
        </div>
      </div>
    </article>
  );
};
