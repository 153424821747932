// import Cookies from "js-cookie";
// import { instance } from "src/services/api-client";
// import { isTokenExpired } from "src/services/interceptors";
// import { create } from "zustand";
// import { devtools } from "zustand/middleware";
// import { NotificationService } from "src/helpers/notifications";
// import { AUTH_REFRESH_TOKEN } from "src/constants/cookiesKeys";
// import { SubscriptionType } from "src/@types/subscription";
// import { Users } from "src/@types/users";
// import { AvailableServices } from "./../components/Subscription/AvailableServices";
// import avatar from "./temp/avatar_temp.png";
// interface IClientStore {
//   step: number;
//   onPreviousStep: () => void;
//   onNextStep: () => void;
//   validateStep: (step: number) => boolean;
//   cleaning_type: { id: number; name: string; description: string };
//   team_size: { label: string; value: number };
//   house_size: number;
//   rooms: number;
//   floors: number;
//   bathrooms: number;
//   bed_linen_change: { label: string; value: string };
//   has_kids: boolean;
//   has_pets: boolean;
//   additional_notes: string;
//   date: string;
//   time: string;
//   // Maybe we'll switch to from - to
//   timeFrom: string;
//   timeTo: string;
//   zip_code: string;
//   available_cleaners: any[];
//   updateField: (fieldName: string, value: any) => void;
// }
// const useBookCleaningStore = create(
//   devtools<IClientStore>((set, get) => ({
//     step: 0,
//     team_size: { label: "", value: 0 },
//     cleaning_type: { id: 0, name: "", description: "" },
//     house_size: 0,
//     rooms: 0,
//     floors: 0,
//     bathrooms: 0,
//     bed_linen_change: { value: "no", label: "No" },
//     has_kids: false,
//     has_pets: false,
//     additional_notes: "",
//     date: "",
//     time: "",
//     timeFrom: "",
//     timeTo: "",
//     zip_code: "",
//     available_cleaners: [],
//     updateField: (fieldName, value) =>
//       set((state) => ({
//         ...state,
//         [fieldName]: value,
//       })),
//     onPreviousStep: () => {
//       set((state) => ({
//         ...state,
//         step: state.step - 1,
//       }));
//     },
//     onNextStep: () => {
//       set((state) => ({
//         ...state,
//         step: state.step + 1,
//       }));
//     },
//     validateStep: (step: number) => {
//       const {
//         cleaning_type,
//         team_size,
//         house_size,
//         rooms,
//         floors,
//         date,
//         time,
//         bathrooms,
//       } = get();
//       switch (step) {
//         case 0:
//           return cleaning_type.name !== "" && team_size.value > 0;
//         case 1:
//           return house_size > 0 && rooms > 0 && floors > 0 && bathrooms > 0;
//         case 2:
//           return date !== "" && time !== "";
//         default:
//           return true;
//       }
//     },
//   }))
// );
// export default useBookCleaningStore;
import { instance } from "src/services/api-client";
import { ErrorResponse } from "src/services/client-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { NotificationService } from "src/helpers/notifications";

export interface CleaningRequest {
  zip_code: string;
  date: string;
  // date_from: string; // Можно заменить на Date, если парсить при получении
  // date_to: string;
  team_size: number;
  house_size: number;
  rooms: number;
  floors: number;
  bathrooms: number;
  bed_linen_change: boolean;
  has_kids: boolean;
  has_pets: boolean;
  additional_notes: string;
  address: string;
  cleaner_id: number;
  cleaning_type_id: number;
}

interface IClientStore {
  step: number;
  onPreviousStep: () => void;
  onNextStep: () => void;
  validateStep: (step: number) => boolean;
  onSetStep: (step: number) => void;

  selected_cleaner: IAvailableCleaner | null;

  cleaning_type: { id: number; value: string; description: string };
  team_size: { label: string; value: number };
  house_size: number;
  rooms: number;
  floors: number;
  bathrooms: number;
  // bed_linen_change: { label: string; value: string };
  bed_linen_change: { value: string; label: string };
  has_kids: boolean;
  has_pets: boolean;
  additional_notes: string;
  date: string;
  time: string;
  timeFrom: string;
  timeTo: string;
  zip_code: string;
  address: string;
  updateField: (fieldName: string, value: any) => void;
  setCleaningType: (newType: any) => void;
  setTeamSize: (newSize: any) => void;
  cleaningRequest: (params: CleaningRequest) => void;
}

const useBookCleaningStore = create(
  devtools(
    persist<IClientStore>(
      (set, get) => ({
        step: 0,

        selected_cleaner: null,

        team_size: { label: "", value: 0 },
        cleaning_type: { id: 0, value: "", description: "" },
        house_size: 0,
        rooms: 0,
        floors: 0,
        bathrooms: 0,
        bed_linen_change: { value: "no", label: "No" },
        has_kids: false,
        has_pets: false,
        additional_notes: "",
        date: "",
        time: "",
        timeFrom: "",
        timeTo: "",
        zip_code: "",
        address: "",

        setCleaningType: (newType) => set({ cleaning_type: newType }),
        setTeamSize: (newSize) => set({ team_size: newSize }),

        updateField: (fieldName, value) =>
          set((state) => ({
            ...state,
            [fieldName]: value,
          })),

        onSetStep: (step: number) => {
          set((state) => ({
            ...state,
            step,
          }));
        },

        onPreviousStep: () => {
          set((state) => ({
            ...state,
            step: state.step - 1,
          }));
        },

        onNextStep: () => {
          set((state) => ({
            ...state,
            step: state.step + 1,
          }));
        },

        cleaningRequest: async (params) => {
          try {
            const response = await instance.post(
              "api/cleaning/cleaning-requests",
              params
            );
            NotificationService.success("Cleaning request has been created.");
            return response;
          } catch (error: ErrorResponse | any) {
            NotificationService.error(error.response.data.detail);
          }
        },
        validateStep: (step: number) => {
          const {
            cleaning_type,
            team_size,
            house_size,
            rooms,
            floors,
            bathrooms,
            date,
            timeFrom,
            timeTo,
            address,
          } = get();

          switch (step) {
            case 0:
              return cleaning_type.value !== "" && team_size.value > 0;
            case 1:
              return (
                house_size > 0 &&
                rooms > 0 &&
                floors > 0 &&
                bathrooms > 0 &&
                address !== ""
              );
            case 2:
              return (
                date !== "" &&
                timeFrom !== "" &&
                timeTo !== "" &&
                parseInt(timeFrom, 10) < parseInt(timeTo, 10)
              );
            default:
              return true;
          }
        },
      }),
      {
        name: "book-cleaning-store",
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state: IClientStore) => {
          const { step, ...rest }: any = state;
          return rest;
        },
      }
    )
  )
);

export default useBookCleaningStore;
