import React from "react";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import { IAvailableCleaner } from "src/services/client-service/types";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { SectionHeader } from "src/components/SectionHeader";
import { ReactComponent as ThirdStarIcon } from "src/assets/icons/star-third.svg";
import SwiperBlock1Image from "src/assets/images/about-us/swipper/block1.png";
import SwiperBlock2Image from "src/assets/images/about-us/swipper/block2.png";
import CleanerItemImage from "src/assets/images/cleaner-item.png";
import NoAvatar from "src/assets/images/noAvatar.png";
import { Sizes } from "src/@types/sizes";
import { ReviewsCard } from "../home/Reviews/ReviewsList/ReviewsCard";

const InfoItem = ({ title, info }: { title: string; info: string }) => {
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="text-xl font-medium capitalize leading-relaxed text-gray-dark">
        {title}
      </div>
      <div className="text-base font-semibold text-black-third">{info}</div>
    </div>
  );
};

interface ICleanerDetailsProps {
  isSelected?: boolean;
  onSelect?: (cleaner: IAvailableCleaner) => void;
  cleaner: IAvailableCleaner;
}

const CleanerDetails = ({
  isSelected,
  onSelect,
  cleaner,
}: ICleanerDetailsProps) => {
  const {
    isFetched: isReviewsFetched,
    isFetching: isReviewsFetching,
    data: reviewsData,
  } = useQuery({
    queryKey: ["getReviewsToCleaner"],
    queryFn: async () => clientServices.getReviewsToCleaner(cleaner.id),
  });

  if (!cleaner) return null;

  const {
    avatar_url,
    name,
    bio,
    rating,
    completed_cleanings,
    experience_years,
    cleaning_types,
  } = cleaner;

  const availableTypes = cleaning_types.map((type) => type.name);

  return (
    <div className="">
      <div className="mt-12 flex items-center justify-between rounded-t-[24px] bg-gray-dark p-2 lg:p-[42px]">
        <div className="flex items-center gap-8">
          <div className="flex h-[80px] w-[150px] items-center justify-center rounded-full bg-purple-base lg:h-[120px] lg:w-[120px]">
            <img src={avatar_url || NoAvatar} alt="Cleaner avatar" />
          </div>

          <div>
            <div className="text-white text-[18px] font-semibold text-white-base lg:text-[32px]">
              {name}
            </div>

            <div className="w-2/3">
              <span className="text-[14px] text-base font-medium leading-normal text-white-base lg:text-[16px]">
                Specializes in&nbsp;
              </span>
              <span className="text-base font-semibold leading-normal text-yellow-base">
                {availableTypes.join(", ")}
              </span>
            </div>

            <div className="mt-4 inline-block rounded-[30px] bg-white-base px-[10px] py-2 text-[10px] font-bold text-green-base">
              OPEN TO WORK
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <Button
            onClick={() => onSelect(cleaner)}
            size={Sizes.S}
            variant={
              isSelected
                ? ButtonVariants.PRIMARY
                : ButtonVariants.OPACITY_PRIMARY
            }
          >
            {isSelected ? "Cleaner Selected" : "Select a Cleaner"}
          </Button>
        </div>
      </div>

      <div className="flex flex-col overflow-x-auto rounded-b-[24px] border border-gray-dark lg:flex-row">
        <div className="botder-r-0 flex w-full flex-col gap-10 border-b border-gray-dark p-10 lg:w-1/3 lg:border-b-0 lg:border-r">
          <InfoItem
            info={`${completed_cleanings} done`}
            title="Completed cleanings"
          />
          <InfoItem info={`${experience_years} years`} title="Experience" />
          {/* <InfoItem info={`${rating} done`} title="Rate" />  на
          дизайні немає*/}
        </div>

        <div className="flex w-2/3 flex-col gap-10 p-10">
          <div>
            <h3 className="text-[32px] font-semibold text-gray-strong">
              General Information
            </h3>

            <div className="text-base font-light leading-normal text-black-third">
              {bio}
            </div>
          </div>

          {isReviewsFetching ? (
            <div>Loading...</div>
          ) : isReviewsFetched && reviewsData?.length === 0 ? (
            <div>Reviews Not Found</div>
          ) : (
            <div className="flex  flex-col gap-6">
              <div className="flex items-center justify-between">
                <h3 className="text-[32px] font-semibold text-gray-strong">
                  Reviews
                </h3>

                <div className="flex items-center gap-2">
                  <ThirdStarIcon /> {rating}
                </div>
              </div>

              <div>
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={40}
                  pagination={{
                    el: ".swiper-pagination-reviews",
                    clickable: true,
                  }}
                  modules={[Navigation, Pagination]}
                >
                  {/* <SwiperSlide className="w-[353px]">
                    <ReviewsCard
                      rating={4}
                      avatar={CleanerItemImage}
                      name="James Wong"
                      description="09-02-2024"
                      review="Team was on time, got everything done with smiles the whole time. Glass was sparkling, floors spotless, stove looked new. It was a great way to start our Thanksgiving holiday. Thank you for such great service."
                      url={""}
                    />
                  </SwiperSlide> */}
                </Swiper>
                <div className={"mt-10 flex items-center justify-end gap-2"}>
                  <div className="swiper-pagination-reviews" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CleanerDetails;
