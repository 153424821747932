export const PATHNAMES = {
  AUTH: "/auth",
  GOOGLE_AUTH: "/auth/google",

  HOME: "/",
  SUBSCRIPTION: "/subscriptions",
  ABOUT: "/about-us",
  SERVICES: "/services",

  ACCOUNT: "/account",
  ACCOUNT_PERSONAl_DATA: "/account/personal-data",
  ACCOUNT_PERSONAl_DATA_MOBILE: "/account/personal-data-mobile",
  ACCOUNT_CALENDAR: "/account/calendar",
  ACCOUNT_CALENDAR_MOBILE: "/account/calendar-mobile",
  ACCOUNT_REQUESTS: "/account/requests",
  ACCOUNT_REQUESTS_MOBILE: "/account/requests-mobile",
  ACCOUNT_BOOKINGS: "/account/bookings",
  ACCOUNT_BOOKINGS_MOBILE: "/account/bookings-mobile",
  ACCOUNT_REVIEW: "/account/review",
  ACCOUNT_REVIEW_MOBILE: "/account/review-mobile",
  ACCOUNT_REVIEW_CREATE: "/account/review/create",
  ACCOUNT_REVIEW_CREATE_MOBILE: "/account/review/create-mobile",

  BOOK_CLEANING: "/book-cleaning",
  AVAILABLE_CLEANERS: "/available-cleaners",
  BOOKING_DETAILS: "/booking-details",

  SIGN_UP: "/auth/sign-up",
  SIGN_IN: "/auth/sign-in",
  FORGET_PASSWORD: "/auth/forget-password",
  RESET_PASSWORD: "/auth/reset-password",
};
