import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import bookingServices, {
  ICleaningRatesData,
} from "src/services/booking-service";
import useBookCleaningStore, {
  CleaningRequest,
} from "src/store/book-cleaning-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import RateInformation from "src/components/Subscription/ServicePlan/SubscriptionPlans/RateInformation";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";

const InfoItem = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <div className="capitalize">
      <span className="font-medium">{title}</span>: <span>{value}</span>
    </div>
  );
};

const CleanerInfoItem = ({
  cleanerName,
  options,
}: {
  cleanerName: string;
  options: { title: string; value: string | number }[];
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-semibold capitalize text-gray-strong">
        {cleanerName}
      </div>

      <div className="flex flex-col gap-2">
        {options.map((option) => (
          <InfoItem
            key={option.title}
            title={option.title}
            value={option.value}
          />
        ))}
      </div>
    </div>
  );
};

const IMPORTANT_INFO_CLASSNAME =
  "text-gray-strong text-2xl font-semibold capitalize";

const BookingDetails = () => {
  const {
    house_size,
    cleaning_type,
    team_size,
    rooms,
    floors,
    bathrooms,
    bed_linen_change,
    has_kids,
    has_pets,
    additional_notes,
    date,
    time,
    timeFrom,
    timeTo,
    zip_code,
    selected_cleaner,
    address,
    updateField,
  } = useBookCleaningStore();

  console.log("zip_code: ", zip_code);
  console.log("address", address);
  const navigate = useNavigate();

  const [isRateInformationOpen, setIsRateInformationOpen] =
    React.useState(false);

  const { cleaningRequest } = useBookCleaningStore();

  const { data: ratesData, isFetching: isRatesFetching } = useQuery<
    ICleaningRatesData[]
  >({
    queryKey: ["getCleaningRates"],
    queryFn: async () =>
      bookingServices.getCleaningRates({
        team_size: team_size.value,
        cleaning_type_id: cleaning_type?.id,
      }),
  });

  const closeModal = () => {
    setIsRateInformationOpen(false);
  };
  const rate = ratesData && ratesData.length > 0 ? ratesData[0] : null;

  const handleBooking = () => {
    const dateWithoutTimezoneFrom = new Date(
      `${date}T${timeFrom.padStart(2, "0")}:00:00Z`
    ).toISOString();

    const dateWithoutTimezoneTo = new Date(
      `${date}T${timeTo.padStart(2, "0")}:00:00Z`
    ).toISOString();

    const dateWithoutTimezone = new Date(`${date}T13:00:00Z`)
      .toISOString()
      .slice(0, 19);
    const fullData: CleaningRequest = {
      zip_code,
      date: dateWithoutTimezoneFrom,
      // date_from: dateWithoutTimezoneFrom,
      // date_to: dateWithoutTimezoneTo,
      team_size: team_size.value,
      house_size,
      address,
      rooms,
      floors,
      bathrooms,
      bed_linen_change: bed_linen_change.value === "yes",
      has_kids,
      has_pets,
      additional_notes,
      cleaner_id: selected_cleaner.id,
      cleaning_type_id: cleaning_type.id,
    };

    try {
      cleaningRequest(fullData);
      navigate(PATHNAMES.AVAILABLE_CLEANERS);
    } catch (e) {
      console.log(e);
    }
  };

  const convertTo12HourFormat = (time?: string) => {
    if (!time || typeof time !== "string") return "";

    let hour: number, minute: number;

    if (time.includes(":")) {
      // Если формат "3:30"
      [hour, minute] = time.split(":").map(Number);
    } else {
      // Если формат просто "3"
      hour = Number(time);
      minute = 0; // По умолчанию минут нет, ставим 00
    }

    if (isNaN(hour) || isNaN(minute)) return ""; // Проверка на случай ошибки

    const period = hour < 12 ? "AM" : "PM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

    return `${formattedHour}:${minute.toString().padStart(2, "0")} ${period}`;
  };

  return (
    <PageWrapper>
      <div className="flex flex-col items-center gap-12 py-[100px]">
        <h2 className="">My booking details</h2>

        {isRatesFetching ? (
          <div>Loading</div>
        ) : (
          <div className="flex w-full max-w-[919px] flex-col items-center gap-12">
            <div className="flex w-full flex-col gap-10 rounded-[34px] border border-[#EFF0F6] bg-blue-ligther p-12">
              <div className="flex items-center justify-between border-b border-[#6B7A8F] pb-4 text-2xl capitalize text-gray-strong">
                <div>Order Details</div>
                <div>basic subscription</div>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-10">
                  <div className="flex items-start justify-between">
                    <div className="flex flex-col gap-4">
                      <div className={IMPORTANT_INFO_CLASSNAME}>
                        Cleaning information
                      </div>

                      <div className="flex flex-col gap-2 capitalize">
                        <InfoItem title="house size" value={house_size} />
                        <InfoItem title="number of floors" value={floors} />
                        <InfoItem title="room count" value={rooms} />
                        <InfoItem title="bathroom count" value={bathrooms} />
                        <InfoItem
                          title="bed linen change"
                          value={bed_linen_change.label}
                        />
                        <InfoItem
                          title="pets"
                          value={has_kids ? "With Pets" : "Without Pets"}
                        />
                        <InfoItem
                          title="kids"
                          value={has_kids ? "With Kids" : "Without Kids"}
                        />
                        <InfoItem
                          title="date and time"
                          value={`${date} ${convertTo12HourFormat(timeFrom)}`}
                        />
                      </div>
                    </div>

                    <div className={IMPORTANT_INFO_CLASSNAME}>
                      {rate?.cleaning_type?.name ? (
                        rate.cleaning_type.name
                      ) : (
                        <span className="text-red-500">
                          Cleaning type not specified
                        </span>
                      )}
                    </div>
                  </div>

                  {/* если больше 1 чистильщика */}
                  {/* <div>
                <div className="flex flex-col gap-4">
                  <div className={IMPORTANT_INFO_CLASSNAME}>
                    Information about the cleaners
                  </div>

                  <div className="flex flex-col gap-8">
                    <CleanerInfoItem
                      cleanerName={"Cleaner1"}
                      options={[
                        {
                          title: "name",
                          value: "Name",
                        },
                        {
                          title: "rating",
                          value: "5 stars",
                        },
                        {
                          title: "experience",
                          value: "3 years",
                        },
                        {
                          title: "minimum time",
                          value: "2 hours",
                        },
                      ]}
                    />

                    <CleanerInfoItem
                      cleanerName={"Cleaner2"}
                      options={[
                        {
                          title: "name",
                          value: selected_cleaner.name,
                        },
                        {
                          title: "rating",
                          value: selected_cleaner.rating,
                        },
                        {
                          title: "experience",
                          value: selected_cleaner.experience_years,
                        },
                        {
                          title: "minimum time",
                          value: "",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between border-t border-[#6B7A8F] pt-4 text-2xl capitalize text-gray-strong">
                <div>
                  Selected Cleaners:{" "}
                  <span className={IMPORTANT_INFO_CLASSNAME}>2</span>
                </div>
                <div>
                  Total Rate:{" "}
                  <span className="text-[32px] font-semibold text-yellow-base">
                    $50/hr
                  </span>
                </div>
              </div> */}

                  <div className="flex items-start justify-between">
                    <div className="flex flex-col gap-4">
                      <div className={IMPORTANT_INFO_CLASSNAME}>
                        Information about the cleaner
                      </div>

                      <div className="flex flex-col gap-2 capitalize">
                        <InfoItem title="name" value={selected_cleaner?.name} />
                        <InfoItem
                          title="rating"
                          value={selected_cleaner?.rating}
                        />
                        <InfoItem
                          title="experience"
                          value={selected_cleaner?.experience_years}
                        />
                        <InfoItem title="minimum time" value="2 hours" />
                      </div>
                    </div>

                    <div className={IMPORTANT_INFO_CLASSNAME}>
                      ${rate?.hourly_rate}/hr
                    </div>
                  </div>
                </div>

                <p
                  className="self-center text-base font-bold leading-normal text-yellow-base underline"
                  onClick={() => setIsRateInformationOpen(true)}
                >
                  How payment for services is made?
                </p>
              </div>
            </div>

            <Button
              size={Sizes.S}
              variant={ButtonVariants.PRIMARY}
              onClick={handleBooking}
            >
              Confirm the Booking
            </Button>

            <ModalWindow
              isOpen={isRateInformationOpen}
              onClose={closeModal}
              isShownCloseButton={false}
              className="flex max-w-[800px] justify-center"
            >
              <ModalPrimaryTemplate
                className="max-w-[800px]"
                onClose={closeModal}
              >
                <RateInformation />
              </ModalPrimaryTemplate>
            </ModalWindow>

            {/* <Link to={PATHNAMES.HOME}>
              <Button size={Sizes.S} variant={ButtonVariants.PRIMARY}>
                Confirm the Booking
              </Button>
            </Link> */}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default BookingDetails;
