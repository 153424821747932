import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import { Button } from "src/components/Button";
import { ReactComponent as MarkIcon } from "src/assets/icons/mark.svg";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";

interface Props {
  id: number;
  name: string;
  price_usd: number;
  isActive: boolean;
  isEven: boolean;
  onClick: (id: number) => void;
}

export const PlanItem: FC<Props> = ({
  id,
  name,
  price_usd,
  isEven,
  isActive,
  onClick,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobileValue = window.innerWidth < 768;
      setIsMobile(isMobileValue);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      onClick={() => onClick(id)}
      className={cn(
        "flex cursor-pointer justify-between rounded-2xl border border-transparent p-10 transition-all hover:border hover:border-gray-base",
        { "!border-black-base": isActive },
        { "bg-red-ligther": !isEven },
        { "bg-blue-ligther": isEven }
      )}
    >
      <div className="flex items-center gap-6">
        <Button
          className="h-9 w-9 rounded-full border bg-white-base disabled:!opacity-100"
          onClick={() => onClick(id)}
          isDisabled={isActive}
        >
          {isActive && <MarkIcon />}
        </Button>

        <div>
          <div className="flex gap-3">
            <StarIcon />
            <span className="text-purple-base">{name}</span>
          </div>
          <span className="text-46 font-medium text-black-ligth">
            &#36;{price_usd}
          </span>
        </div>
      </div>
    </div>
  );
};
