import { FC } from "react";
import { withFormikField } from "src/hoc/withFormikField";
import PhoneInput from "src/ui/PhoneInput";
import { TextInput } from "src/ui/TextInput";
import * as Yup from "yup";
import { EMAIL_VALIDATION_SCHEMA } from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { States } from "src/@types/users";
import { SelectInput } from "./FormikSelect";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "text-lg text-gray-strong";
const PLACEHOLDER = "Write here";

export const PERSONAL_DATA_INITIAL_VALUES: IFormikValues = {
  name: "",
  email: "",
  phone_number: "",
  state: "",
  city: "",
  zip_code: "",
};

const FormikPhoneInput = withFormikField(PhoneInput);
const FormikTextInput = withFormikField(TextInput);

export const PERSONAL_DATA_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "name",
    type: "text",
    label: "Full Name",
    component: FormikTextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
];

export const PERSONAL_DATA_FORM_FIELDS_EMAIL: IRenderFormField[] = [
  {
    name: "email",
    type: "email",
    label: "Email",
    component: FormikTextInput,
    disabled: true,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
];

export const PERSONAL_DATA_FORM_FIELDS2: IRenderFormField[] = [
  // {
  //   name: "email",
  //   type: "email",
  //   label: "Email",
  //   component: FormikTextInput,
  //   disabled: true,
  //   labelClassName: LABEL_CLASSNAME,
  //   placeholder: PLACEHOLDER,
  //   fieldClassName: "col-span-2",
  // },

  {
    name: "phone_number",
    type: "tel",
    label: "Cell Phone",
    component: FormikPhoneInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
  {
    name: "state",
    type: "select",
    label: "State",
    component: SelectInput,
    labelClassName: LABEL_CLASSNAME,
    fieldClassName: "col-span-2",
    placeholder: "Select your state",
    options: Object.entries(States).map(([label, value]) => ({
      label,
      value,
    })),
  },
  {
    name: "city",
    type: "text",
    label: "City",
    component: FormikTextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
  // {
  //   name: "zip_code",
  //   type: "text",
  //   label: "Zip",
  //   component: FormikTextInput,
  //   labelClassName: LABEL_CLASSNAME,
  //   placeholder: PLACEHOLDER,
  //   fieldClassName: "col-span-2",
  // },
];

export const PERSONAL_DATA_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  // name: Yup.string().required("Name is required"),
  // phone_number: Yup.string().required("Phone is required"),
  // state: Yup.string().required("State is required"),
  // address: Yup.string().required("Address is required"),
  // email: EMAIL_VALIDATION_SCHEMA,
  // current_password: Yup.string(),
  // new_password: Yup.string(),
  // confirm_password: Yup.string(),
  name: Yup.string(),
  phone_number: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  email: EMAIL_VALIDATION_SCHEMA,
  // zip_code: Yup.string()

  //   .matches(/^.{5}$||^.{9}$/, "Zip code must be either 5 or 9 digits")

  //   .required("Zip code is required"),
  current_password: Yup.string(),
  new_password: Yup.string(),
  confirm_password: Yup.string(),
});
