import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as InfoIcon } from "src/assets/icons/material-symbols_info-outline.svg";
import { ReactComponent as AvatarIcon } from "src/assets/icons/toollip_avatar1.svg";
import { IProfileStatus, IProfileStatusLabels } from "src/@types/users";
import { StatusIndicator } from "../AccountProfileHeader";

const Tooltip = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState("top"); // 'top' or 'bottom'
  const hideTimeoutRef = useRef(null);
  const hideDelay = 300;
  const tooltipRef = useRef(null);

  const profileStatuses = [
    IProfileStatus.UNCOMPLETED_PROFILE,
    IProfileStatus.UNSIGNED_AGREEMENT,
    IProfileStatus.FAILED_MODERATION,
    IProfileStatus.UNSUBSCRIBED,
    IProfileStatus.PUBLISHED,
  ];

  const formatStatus = (status) => IProfileStatusLabels[status] || status;

  // Determine optimal position based on viewport space
  useEffect(() => {
    const handleResize = () => {
      if (tooltipRef.current) {
        const rect = tooltipRef.current.getBoundingClientRect();
        const spaceAbove = rect.top;
        const spaceBelow = window.innerHeight - rect.bottom;
        setPosition(spaceAbove > spaceBelow ? "top" : "bottom");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isVisible]);

  const handleMouseEnter = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    setIsVisible(true);
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    const tooltipContent = document.querySelector(".tooltip-content");
    if (
      tooltipContent &&
      (tooltipContent.contains(relatedTarget) ||
        tooltipContent === relatedTarget)
    )
      return;
    hideTimeoutRef.current = setTimeout(() => {
      setIsVisible(false);
      hideTimeoutRef.current = null;
    }, hideDelay);
  };

  const handleTouchStart = () => setIsVisible(true);
  const handleTouchEnd = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setIsVisible(false);
      hideTimeoutRef.current = null;
    }, hideDelay);
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
      {isVisible && (
        <div
          ref={tooltipRef}
          className="tooltip-content absolute left-[90%] z-50 w-[250px] max-w-[90vw] rounded-md border border-[#D0D5DD] bg-white-base p-4 text-sm shadow-md md:w-[350px] lg:left-1/2 lg:-translate-x-1/2"
          style={{
            [position === "top" ? "bottom" : "top"]: "calc(100% + 0.5rem)",
            transformOrigin: position === "top" ? "bottom" : "top",
          }}
        >
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center gap-1">
              <InfoIcon />
              <p className="text-sm font-semibold">Status:</p>
            </div>
            <div className="text-sm font-normal">
              <p className="font-semibold text-[#F40B0B]">
                Reason for rejection: expired license.
              </p>
              <p className="text-[#7A7B83]">
                Please change your license and submit your profile for
                moderation and we will review it again.
              </p>
            </div>
            <div className="flex items-center gap-4">
              <AvatarIcon className="h-[48px] min-w-[48px]" />
              <p className="text-[#7A7B83]">
                <span className="font-semibold text-[#212331]">Note:</span> your
                status will be displayed immediately on your profile photo in
                the main menu.
              </p>
            </div>
            <div>
              <p className="mb-2 font-semibold text-[#212331]">
                Possible statuses:
              </p>
              <ul className="flex flex-col gap-2">
                {profileStatuses.map((status) => (
                  <li key={status} className="flex items-center gap-2">
                    <StatusIndicator status={status} />
                    <p>{formatStatus(status)}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
