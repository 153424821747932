import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import { ModalNotificationType } from "src/components/ModalWindow/constants";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import NotificationModalManager from "src/components/ModalWindow/templates/NotificationModalManager";
import { RenderFormFields } from "src/components/RenderFormFields";
import { useModal } from "src/hooks/useModal";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import {
  AUTH_FORM_FIELDS,
  AUTH_FORM_VALIDATION_SCHEMA,
  AUTH_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const AuthForm: FC = () => {
  const [notificationType, setNotificationType] =
    React.useState<ModalNotificationType | null>(null);
  const { isOpenModal, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { login, setAuthEmail } = useAuthStore((state) => state);
  const { getClient } = useClientStore((state) => state);

  const handleLoginSuccess = async () => {
    navigate(PATHNAMES.HOME);
    getClient();
    getClient();
  };

  const handleLoginFailure = async (email: string, error: AxiosError) => {
    const errorStatus = error.response?.status;

    if (errorStatus === 403) {
      setNotificationType(ModalNotificationType.EMAIL_VERIFICATION);
      setAuthEmail(email);
    } else if (errorStatus === 401) {
      setNotificationType(ModalNotificationType.INVALID_CREDENTIALS);
    } else {
      setNotificationType(ModalNotificationType.GENERAL_ERROR);
    }

    openModal();
  };

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: AUTH_INITIAL_VALUES,
    validationSchema: AUTH_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      login(
        values,
        () => handleLoginSuccess(),
        (error) => handleLoginFailure(values.email, error)
      );
    },
  };

  const formik = useFormik(formikProps);

  return (
    <div className="mt-15">
      <div className="flex w-full justify-center lg:justify-start">
        <h3>Sign In</h3>
      </div>

      <FormikProvider value={formik}>
        <Form className="mt-10">
          <div className="flex flex-col gap-10">
            <RenderFormFields fields={AUTH_FORM_FIELDS} />
          </div>

          <div className="mt-10 flex justify-end lg:mt-5">
            <Link className="text-yellow-base" to={PATHNAMES.FORGET_PASSWORD}>
              Forgot your password
            </Link>
          </div>

          <Button
            className="mt-12 w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            type="submit"
          >
            Sign In
          </Button>
        </Form>
      </FormikProvider>

      <ModalWindow
        isOpen={isOpenModal}
        onClose={closeModal}
        isShownCloseButton={false}
      >
        <ModalPrimaryTemplate onClose={closeModal}>
          <NotificationModalManager
            notificationType={notificationType}
            isOpen={isOpenModal}
            closeModal={closeModal}
          />
        </ModalPrimaryTemplate>
      </ModalWindow>
    </div>
  );
};
