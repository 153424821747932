// import React, { FC } from "react";
// import { Link } from "react-router-dom";
// import { AxiosError } from "axios";
// import cn from "classnames";
// import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
// import useAuthStore from "src/store/auth-store";
// import { Button } from "src/components/Button";
// import { ButtonVariants } from "src/components/Button/types";
// import { ModalWindow } from "src/components/ModalWindow";
// import { ModalNotificationType } from "src/components/ModalWindow/constants";
// import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
// import NotificationModalManager from "src/components/ModalWindow/templates/NotificationModalManager";
// import { RenderFormFields } from "src/components/RenderFormFields";
// import { useModal } from "src/hooks/useModal";
// import { PATHNAMES } from "src/constants/routes";
// import { Sizes } from "src/@types/sizes";
// import { Users } from "src/@types/users";
// import {
//   REGISTRATION_FORM_FIELDS,
//   REGISTRATION_FORM_VALIDATION_SCHEMA,
//   REGISTRATION_INITIAL_VALUES,
// } from "./constants";
// import { IFormikRegisterValues } from "./types";
// const DEFAULT_BUTTON_CLASSNAME =
//   "flex-1 rounded-full py-4 text-sm font-semibold text-[#393D5099]";
// export const RegistrationForm: FC = () => {
//   const [notificationType, setNotificationType] =
//     React.useState<ModalNotificationType | null>(null);
//   const { isOpenModal, openModal, closeModal } = useModal();
//   const [userType, setUserType] = React.useState(Users.CLIENT);
//   const { register, setAuthEmail } = useAuthStore((state) => state);
//   const handleRegisterSuccess = async (email: string) => {
//     setAuthEmail(email);
//     setNotificationType(ModalNotificationType.REGISTER_SUCCESS);
//     openModal();
//   };
//   const handleRegisterFailure = async (email: string, error: AxiosError) => {
//     const errorStatus = error.response?.status;
//     if (errorStatus === 403) {
//       setNotificationType(ModalNotificationType.EMAIL_VERIFICATION);
//       setAuthEmail(email);
//     } else if (errorStatus === 409) {
//       setNotificationType(ModalNotificationType.EMAIL_EXISTS);
//     } else {
//       setNotificationType(ModalNotificationType.GENERAL_ERROR);
//     }
//     openModal();
//   };
//   const formikProps: FormikConfig<IFormikRegisterValues> = {
//     initialValues: REGISTRATION_INITIAL_VALUES,
//     validationSchema: REGISTRATION_FORM_VALIDATION_SCHEMA,
//     onSubmit: (values) => {
//       values.type = userType;
//       register(
//         values,
//         () => handleRegisterSuccess(values.email),
//         (error) => handleRegisterFailure(values.email, error)
//       );
//     },
//   };
//   const formik = useFormik(formikProps);
//   return (
//     <div className="mt-15">
//       <div className="flex items-center justify-between gap-6">
//         <h3>Sign Up</h3>
//         <div className="flex w-full max-w-80 rounded-full border border-yellow-base bg-[#FBFBFB] p-1">
//           <Button
//             className={cn(DEFAULT_BUTTON_CLASSNAME, {
//               "bg-yellow-base !text-white-base": userType === Users.CLIENT,
//             })}
//             onClick={() => setUserType(Users.CLIENT)}
//           >
//             For Customers
//           </Button>
//           <Button
//             className={cn(DEFAULT_BUTTON_CLASSNAME, {
//               "bg-yellow-base !text-white-base": userType === Users.CLEANER,
//             })}
//             onClick={() => setUserType(Users.CLEANER)}
//           >
//             For cleaners
//           </Button>
//         </div>
//       </div>
//       <FormikProvider value={formik}>
//         <Form className="mt-10">
//           <div className="relative flex flex-col gap-6">
//             <RenderFormFields fields={REGISTRATION_FORM_FIELDS} />
//             <Link
//               className="absolute bottom-0 left-36 text-yellow-base"
//               to={PATHNAMES.HOME}
//             >
//               Terms of Service.
//             </Link>
//           </div>
//           <Button
//             className="mt-12 w-full"
//             size={Sizes.S}
//             variant={ButtonVariants.PRIMARY}
//             type="submit"
//           >
//             Sign Up
//           </Button>
//         </Form>
//       </FormikProvider>
//       <ModalWindow
//         isOpen={isOpenModal}
//         onClose={closeModal}
//         isShownCloseButton={false}
//       >
//         <ModalPrimaryTemplate onClose={closeModal}>
//           <NotificationModalManager
//             notificationType={notificationType}
//             isOpen={isOpenModal}
//             closeModal={closeModal}
//           />
//         </ModalPrimaryTemplate>
//       </ModalWindow>
//     </div>
//   );
// };
import React, { FC, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import cn from "classnames";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useAuthStore from "src/store/auth-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import { ModalNotificationType } from "src/components/ModalWindow/constants";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import NotificationModalManager from "src/components/ModalWindow/templates/NotificationModalManager";
import { RenderFormFields } from "src/components/RenderFormFields";
import { useModal } from "src/hooks/useModal";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import {
  REGISTRATION_FORM_FIELDS,
  REGISTRATION_FORM_VALIDATION_SCHEMA,
  REGISTRATION_INITIAL_VALUES,
} from "./constants";
import { IFormikRegisterValues } from "./types";

const DEFAULT_BUTTON_CLASSNAME =
  "flex-1 rounded-full py-4 text-sm font-semibold text-[#393D5099]";

export const RegistrationForm: FC = () => {
  const [notificationType, setNotificationType] =
    React.useState<ModalNotificationType | null>(null);
  const { isOpenModal, openModal, closeModal } = useModal();
  const [userType, setUserType] = React.useState<Users | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, setAuthEmail } = useAuthStore((state) => state);

  const handleRegisterSuccess = async (email: string) => {
    setAuthEmail(email);
    setNotificationType(ModalNotificationType.REGISTER_SUCCESS);

    openModal();
  };

  const handleRegisterFailure = async (email: string, error: AxiosError) => {
    const errorStatus = error.response?.status;

    if (errorStatus === 403) {
      setNotificationType(ModalNotificationType.EMAIL_VERIFICATION);
      setAuthEmail(email);
    } else if (errorStatus === 409) {
      setNotificationType(ModalNotificationType.EMAIL_EXISTS);
    } else {
      setNotificationType(ModalNotificationType.GENERAL_ERROR);
    }

    openModal();
  };

  const formikProps: FormikConfig<IFormikRegisterValues> = {
    initialValues: REGISTRATION_INITIAL_VALUES,
    validationSchema: REGISTRATION_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      values.type = userType;

      register(
        values,
        () => handleRegisterSuccess(values.email),
        (error) => handleRegisterFailure(values.email, error)
      );
    },
  };

  const formik = useFormik(formikProps);

  // Update query parameter on button click
  const handleUserTypeChange = (type: Users) => {
    setUserType(type);
    setSearchParams({ type: type.toLowerCase() });
  };

  // Handle query parameter on initial render
  useEffect(() => {
    const type = searchParams.get("type");
    if (type === "cleaner") {
      setUserType(Users.CLEANER);
    } else if (type === "client") {
      setUserType(Users.CLIENT);
    } else {
      setUserType(Users.CLIENT);
    }
  }, [searchParams]);

  return (
    <div className="mt-15">
      <div className="flex flex-col items-center justify-between gap-6 lg:flex-row">
        <h3>Sign Up</h3>

        <div className="flex w-full max-w-80 rounded-full border border-yellow-base bg-[#FBFBFB] p-1">
          <Button
            className={cn(DEFAULT_BUTTON_CLASSNAME, {
              "bg-yellow-base !text-white-base": userType === Users.CLIENT,
            })}
            onClick={() => handleUserTypeChange(Users.CLIENT)}
          >
            For Customers
          </Button>

          <Button
            className={cn(DEFAULT_BUTTON_CLASSNAME, {
              "bg-yellow-base !text-white-base": userType === Users.CLEANER,
            })}
            onClick={() => handleUserTypeChange(Users.CLEANER)}
          >
            For Cleaners
          </Button>
        </div>
      </div>

      <FormikProvider value={formik}>
        <Form className="mt-10">
          <div className="relative flex flex-col gap-14">
            <RenderFormFields fields={REGISTRATION_FORM_FIELDS} />

            <Link
              className="absolute bottom-0 left-36 text-yellow-base"
              to={PATHNAMES.HOME}
            >
              Terms of Service.
            </Link>
          </div>

          <Button
            className="mt-12 w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            type="submit"
          >
            Sign Up
          </Button>
        </Form>
      </FormikProvider>

      <ModalWindow
        isOpen={isOpenModal}
        onClose={closeModal}
        isShownCloseButton={false}
      >
        <ModalPrimaryTemplate onClose={closeModal}>
          <NotificationModalManager
            notificationType={notificationType}
            isOpen={isOpenModal}
            closeModal={closeModal}
          />
        </ModalPrimaryTemplate>
      </ModalWindow>
    </div>
  );
};
