import React, { useState } from "react";
import useClientStore from "src/store/client-store";
import { TextInput } from "src/ui/TextInput";
import { TextInput as SecondInput } from "src/components/FormField/TextInput";
import { ITeamMember } from "src/@types/users";
import BlockTitle from "../../BlockTitle";

const CleanerTeamEditForm = () => {
  const { user, updateUserField, isChangingPersonalData } = useClientStore();

  const handleAddMember = () => {
    updateUserField("team_members", [
      ...user.team_members,
      { name: "", experience_years: 0 },
    ]);
  };

  const handleDeleteMember = (index) => {
    const newTeamMembers = user.team_members.filter((_, i) => i !== index);
    updateUserField("team_members", newTeamMembers);
  };

  const handleInputChange = (index, field, value) => {
    const updatedTeamMembers = [...user.team_members];

    if (field === "experience_years") {
      const numericValue = value.toString().replace(/\D/g, "");
      updatedTeamMembers[index][field] = numericValue
        ? parseInt(numericValue, 10)
        : 0;
    } else {
      updatedTeamMembers[index][field] = value;
    }

    updateUserField("team_members", updatedTeamMembers);
  };

  // const handleExperienceInput = (index, value) => {
  //   console.log(value);
  //   if (user.team_members[index].experience_years === 0 && value) {
  //     const numericValue = value.replace(/\D/g, "");
  //     console.log(numericValue);
  //     if (numericValue) {
  //       handleInputChange(index, "experience_years", numericValue);
  //     }
  //   } else {
  //     const numericValue = value.replace(/\D/g, "");
  //     handleInputChange(index, "experience_years", numericValue);
  //   }
  //   return;
  // };

  return (
    <div className="mt-10 flex flex-col gap-6 px-4 lg:px-0">
      <BlockTitle title="My team" />
      <div className="flex flex-col gap-6">
        {user.team_members.map((member, index) => (
          <div key={index} className="flex flex-col gap-1">
            <div className="flex items-center justify-between gap-2">
              {/* <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                Member #{index + 1}
              </div> */}
              {isChangingPersonalData && (
                <button
                  type="button"
                  onClick={() => handleDeleteMember(index)}
                  className="text-red-500"
                >
                  Delete
                </button>
              )}
            </div>
            <div className="flex gap-2">
              <TextInput
                label={`Member #${index + 1}`}
                name={`name-${index}`}
                value={member.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                placeholder="Name & Surname"
                isShownError={false}
                error={""}
                className="!rounded-10"
                disabled={!isChangingPersonalData}
              />
              <SecondInput
                label="Experience"
                type="text"
                name={`experience_years-${index}`}
                value={member.experience_years || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const numericValue = value.replace(/\D/g, "");
                  try {
                    if (numericValue) {
                      handleInputChange(
                        index,
                        "experience_years",
                        parseInt(numericValue, 10)
                      );
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
                placeholder="Experience"
                className="!rounded-10"
                disabled={!isChangingPersonalData}
                maxLength={4}
              />
            </div>
          </div>
        ))}
      </div>
      {isChangingPersonalData && (
        <div className="flex items-center justify-center">
          <button
            type="button"
            onClick={handleAddMember}
            className="mt-4 text-blue-500"
          >
            + Add Member
          </button>
        </div>
      )}
    </div>
  );
};

export default CleanerTeamEditForm;
