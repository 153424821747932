import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { SectionHeader } from "src/components/SectionHeader";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import WelcomeImage from "src/assets/images/about-us/welcome/welcome.png";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import ValueItem from "./ValueItem";

const Welcome = () => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const { type } = useClientStore((state) => state);
  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLIENT });

  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  const scroll = () => {
    const topElement = document.getElementById("services-section");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="container flex items-center gap-20">
      <div className="hidden h-full w-full lg:block">
        <img className="h-full w-full" src={WelcomeImage} alt="welcome" />
      </div>

      <div className="flex flex-col gap-6">
        <SectionHeader
          title="Welcome to Sparkle the Cleaning Service!"
          subtitle="About Sparkle the Cleaning Service"
          titleClassName="text-start"
          description={
            <div className="flex flex-col gap-2 text-start">
              <p>
                At Sparkle, we believe a clean space leads to a happier,
                healthier life. That’s why we provide tailored cleaning services
                to meet your unique needs. Our experienced team uses
                eco-friendly products and advanced techniques to ensure your
                home or office shines.
              </p>
              <p>
                Whether you need a deep clean or regular maintenance, we’re
                dedicated to creating a pristine environment that enhances your
                well-being. Experience the Sparkle difference today!
              </p>
            </div>
          }
        />

        <div className="block h-full w-full lg:hidden">
          <img className="h-full w-full" src={WelcomeImage} alt="welcome" />
        </div>

        <div className="flex flex-col gap-10">
          <ul className="flex flex-col items-start gap-6 lg:flex-row">
            <ValueItem
              number={1}
              title="Affordable Price"
              description="Lorem ipsum dolor sit amet consectetur. Don ecornare ullam"
            />

            <div className="hidden h-[146px] w-[1px] bg-blue-ligth lg:block" />

            <ValueItem
              number={2}
              title="Experienced Team"
              description="Lorem ipsum dolor sit amet consectetur. Don ecornare ullam"
            />

            <div className="hidden h-[146px] w-[1px] bg-blue-ligth lg:block" />

            <ValueItem
              number={3}
              title="Safe and Trusted"
              description="Lorem ipsum dolor sit amet consectetur. Don ecornare ullam"
            />
          </ul>

          <div className="flex flex-col items-center gap-4 lg:flex-row">
            {!(isAuthorized && type === "cleaner") && (
            <Link to={bookNowHref} onClick={scroll}>
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
              >
                Book a Cleaner Now
              </Button>
            </Link>
            )}

            <Link to={cleanWithUsHref} onClick={scroll}>
              <Button
                size={Sizes.S}
                variant={ButtonVariants.OPACITY_PRIMARY}
                className="w-full"
              >
                Become A Cleaner With Us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
