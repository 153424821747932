import { FC, useEffect, useState } from "react";
import useAuthStore from "src/store/auth-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  newEmailAddress: string;
  setIsNextModalOpen: (value: boolean) => void;
  password: string;
}

export const ConfirmationModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  newEmailAddress,
  setIsNextModalOpen,
  password,
}) => {
  const { changeEmail } = useAuthStore((state) => state);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [countdown, setCountdown] = useState(60); // Начальный отсчет 60 секунд

  // Используем useEffect, чтобы обновить отсчет каждую секунду
  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1); // Уменьшаем оставшееся время
        } else {
          setIsButtonDisabled(false); // Разблокировать кнопку по истечении времени
        }
      }, 1000); // Обновляем каждую секунду

      // Очистить таймер при закрытии модалки или по завершении отсчета
      return () => clearTimeout(timer);
    }
  }, [isModalOpen, countdown]);

  const handleSendClick = () => {
    setIsModalOpen(false);
    setIsNextModalOpen(true);
  };

  const handleResendClick = () => {
    changeEmail({ new_email: newEmailAddress, password });
  };

  return (
    <ModalWindow
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isShownCloseButton={false}
      isActiveCloseClickOutside={true}
      className="flex justify-center"
    >
      <ModalPrimaryTemplate
        className="w-[375px] lg:w-[700px]"
        onClose={() => setIsModalOpen(false)}
      >
        <h3 className="text-center text-[28px]">Email Confirmation Sent</h3>
        <p className="text-center text-sm">
          We’ve sent a confirmation email to(
          <span className="whitespace-nowrap text-yellow-base">
            {newEmailAddress}
          </span>
          ) .<br />
          Please check your inbox and click the confirmation link to complete
          the update.
        </p>
        <div className="mt-5 flex w-full items-center justify-center gap-5">
          <Button
            variant={ButtonVariants.PRIMARY}
            className="px-5 py-2"
            onClick={handleResendClick}
            isDisabled={isButtonDisabled} // Отключаем кнопку на 1 минуту
          >
            {isButtonDisabled
              ? `Resend Email (${countdown}s)` // Показать обратный отсчет
              : "Resend Email"}
          </Button>
        </div>
      </ModalPrimaryTemplate>
    </ModalWindow>
  );
};
