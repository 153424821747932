import React, { FC } from "react";

export const AvailableServices: FC = () => {
  return (
    <div className="flex">
      <p className="mx-auto mt-10 w-max text-lg font-semibold text-black-ligth">
        Affordable Cleaning Services Delivered to Your Door—Get Started for as
        Low as <span className="text-yellow-base">$19.99!</span>
      </p>
    </div>
  );
};
