import { FC } from "react";
import { Link } from "react-router-dom";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useAuthStore from "src/store/auth-store";
import { TextInput } from "src/ui/TextInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import {
  NEW_EMAIL_FORM_FIELDS,
  NEW_EMAIL_INITIAL_VALUES,
  NEW_EMAIL_VALIDATION_SCHEMA,
} from "./constant";
import { IFormikValues } from "./types";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  newEmailAddress: string;
  setNewEmailAddress: (value: string) => void;
  setIsConfirmationModalOpen: (value: boolean) => void;
  setPassword: (value: string) => void;
}

export const ChangeEmail: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  newEmailAddress,
  setNewEmailAddress,
  setIsConfirmationModalOpen,
  setPassword,
}) => {
  const { changeEmail } = useAuthStore((state) => state);

  const handleSubmit = async (values: IFormikValues) => {
    try {
      const response = await changeEmail(values); // Ожидаем выполнения запроса
      if (response) {
        setNewEmailAddress(values.new_email);
        setPassword(values.password);
        setIsModalOpen(false);
        setIsConfirmationModalOpen(true);
      }
    } catch (error) {
      console.error("Error occurred while changing email:", error);
    }
  };

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: NEW_EMAIL_INITIAL_VALUES,
    validationSchema: NEW_EMAIL_VALIDATION_SCHEMA,
    onSubmit: (values) => handleSubmit(values),
  };

  const formik = useFormik(formikProps);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isShownCloseButton={false}
      isActiveCloseClickOutside={true}
      className="flex justify-center"
    >
      <ModalPrimaryTemplate
        className="w-[375px] lg:w-[700px]"
        onClose={() => setIsModalOpen(false)}
      >
        <h3 className="text-center text-[28px] lg:text-left">
          Change Your Email Address
        </h3>
        <p className="mt-5 text-center text-[16px]">
          We’ve noticed you’d like to change your email address. Please enter
          your new email below, and we’ll send a confirmation to update your
          account.
        </p>
        <div className="mt-5">
          <FormikProvider value={formik}>
            <Form>
              <RenderFormFields fields={NEW_EMAIL_FORM_FIELDS} />
              <Button
                variant={ButtonVariants.PRIMARY}
                className="mt-5 w-full px-5 py-2"
                type="submit"
              >
                Update email
              </Button>
            </Form>
          </FormikProvider>
        </div>

        <hr className="my-5 h-[1px] w-full bg-[#393D50]" />

        <p>Didn't get the email?</p>

        <dl className="mt-5">
          <li>Check your spam or junk folder.</li>
          <li>
            Still no luck? Contact our
            <Link className="ml-1 text-yellow-base" to={PATHNAMES.HOME}>
              support team&nbsp;
            </Link>
            for assistance.
          </li>
        </dl>
      </ModalPrimaryTemplate>
    </ModalWindow>
  );
};
