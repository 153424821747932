import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { isTokenExpired } from "src/services/interceptors";
import useClientStore from "src/store/client-store";
import { NotificationService } from "src/helpers/notifications";
import { AUTH_REFRESH_TOKEN } from "src/constants/cookiesKeys";
import { PATHNAMES } from "src/constants/routes";
import { ONLY_FOR, OnlyFor } from "./types";

interface Props {
  component: React.FC;
  onlyFor?: OnlyFor;
  redirectUrl?: string;
}

export const PrivateRoute = ({
  component: Component,
  onlyFor = ONLY_FOR.AUTHORIZED,
  redirectUrl = PATHNAMES.HOME,
}: Props) => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const isLoading = useClientStore((state) => state.isLoading);
  const type = useClientStore((state) => state.type);
  const refreshToken = Cookies.get(AUTH_REFRESH_TOKEN);
  const isRefreshTokenExpired = isTokenExpired(refreshToken);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If the refresh token is expired, clear authorization and redirect
  if (isRefreshTokenExpired) {
    useClientStore.getState().clearClient();
    NotificationService.error("Session expired. Please log in again.");
    return <Navigate to={PATHNAMES.SIGN_IN} replace />;
  }

  const { CLIENT, AUTHORIZED, UNAUTHORIZED } = ONLY_FOR;

  const isUserClient = isAuthorized && type === CLIENT;

  // if the route is for CLIENT only
  if (onlyFor === CLIENT && isUserClient) {
    return <Component />;
  }

  // if the route is for AUTHORIZED users (any type)
  if (onlyFor === AUTHORIZED && isAuthorized) {
    return <Component />;
  }

  // if the route is for UNAUTHORIZED users (not logged in)
  if (onlyFor === UNAUTHORIZED && !isAuthorized) {
    return <Component />;
  }

  // Default case: redirect if the conditions are not met
  return <Navigate to={redirectUrl} replace />;
};
