import React, { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { StatusIndicator } from "src/page-components/account/personal-data/AccountProfileHeader";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { IProfileStatusLabels, Users } from "src/@types/users";
import { ReactComponent as LogoutSvg } from "../../assets/icons/logout.svg";
import { Avatar } from "../Avatar";
import { BurgerButton } from "../BurgerButton";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { Logo } from "../Logo";
import { ActionsButtons } from "./ActionsButtons";
import { Navigation } from "./Navigation";

interface Props {
  isShown?: boolean;
}

export const Header: FC<Props> = ({ isShown = true }) => {
  const { user, type } = useClientStore();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setIsAuthorized(user.id !== 0);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  const handleScrollLock = useCallback(
    (lock: boolean) => {
      if (lock) {
        setScrollPosition(window.scrollY);
        document.body.style.position = "fixed";
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.width = "100%";
      } else {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
        window.scrollTo(0, scrollPosition);
      }
    },
    [scrollPosition]
  );

  const handleBurgerClick = useCallback(() => {
    setIsNavOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleScrollLock(isNavOpen);
    }, 10);

    return () => {
      clearTimeout(timer);
      if (!isNavOpen) {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
      }
    };
  }, [isNavOpen, handleScrollLock]);

  if (!isShown) return null;

  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  const formatStatus = (status: string) => {
    return (
      IProfileStatusLabels[status as keyof typeof IProfileStatusLabels] ||
      status
    );
  };

  const profileStatus = user?.profile_status || "UNCOMPLETED_PROFILE";

  const formattedStatus = formatStatus(profileStatus);

  return (
    <header className="relative bg-blue-dark">
      <div className="container flex items-center justify-between py-3">
        <Logo className="pr-28" />
        <div className="hidden items-center gap-0 lg:flex">
          <Navigation />
          <ActionsButtons />
        </div>

        <div className="flex items-center lg:hidden">
          {isAuthorized && user?.avatar_url ? (
            <Link
              to={cleanWithUsHref}
              className="flex w-full justify-center sm:w-auto"
            >
              <Button
                size={Sizes.S}
                variant={!isAuthorized && ButtonVariants.OPACITY_PRIMARY}
              >
                <div className="relative flex items-center gap-2">
                  <Avatar avatarUrl={user?.avatar_url} />
                  <div className="absolute bottom-2 right-0 top-0">
                    <StatusIndicator status={profileStatus} />
                  </div>
                </div>
              </Button>
            </Link>
          ) : (
            <></>
          )}
          <BurgerButton isOpen={isNavOpen} onClick={handleBurgerClick} />
        </div>
      </div>
      <div
        className={`${
          isNavOpen
            ? "pointer-events-auto opacity-100"
            : "pointer-events-none opacity-0"
        } fixed bottom-0 left-0 right-0 top-[102px] z-10 h-screen overflow-y-auto bg-white-base transition-opacity duration-300 lg:hidden`}
      >
        <div className="mx-4 mb-4 mt-8 border-b py-2">
          {isAuthorized && (
            <>
              <div className="flex flex-col items-center gap-3 px-4">
                <Avatar avatarUrl={user?.avatar_url} className="h-16 w-16" />
                <div className="text-center">
                  <p className="text-[16px] font-bold text-[#F4B10B]">
                    {user.name}
                  </p>
                  <p className="font-montserrat text-[14px] font-normal">
                    {type?.charAt(0).toUpperCase() + type?.slice(1)}
                  </p>
                </div>
              </div>
              {user && (
                <div className="flex items-center justify-center gap-2 px-4 py-2">
                  <p>Status: </p>
                  <span>{formattedStatus}</span>
                  <StatusIndicator status={profileStatus} />
                </div>
              )}
            </>
          )}
          {/*  */}
        </div>
        <Navigation isMobile />
        <div className="flex w-full justify-center pb-4 pt-2">
          {/* <hr className="w-11/12 border-t border-gray-200" /> */}
        </div>
        <div className="w-full px-6 pt-4">
          <ActionsButtons />
        </div>
      </div>
    </header>
  );
};
