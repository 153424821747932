import React, { FC, KeyboardEvent, useState } from "react";
import cn from "classnames";
import { useField, useFormikContext } from "formik";
import { IFormField } from "src/@types/form";
import { FormField } from "src/components/FormField";
import { TEXT_INPUT_STYLE_VARIANTS } from "src/components/FormField/constants";
import { FormFieldVariants } from "src/components/FormField/types";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const EyeIcon = FaEye as unknown as FC;
const EyeSlashIcon = FaEyeSlash as unknown as FC;

export const PasswordInput: FC<IFormField> = ({
                                                className,
                                                fieldClassName,
                                                labelClassName,
                                                id,
                                                label,
                                                variant = FormFieldVariants.PRIMARY,
                                                placeholder,
                                                disabled,
                                                onChange,
                                                value,
                                                maxLength,
                                                ...props
                                              }) => {
  const [showPassword, setShowPassword] = useState(false);
  const fieldId = id || props.name;

  const { handleSubmit } = useFormikContext();
  const [
    { value: valueFormik, onChange: formikOnChange, ...field },
    { error, touched },
  ] = useField(fieldId);
  const isShownError = Boolean((touched || value) && error);

  const currentValue = value !== undefined ? value : valueFormik;

  const onKeyDownEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const combinedClassNames = cn(
    "w-full outline-none disabled:bg-[#D0D5DD33] disabled:cursor-default disabled:border-[#D0D5DD33] pr-10",
    TEXT_INPUT_STYLE_VARIANTS[variant],
    className
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikOnChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormField
      className={fieldClassName}
      labelClassName={labelClassName}
      variant={variant}
      label={label}
      labelFor={fieldId}
      isShownError={isShownError}
      error={error}
    >
      <div className="relative">
        <input
          id={fieldId}
          className={combinedClassNames}
          value={currentValue}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={handleChange}
          {...props}
          {...field}
          type={showPassword ? "text" : "password"}
          onKeyDown={onKeyDownEnter}
        />
        {currentValue && currentValue.toString().length > 0 && (
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
            className="absolute inset-y-0 right-0 flex items-center pr-5 text-gray-500"
          >
            {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </button>
        )}
      </div>
    </FormField>
  );
};
