import { Link, Outlet, useLocation } from "react-router-dom";
import cn from "classnames";
import useClientStore from "src/store/client-store";
import { isCleanerType, isClientType } from "src/utils/getUserType";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-right-dark.svg";
import { ACCOUNT_TABS_ITEMS } from "./constants";

export const AccountTabs = () => {
  const { pathname } = useLocation();
  const { type } = useClientStore((state) => state);

  const isActive = (href: string) =>
    (pathname === PATHNAMES.ACCOUNT &&
      href === PATHNAMES.ACCOUNT_PERSONAl_DATA) ||
    pathname.includes(href);

  return (
    <>
      <div className="mt-10 flex flex-col lg:hidden">
        {ACCOUNT_TABS_ITEMS.map(({ id, label, href, mobileHref }) => {
          if (isClientType(type) && href === PATHNAMES.ACCOUNT_CALENDAR) {
            return null;
          }
          if (isClientType(type) && id === "requests") {
            return null;
          }
          if (isCleanerType(type) && id === "bookings") {
            return null;
          }

          return (
            <div
              key={id}
              className="flex w-full items-center justify-between border-b-[1px] border-[#a6a8b0] pb-5 pt-2 text-start text-sm font-semibold"
            >
              <Link
                className={cn("", { active: isActive(href) })}
                to={mobileHref}
              >
                {label}
              </Link>
              <ArrowIcon className="mr-2" />
            </div>
          );
        })}
      </div>
      <div className="mt-10 hidden lg:flex">
        {ACCOUNT_TABS_ITEMS.map(({ id, label, href }) => {
          if (isClientType(type) && href === PATHNAMES.ACCOUNT_CALENDAR) {
            return null;
          }
          if (isClientType(type) && id === "requests") {
            return null;
          }
          if (isCleanerType(type) && id === "bookings") {
            return null;
          }

          return (
            <Link
              key={id}
              className={cn(
                "flex-1 border-b-2 pb-2 text-center text-sm font-semibold",
                {
                  "border-black-base": isActive(href),
                }
              )}
              to={href}
            >
              {label}
            </Link>
          );
        })}
      </div>
    </>
  );
};
