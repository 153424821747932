import React from "react";

interface Props {
  checked: boolean;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
}

const RadioCheckbox: React.FC<Props> = ({
  checked,
  onChangeValue,
  name,
  label,
}) => {
  return (
    <label className="flex cursor-pointer items-center gap-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChangeValue}
        name={name}
        className="hidden"
      />
      <div
        className={`flex h-5 w-5 items-center justify-center rounded-full border-2 bg-white-base ${checked ? "border-blue-500" : "border-gray-400"}`}
      >
        {checked && <div className="h-3 w-3 rounded-full bg-blue-500"></div>}
      </div>
      <span>{label}</span>
    </label>
  );
};

export default RadioCheckbox;
