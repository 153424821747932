import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import bookingServices from "src/services/booking-service";
import { ICreateCleanerSchedules } from "src/services/client-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import useBookCleaningStore from "src/store/book-cleaning-store";
import useClientStore from "src/store/client-store";
import CleanerDetails from "src/page-components/available-cleaners/CleanerDetails";
import CleanerItem from "src/page-components/available-cleaners/CleanerItem";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import SearchField from "src/components/FormField/SearchField";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { useModal } from "src/hooks/useModal";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter.svg";
import { ReactComponent as SortIcon } from "src/assets/icons/sort.svg";
import { Sizes } from "src/@types/sizes";
import RateInformation from "src/components/Subscription/ServicePlan/SubscriptionPlans/RateInformation";

interface ICleanersListProps {
  cleaners: IAvailableCleaner[];
}

const TestCleaner = [
  {
    id: 1,
    name: "John Doe",
    experience_years: 5,
    bio: "Professional cleaner with 5 years of experience.",
    completed_cleanings: 50,
    rating: 4.8,
    status: "PENDING",
    reviews_received: [
      {
        comment: "Great job, very thorough!",
        rating: 5,
        from_name: "Jane Smith",
        created_at: "2025-01-25T14:12:30.703Z",
      },
    ],
    cleaning_types: [
      {
        id: 1,
        name: "Residential",
        description: "Cleaning for homes and apartments.",
      },
      {
        id: 2,
        name: "Commercial",
        description: "Office and business space cleaning.",
      },
    ],
    team_size: 2,
  },
  {
    id: 2,
    name: "Alice Green",
    experience_years: 3,
    bio: "Experienced in both residential and commercial cleaning.",
    completed_cleanings: 30,
    rating: 4.2,
    status: "IN_PROGRESS",
    reviews_received: [
      {
        comment: "Good work but could be more detail-oriented.",
        rating: 3,
        from_name: "Samuel Turner",
        created_at: "2025-01-26T09:45:00.203Z",
      },
    ],
    cleaning_types: [
      {
        id: 1,
        name: "Residential",
        description: "Cleaning for homes and apartments.",
      },
    ],
    team_size: 1,
  },
  {
    id: 3,
    name: "Mark Black",
    experience_years: 10,
    bio: "Over 10 years of cleaning experience in various sectors.",
    completed_cleanings: 150,
    rating: 5.0,
    status: "DONE",
    reviews_received: [
      {
        comment: "Absolutely spotless! Would hire again.",
        rating: 5,
        from_name: "Emily White",
        created_at: "2025-01-24T18:30:45.503Z",
      },
    ],
    cleaning_types: [
      {
        id: 1,
        name: "Residential",
        description: "Cleaning for homes and apartments.",
      },
      {
        id: 3,
        name: "Post-construction",
        description: "Cleaning after construction or renovation.",
      },
    ],
    team_size: 4,
  },
  {
    id: 4,
    name: "Eve Blue",
    experience_years: 2,
    bio: "New to the cleaning industry but passionate about providing excellent service.",
    completed_cleanings: 15,
    rating: 3.9,
    status: "DECLINED",
    reviews_received: [
      {
        comment: "She tried hard, but I expected better results.",
        rating: 3,
        from_name: "John Brown",
        created_at: "2025-01-20T11:10:00.103Z",
      },
    ],
    cleaning_types: [
      {
        id: 2,
        name: "Commercial",
        description: "Office and business space cleaning.",
      },
    ],
    team_size: 1,
  },
];

const CleanersList = ({ cleaners }: ICleanersListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selected_cleaner, updateField } = useBookCleaningStore();
  const [detailsCleaner, setDetailsCleaner] = useState<IAvailableCleaner>(null);

  const { isOpenModal, openModal, closeModal } = useModal(false);
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const navigate = useNavigate();

  //if user is not authorized, redirect to subscription page
  // const onClickCleanerItem = () => {
  //   if (isAuthorized) {
  //     openModal();
  //   } else {
  //     navigate(PATHNAMES.SUBSCRIPTION);
  //   }
  // };

  const handleSelectCleaner = (cleaner: IAvailableCleaner) => {
    if (selected_cleaner?.id === cleaner.id) {
      updateField("selected_cleaner", null);
      return;
    }

    updateField("selected_cleaner", cleaner);
  };

  const handleDetailsCleaner = (cleaner: IAvailableCleaner) => {
    setDetailsCleaner(cleaner);
    openModal();
  };

  console.log("Cleaners: ", cleaners);

  return (
    <>
      <div className="container flex flex-col gap-10">
        <div className="flex w-full flex-col justify-between lg:flex-row lg:items-center">
          <h2 className="text-start text-[28px] font-semibold leading-[64px] text-gray-strong lg:text-center lg:text-[46px]">
            Select the cleaner
          </h2>

          <div className="flex w-full flex-col-reverse items-center gap-12 lg:flex-row">
            <div className="flex w-full items-center gap-8">
              <div className="flex w-full items-center gap-2">
                <FilterIcon /> Filter
              </div>

              <div className="flex items-center gap-2">
                <SortIcon /> Sort
              </div>
            </div>

            <div className="flex w-full items-center gap-2">
              <SearchField placeholder="Search..." />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
          {cleaners?.map((item) => (
            <CleanerItem
              key={item.id}
              isSelected={selected_cleaner?.id === item.id}
              onSelectCleaner={() => handleSelectCleaner(item)}
              onClickDetail={() => handleDetailsCleaner(item)}
              {...item}
            />
          ))}
        </div>

        <div className="flex flex-col items-center gap-6">
          <Link to={PATHNAMES.BOOKING_DETAILS}>
            <Button
              isDisabled={selected_cleaner === null}
              size={Sizes.S}
              variant={ButtonVariants.PRIMARY}
            >
              Finish
            </Button>
          </Link>

          <button
            className="text-yellow-base underline"
            onClick={() => setIsModalOpen(true)}
          >
            How is the rate calculated?
          </button>

        </div>
      </div>

      <ModalWindow
        isShownCloseButton={false}
        onClose={closeModal}
        isOpen={isOpenModal}
        className="flex justify-center"
      >
        <ModalPrimaryTemplate
          className="!max-w-[1400px] items-center justify-center p-4"
          onClose={closeModal}
        >
          <div className="min-w-[350px] lg:min-w-[800px]">
            <CleanerDetails
              isSelected={selected_cleaner?.id === detailsCleaner?.id}
              onSelect={handleSelectCleaner}
              cleaner={detailsCleaner}
            />
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isShownCloseButton={false}
      >
        <ModalPrimaryTemplate onClose={() => setIsModalOpen(false)}>
          <RateInformation />
        </ModalPrimaryTemplate>
      </ModalWindow>
    </>
  );
};

export default CleanersList;
