import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import BlockTitle from "src/page-components/account/BlockTitle";
import { RequestItem as ClientrequestItem } from "src/page-components/account/bookings/RequestItem";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { ReviewsList } from "src/page-components/home/Reviews/ReviewsList";
import { ClientDetails } from "src/page-components/requests/ClientDetails";
import RequestItem from "src/page-components/requests/RequestItem";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { Header } from "src/components/Header";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { Rating } from "src/components/Rating";
import NumberedRating from "src/components/Rating/NumberedRating";
import { useModal } from "src/hooks/useModal";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ICleaningRequest, RequestStatus } from "src/@types/request";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";

const Review: FC = () => {
  const { user, type, updateField, isChangingPersonalData } = useClientStore(
    (state) => state
  );

  console.log("User: ", user);
  const cleanerId = user.id;
  const {
    isFetched: isReviewsFetched,
    isFetching: isReviewsFetching,
    data: reviewsData,
  } = useQuery({
    queryKey: ["getReviewsToCleaner"],
    queryFn: () => {
      if (type === Users.CLEANER) {
        return clientServices.getReviewsToCleaner(cleanerId);
      } else {
        return clientServices.getReviewsToClient(user.id);
      }
    },
  });

  const {
    data: myReviews,
    isFetched: isMyReviewsFetched,
    isFetching: isMyReviewsFetching,
  } = useQuery({
    queryKey: ["getMyReviews"],
    queryFn: () => {
      if (type === Users.CLEANER) {
        return clientServices.getReviewFromCLeaner(user.id);
      } else {
        return clientServices.getReviewsFromCLient(user.id);
      }
    },
  });

  const {
    data: requests,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["clientRequests"],
    queryFn: () => {
      if (type === Users.CLIENT) {
        return clientServices.getClientBookings();
      } else {
        return clientServices.getCleanersBookings();
      }
    },
  });

  const [showHistory, setShowHistory] = useState(true);
  const [showReviewHistory, setReviewHistory] = useState(true);
  const [detailRequest, setDetailRequest] = useState(null);
  const { isOpenModal, openModal, closeModal } = useModal(false);
  const [showAll, setShowAll] = useState(false);

  const filteredRequests = requests?.filter(
    (booking) => booking.status === RequestStatus.DONE
  );

  const displayedRequests = showAll
    ? filteredRequests
    : filteredRequests?.slice(0, 5);

  const navigate = useNavigate();

  const toggleHistory = () => {
    setShowHistory((prev) => !prev);
  };

  const toggleReviewHistory = () => {
    setReviewHistory((prev) => !prev);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDetailsCleaner = (request: ICleaningRequest) => {
    console.log("SELECTED SELCTED CLEANER: ", request);
    setDetailRequest(request);
    openModal();
  };

  console.log("Reviews in REviewC: ", reviewsData);

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div className="flex flex-col gap-10 pt-[54px]">
        <div className="flex w-full justify-between p-4 lg:hidden">
          <Title title="Reviews" subtitle="" />
          <p onClick={handleBackClick}>Back</p>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-6">
            <div className="flex items-center justify-between px-4 lg:px-0">
              <BlockTitle title="My Reviews " />

              <NumberedRating
                className="gap-2"
                starClassName="w-6 h-6"
                numberClassName="text-2xl font-semibold leading-7"
                rating={user?.rating}
              />
            </div>

            <ReviewsList reviews={reviewsData} />
          </div>

          {/*{reviewsData?.length > 3 && (*/}
          {/*  <Button*/}
          {/*    size={Sizes.S}*/}
          {/*    variant={ButtonVariants.PRIMARY}*/}
          {/*    className="w-[90%] self-center lg:w-auto lg:self-end"*/}
          {/*  >*/}
          {/*    See More*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>

        <div className="flex flex-col gap-6 px-4 md:px-0">
          <div className="flex w-full justify-between">
            <BlockTitle title="Booking History" />
            <button
              onClick={toggleHistory}
              className="text-sm font-medium text-blue-500 hover:underline"
            >
              <ArrowIcon />
            </button>
          </div>
          {type === Users.CLEANER &&
            (filteredRequests === undefined ? (
              <p>Loading requests...</p>
            ) : filteredRequests.length > 0 ? (
              <>
                {showHistory && (
                  <div className="flex flex-col gap-6">
                    {displayedRequests.map((booking) => (
                      <RequestItem
                        key={booking.id}
                        request={booking}
                        refetch={refetch}
                        onClickDetail={() => handleDetailsCleaner(booking)}
                      />
                    ))}
                  </div>
                )}
                {!showAll && filteredRequests.length > 5 && (
                  <button onClick={() => setShowAll(true)} className="btn">
                    View More
                  </button>
                )}
              </>
            ) : (
              <p>You haven't completed any cleanings yet.</p>
            ))}
          {type === Users.CLIENT &&
            (filteredRequests === undefined ? (
              <p>Loading requests...</p>
            ) : filteredRequests.length > 0 ? (
              <>
                {showHistory && (
                  <div className="flex flex-col gap-6">
                    {displayedRequests.map((booking) => (
                      <ClientrequestItem
                        key={booking.id}
                        booking={booking}
                        refetch={refetch}
                        // onClickDetail={() => handleDetailsCleaner(booking)}
                      />
                    ))}
                  </div>
                )}
                {!showAll && filteredRequests.length > 5 && (
                  <button onClick={() => setShowAll(true)} className="btn">
                    View More
                  </button>
                )}
              </>
            ) : (
              <p>You haven't completed any cleanings yet.</p>
            ))}
        </div>

        <div className="flex flex-col gap-6 px-4 md:px-0">
          <div className="flex w-full items-center justify-between">
            <BlockTitle title="Reviews history" />

            <button
              onClick={toggleReviewHistory}
              className="text-sm font-medium text-blue-500 hover:underline"
            >
              <ArrowIcon />
            </button>
          </div>
          {showReviewHistory && (
            <div className="flex flex-col gap-6">
              <ReviewsList reviews={myReviews} />
            </div>
          )}
        </div>
      </div>
      <div className="block lg:hidden">
        <Footer />
        <ModalWindow
          isShownCloseButton={false}
          isOpen={isOpenModal}
          className="flex justify-center"
          onClose={closeModal}
        >
          <ModalPrimaryTemplate
            className="!max-w-[1400px] items-center justify-center p-4"
            onClose={closeModal}
          >
            <div className="min-w-[350px] lg:min-w-[800px]">
              <ClientDetails request={detailRequest} />
            </div>
          </ModalPrimaryTemplate>
        </ModalWindow>
      </div>
    </>
  );
};

export default Review;
