import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  ErrorMessage,
  Field,
  Form,
  FormikConfig,
  FormikProvider,
  useFormik,
} from "formik";
import { withFormikField } from "src/hoc/withFormikField";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import { ChangeEmail } from "src/page-components/ChangeEmail/ChangeEmail";
import { ConfirmationModal } from "src/page-components/ChangeEmail/ConfirmationModal";
import { EmailSuccess } from "src/page-components/ChangeEmail/EmailSuccess";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { TextInput } from "src/components/FormField/TextInput";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { RenderFormFields } from "src/components/RenderFormFields";
import { NotificationService } from "src/helpers/notifications";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { IZipCodes } from "src/@types/users";
import { ResetPassword } from "../ResetPassword";
import { RESET_PASSWORD_FORM_FIELDS } from "../ResetPassword/constants";
import {
  PERSONAL_DATA_FORM_FIELDS,
  PERSONAL_DATA_FORM_FIELDS2,
  PERSONAL_DATA_FORM_FIELDS_EMAIL,
  PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
  PERSONAL_DATA_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const EditForm: FC = () => {
  const { user, type, updateField, isChangingPersonalData, getClient } =
    useClientStore((state) => state);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [newEmailAddress, setNewEmailAddress] = React.useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isSuccesModalOpen, setIsSuccesModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    if (user.zip_code) {
      setZipCode([user.zip_code]);
    }
  }, [user.zip_code]);

  const [zip_code, setZipCode] = useState<any>(); // Current zip code (optional)

  const [resetPasswordFields, setResetPasswordFields] = React.useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("email-change-success") === "True") {
      setIsSuccesModalOpen(true);
    }
  }, [location]);

  const handleSubmit = async (
    values: IFormikValues,
    initialValues: IFormikValues
  ) => {
    const cleanedPhoneNumber = values.phone_number.replace(/\s+/g, "");

    const updatedValues = {
      ...values,
      phone_number: cleanedPhoneNumber,
      zip_code: Array.isArray(values.zip_code)
        ? values.zip_code[0]
        : values.zip_code,
    };

    const updatedFields = getUpdatedFields(initialValues, updatedValues);
    console.log("Updated fields: ", updatedFields);

    if (Object.keys(updatedFields).length > 0) {
      const dataInfo = {
        id: user.id,
        ...updatedFields,
      };

      await clientServices.updateClient(dataInfo);
      await getClient();
      handleIsChangingPersonalData(false);
    } else {
    }
  };

  const handleChangePassword = async () => {
    try {
      await clientServices.updatePassword({
        old_password: resetPasswordFields.old_password,
        password: resetPasswordFields.password,
      });
      NotificationService.success("Password changed successfully");
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.detail ||
        "An error occurred while changing the password";
      NotificationService.error(errorMessage);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // Ожидаем только цифры, и длина либо 5, либо 9
    if (/^\d{0,5}$/.test(value) || /^\d{0,9}$/.test(value)) {
      setZipCode(value);
    }
  };

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: {
      name: user.name || "",
      email: user.email || "",
      phone_number: user.phone_number || "",
      state: user.state || "",
      city: user.city || "",
      zip_code: user.zip_code || "",
    },
    validationSchema: PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      debugger;
      let hasError = false;

      if (
        resetPasswordFields.old_password &&
        resetPasswordFields.password &&
        resetPasswordFields.password_confirmation
      ) {
        if (
          resetPasswordFields.password !==
          resetPasswordFields.password_confirmation
        ) {
          setPasswordError("Passwords do not match");
          hasError = true;
        } else if (resetPasswordFields.password.length < 8) {
          setPasswordError("Password must be at least 8 characters long");
          hasError = true;
        } else {
          setPasswordError(""); // Сбрасываем ошибку, если всё ок
          handleChangePassword();
        }
      }

      const isZipCodeValid = checkZipCode(values.zip_code);

      if (isFormCompleted) {
        if (!isZipCodeValid) {
          // Ошибка показывается, если ZIP-код неверный
          setZipCodeError("The postal code must be 5 or 9 digits long.");
          return;
        }
        handleSubmit(values, formikProps.initialValues);
      }
    },

    // onSubmit: (values) => {
    //   if (
    //     resetPasswordFields.old_password &&
    //     resetPasswordFields.password &&
    //     resetPasswordFields.password_confirmation
    //   ) {
    //     if(resetPasswordFields.password !== resetPasswordFields.password_confirmation){
    //       setPasswordError("Passwords do not match");
    //     }
    //     if(resetPasswordFields.password.length < 8){
    //       setPasswordError("Password must be at least 8 characters long");
    //     }
    //     handleChangePassword();
    //   }
    //   const isZipCodeValid = checkZipCode(values.zip_code);

    //   if (isFormCompleted) {
    //     if (isZipCodeValid) {
    //       setZipCodeError("The postal code must be 5 or 9 digits long.");
    //       return;
    //     }
    //     handleSubmit(values, formikProps.initialValues);
    //   }
    // },
  };

  const checkZipCode = (zipCode: any): boolean => {
    const zipCodeRegex = /^[0-9]+$/;
    if (
      (zipCode.length === 5 || zipCode.length === 9) &&
      zipCodeRegex.test(zipCode)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFormCompleted = () => {
    return (
      formik.values.name &&
      formik.values.phone_number &&
      formik.values.email &&
      zip_code &&
      formik.values.state &&
      formik.values.city
    );
  };

  const formik = useFormik(formikProps);

  const getUpdatedFields = (
    initialValues: IFormikValues,
    currentValues: IFormikValues
  ) => {
    const updatedFields: Partial<IFormikValues> = {};
    Object.keys(initialValues).forEach((key) => {
      if (
        initialValues[key as keyof IFormikValues] !==
        currentValues[key as keyof IFormikValues]
      ) {
        updatedFields[key as keyof IFormikValues] =
          currentValues[key as keyof IFormikValues];
      }
    });
    return updatedFields;
  };

  const handleIsChangingPersonalData = (value: boolean) => {
    updateField("isChangingPersonalData", value);
  };

  return (
    <>
      <div className="mt-10 flex w-full justify-end"></div>
      <FormikProvider value={formik}>
        <Form className="">
          <div className="flex w-full flex-col gap-6 px-4 lg:grid lg:w-auto lg:grid-cols-4 lg:px-0">
            <RenderFormFields
              className="!rounded-[10px]"
              fields={PERSONAL_DATA_FORM_FIELDS}
              disabled={!isChangingPersonalData}
            />
            <div className="relative col-span-2 flex w-full flex-row-reverse">
              <p
                className="absolute right-[10px] z-10 cursor-pointer items-end text-sm font-semibold text-[#F4B10B] underline lg:text-lg"
                onClick={() => setIsModalOpen(true)}
              >
                Change
              </p>
              <RenderFormFields
                className="!rounded-[10px]"
                fields={PERSONAL_DATA_FORM_FIELDS_EMAIL}
                disabled={!isChangingPersonalData}
              />
            </div>
            <RenderFormFields
              className="!rounded-[10px]"
              fields={PERSONAL_DATA_FORM_FIELDS2}
              disabled={!isChangingPersonalData}
            />
            <div className="col-span-2 flex flex-col">
              <div className="flex items-center justify-center">
                <TextInput
                  name="zip_code"
                  className="!rounded-[10px]"
                  label="Zip codes"
                  value={zip_code}
                  onChange={handleChange}
                  disabled={!isChangingPersonalData}
                />
              </div>
              {zipCodeError && (
                <p className="text-sm text-red-500">{zipCodeError}</p>
              )}
            </div>
          </div>
          {isChangingPersonalData && (
            <ResetPassword setResetPasswordFields={setResetPasswordFields} />
          )}

          {passwordError && (
            <p className="text-sm text-red-500">{passwordError}</p>
          )}

          <div className="mt-10 px-4 lg:px-0">
            {isChangingPersonalData ? (
              <div className="flex justify-between">
                <Button
                  onClick={() => handleIsChangingPersonalData(false)}
                  size={Sizes.S}
                  variant={ButtonVariants.CANCEL}
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                  type="submit"
                  // onClick={hanleSaveChanges}
                >
                  Save Changes
                </Button>
              </div>
            ) : (
              <div className="flex justify-end">
                <Button
                  onClick={() => handleIsChangingPersonalData(true)}
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                >
                  Change information
                </Button>
              </div>
            )}
          </div>
        </Form>
      </FormikProvider>

      <ChangeEmail
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newEmailAddress={newEmailAddress}
        setNewEmailAddress={setNewEmailAddress}
        setIsConfirmationModalOpen={setIsConfirmModalOpen}
        setPassword={setPassword}
      />
      <ConfirmationModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        newEmailAddress={newEmailAddress}
        setIsNextModalOpen={setIsSuccesModalOpen}
        password={password}
      />
      <EmailSuccess
        isModalOpen={isSuccesModalOpen}
        setIsModalOpen={setIsSuccesModalOpen}
        newEmailAddress={newEmailAddress}
      />
    </>
  );
};
