import * as Yup from "yup";
import { IOptionSelect } from "src/@types/form";

export const TIME_OPTIONS: IOptionSelect[] = [
  { label: "12:00 AM", value: "0" },
  { label: "1:00 AM", value: "1" },
  { label: "2:00 AM", value: "2" },
  { label: "3:00 AM", value: "3" },
  { label: "4:00 AM", value: "4" },
  { label: "5:00 AM", value: "5" },
  { label: "6:00 AM", value: "6" },
  { label: "7:00 AM", value: "7" },
  { label: "8:00 AM", value: "8" },
  { label: "9:00 AM", value: "9" },
  { label: "10:00 AM", value: "10" },
  { label: "11:00 AM", value: "11" },
  { label: "12:00 PM", value: "12" },
  { label: "1:00 PM", value: "13" },
  { label: "2:00 PM", value: "14" },
  { label: "3:00 PM", value: "15" },
  { label: "4:00 PM", value: "16" },
  { label: "5:00 PM", value: "17" },
  { label: "6:00 PM", value: "18" },
  { label: "7:00 PM", value: "19" },
  { label: "8:00 PM", value: "20" },
  { label: "9:00 PM", value: "21" },
  { label: "10:00 PM", value: "22" },
  { label: "11:00 PM", value: "23" },
];

export const FILLING_INITIAL_VALUES = {
  houseSize: "",
  floorCount: "",
  roomCount: "",
  bathroomCount: "",
  bedLinenChange: "",
  aditionalInformation: "",
  notes: "",
};

export const FILLING_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  houseSize: Yup.string().required("House size is required"),
  floorCount: Yup.string().required("Floor count is required"),
  roomCount: Yup.string().required("Room count is required"),
  bathroomCount: Yup.string().required("Bathroom count is required"),
  bedLinenChange: Yup.string().required("Bed linen change is required"),
  aditionalInformation: Yup.string().required(
    "Additional information is required"
  ),
  notes: Yup.string(),
  date: Yup.string().required("Date is required"),
  timeFrom: Yup.string()
    .required("Time From is required")
    .test(
      "is-before",
      "'Time From' must be before 'Time To'",
      function (value) {
        const { timeTo } = this.parent;
        const timeFromValue = parseInt(value, 10);
        const timeToValue = parseInt(timeTo, 10);
        return !timeTo || timeFromValue < timeToValue;
      }
    ),
  timeTo: Yup.string().required("Time To is required"),
  additional_notes: Yup.string().nullable(),
});
