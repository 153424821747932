import React from "react";
import { ReactComponent as DeleteIcon } from "src/assets/icons/trash.svg";

interface IWeekdayItemProps {
  title: string;
  start_time: string;
  end_time: string;
  onDelete: () => void;
}

// Преобразование из 24-часового формата в 12-часовой
const convertTo12HourFormat = (time: string) => {
  const [hours, minutes] = time.split(":");
  const hourNum = parseInt(hours, 10);
  const period = hourNum >= 12 ? "PM" : "AM";
  const adjustedHour = hourNum % 12 === 0 ? 12 : hourNum % 12;
  return `${adjustedHour.toString().padStart(2, "0")}:${minutes} ${period}`;
};

const WeekdayItem = ({
  title,
  start_time,
  end_time,
  onDelete,
}: IWeekdayItemProps) => {
  const formattedStartTime = convertTo12HourFormat(start_time);
  const formattedEndTime = convertTo12HourFormat(end_time);

  return (
    <div className="flex items-center justify-between gap-4">
      <div>
        <div>{title}:</div>
        <div>
          {formattedStartTime} - {formattedEndTime}
        </div>
      </div>

      <button className="text-red-600" onClick={onDelete}>
        <DeleteIcon />
      </button>
    </div>
  );
};

export default WeekdayItem;
