import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { AccountProfileHeader } from "src/page-components/account/personal-data/AccountProfileHeader";
import { CleanerEditForm } from "src/page-components/account/personal-data/CleanerEditForm";
import CleanerTeamEditForm from "src/page-components/account/personal-data/CleanerTeamEditForm";
import { EditForm } from "src/page-components/account/personal-data/EditForm";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { ChangeEmail } from "src/page-components/ChangeEmail/ChangeEmail";
import { ConfirmationModal } from "src/page-components/ChangeEmail/ConfirmationModal";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { Header } from "src/components/Header";
import { isCleanerType } from "src/utils/getUserType";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import { useModal } from "src/hooks/useModal";
import { ModalWindow } from "src/components/ModalWindow";
import NotificationModalManager from "src/components/ModalWindow/templates/NotificationModalManager";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";

const PersonalData: FC = () => {
  const { isOpenModal, openModal, closeModal } = useModal();
  const { user, type, updateField, isChangingPersonalData } = useClientStore(
    (state) => state
  );
  const { logout } = useAuthStore((state) => state);
  const { getClient, clearClient, resendDocs } = useClientStore(
    (state) => state
  );

  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (type === Users.CLEANER) {
      await clientServices.deleteCleaner();
      logout();
      clearClient();
    } else if (type === Users.CLIENT){
      await clientServices.deleteClients()
      logout();
      clearClient();
    }
  };

  const handleResendDocs = () => {
    resendDocs();
  };

  const { data: isSignedDocs, isLoading } = useQuery({
    queryKey: ["isSignedDocs"],
    queryFn: clientServices.getSignedDocuments,
  });

  console.log("Data: ", isSignedDocs);

  const handleIsChangingPersonalData = (value: boolean) => {
    updateField("isChangingPersonalData", value);
  };

  const handleUpdateCleanerInfo = async () => {
    const cleaningTypeIds = user.cleaning_types.map((type) => type.id);

    const newCleanerData = {
      name: user.name,
      phone_number: user.phone_number.replace(/\s+/g, ""),
      zip_code: user.zip_code,
      experience_years: user.experience_years ? user.experience_years : 0,
      bio: user.bio,
      cleaning_type_ids: cleaningTypeIds ?? [],
      team_members: user.team_members,
    };

    const updateCleanerPromise = clientServices.updateCleaner(newCleanerData);

    const InsuranceFormData = new FormData();
    InsuranceFormData.append("file", user.insurance);

    const LicenseFormData = new FormData();
    LicenseFormData.append("file", user.license);

    const updateInsurancePromise =
      user.insurance && typeof user.insurance !== "string"
        ? clientServices.updateInsurance(InsuranceFormData)
        : Promise.resolve();
    const updateLicensePromise =
      user.license && typeof user.license !== "string"
        ? clientServices.updateLicense(LicenseFormData)
        : Promise.resolve();

    await Promise.all([
      updateCleanerPromise,
      updateInsurancePromise,
      updateLicensePromise,
    ]);
  };

  const handleUpdateClientInfo = async () => {
    const newClientData = {
      name: user.name,
      phone_number: user.phone_number.replace(/\s+/g, ""),
      state: user.state,
      address: user.city,
    };

    await clientServices.updateClient(newClientData);
  };

  const hanleSaveChanges = async () => {
    try {
      if (isCleanerType(type)) {
        handleUpdateCleanerInfo();
      } else {
        handleUpdateClientInfo();
      }

      handleIsChangingPersonalData(false);
      toast.success("Your data has been updated successfully");
    } catch (error) {}
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  console.log("MEE: ", user);

  const handleOpenDeleteModal = () => {
    openModal();
  }

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div>
        <div className="flex w-full justify-between p-2 lg:hidden">
          <Title title="Personal Data" subtitle="" />

          <p onClick={handleBackClick}>Back</p>
        </div>
        <AccountProfileHeader />

        {isCleanerType(type) ? <CleanerEditForm /> : <EditForm />}

        <div className="mt-10 flex gap-5 px-4 pb-10 lg:px-0 lg:pb-0">
          <Button
            onClick={handleOpenDeleteModal}
            size={Sizes.S}
            className="rounded-2xl bg-red-500 text-2xl font-bold text-white-base"
          >
            Delete my profile
          </Button>
          {!isSignedDocs && type == Users.CLEANER && (
            <Button
              onClick={handleResendDocs}
              size={Sizes.S}
              className="rounded-2xl bg-yellow-500 text-2xl font-bold text-white-base"
            >
              Resend the agreements
            </Button>
          )}
        </div>
      </div>
      <div className="block lg:hidden">
        <Footer />
      </div>
      <ModalWindow
        isOpen={isOpenModal}
        onClose={closeModal}
        isShownCloseButton={false}
        className="!w-[600px] flex items-center justify-center"
      >
        <ModalPrimaryTemplate onClose={closeModal}>
          <div className="flex flex-col w-full h-full items-center justify-center text-center">
            <h3 className="mb-4">
              Are you sure that you want to delete your account?
            </h3>
            <h4 className="mb-4">
              It will not be possible to restore it !
            </h4>
            <div className="flex gap-5">
              <Button
                size={Sizes.S}
                className="rounded-2xl bg-red-500 text-2xl font-bold text-white-base w-[100px]"
                onClick={handleDeleteAccount}
              >
                Yes
              </Button>
              <Button
                size={Sizes.S}
                className="rounded-2xl bg-yellow-500 text-2xl font-bold text-white-base w-[100px]"
                onClick={closeModal}
              >
                No
              </Button>
            </div>
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>


    </>
  );
};

export default PersonalData;
