import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as LogoutSvg } from "../../assets/icons/logout.svg";

interface NavigationProps {
  isMobile?: boolean;
}

export const Navigation: FC<NavigationProps> = ({ isMobile = false }) => {
  const { user } = useClientStore((state) => state);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setIsAuthorized(user.id !== 0);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  const { logout } = useAuthStore((state) => state);
  const { clearClient } = useClientStore((state) => state);

  const NAVIGATION_LINKS = [
    { id: 1, label: "Home", href: PATHNAMES.HOME },
    { id: 2, label: "About Us", href: PATHNAMES.ABOUT },
    { id: 3, label: "Services", href: PATHNAMES.SERVICES },
    ...(isAuthorized
      ? [
          { id: 4, label: "Subscriptions", href: PATHNAMES.SUBSCRIPTION },
          ...(isMobile
            ? [
                {
                  id: 5,
                  label: "Your Account",
                  href: PATHNAMES.ACCOUNT_PERSONAl_DATA,
                },
                {
                  id: 6,
                  label: "Log Out",
                  onClick: () => {
                    logout();
                    clearClient();
                  },
                  isButton: true,
                },
              ]
            : []),
        ]
      : []),
  ];

  return (
    <ul
      className={`${
        isMobile
          ? "flex flex-col items-center space-y-4 py-4"
          : `${isAuthorized ? "mr-[340px]" : "mr-[225px]"} flex gap-12 whitespace-nowrap text-white-base`
      }`}
    >
      {NAVIGATION_LINKS.map(({ id, label, href, onClick, isButton }) => (
        <li key={id} className={isMobile ? "py-2" : ""}>
          {isButton ? (
            <button
              className="flex gap-1 px-4 py-2 text-left text-sm hover:bg-gray-50"
              onClick={onClick}
            >
              <LogoutSvg />
              {label}
            </button>
          ) : (
            <Link
              className={`${
                isMobile
                  ? "block px-4 text-gray-900"
                  : "text-white-base hover:opacity-90"
              }`}
              to={href}
            >
              {label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};
