import React, { FC } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReviewsCard } from "./ReviewsCard";
import { IReview } from "./types";

interface Props {
  reviews?: IReview[];
}

export const ReviewsList: FC<Props> = ({ reviews }) => {
  console.log("Reviews TESTTT DATAA: ", reviews);
  if (!reviews || reviews.length === 0) {
    return (
      <p className="mt-5 text-center text-gray-500">No reviews available</p>
    );
  }

  return (
    <>
      <div className="mt-12.5 w-full pl-5 pr-5 md:pr-0">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          className="lg:hidden"
        >
          {reviews.map((card, id) => (
            <SwiperSlide key={id}>
              <ReviewsCard reviewCard={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden gap-6 overflow-scroll lg:flex">
        {reviews.map((card, id) => (
          <ReviewsCard key={id} reviewCard={card} />
        ))}
      </div>
    </>
  );
};
