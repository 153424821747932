import React, { FC, useEffect } from "react";
import { Outlet } from "react-router";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { AccountTabs } from "src/page-components/account/AccountTabs";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const Account: FC = () => {
  const { getClient, clearClient, isLoading, isAuthorized } = useClientStore(
    (state) => state
  );
  const { logout } = useAuthStore((state) => state);

  isLoading && <div>Loading...</div>;

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <PageWrapper mainClassName="py-25">
          <section>
            <div className="container">
              <h2 className="text-center">Your Account</h2>
              <AccountTabs />
              <div className="hidden lg:block">
                <Outlet />
              </div>
              {/* <button
            onClick={() => {
              logout();
              clearClient();
            }}
            className="mt-5"
          >
            logout
          </button> */}
            </div>
          </section>
        </PageWrapper>
      )}
    </>
  );
};

export default Account;
