export enum RequestStatus {
  PENDING = "Pending",
  IN_PROGRESS = "In Progress",
  DECLINED = "Declined",
  DONE = "Done",
}

export interface IBookingRequest {
  additional_notes: string | null;
  address: string;
  bathrooms: number;
  bed_linen_change: boolean;
  cleaning_type: {
    id: number;
    name: string;
    description: string;
  };
  cleaner: {
    id: number;
    avatar_url: string;
    name: string;
    email: string;
    phone_number: string;
    rating: number;
  };
  date: string; // ISO 8601 format
  floors: number;
  has_kids: boolean;
  has_pets: boolean;
  hourly_rate: number;
  house_size: number;
  id: number;
  minimum_hours: number;
  rooms: number;
  status: RequestStatus;
  team_size: number;
  zip_code: string;
}

export interface ICleaningRequest {
  additional_notes: string | null;
  address: string;
  bathrooms: number;
  bed_linen_change: boolean;
  cleaning_type: {
    id: number;
    name: string;
    description: string;
  };
  client: {
    id: number;
    avatar_url: string;
    name: string;
    email: string;
    phone_number: string;
    rating: number;
  };
  date: string; // ISO 8601 format
  floors: number;
  has_kids: boolean;
  has_pets: boolean;
  hourly_rate: number;
  house_size: number;
  id: number;
  minimum_hours: number;
  rooms: number;
  status: RequestStatus;
  team_size: number;
  zip_code: string;
}
