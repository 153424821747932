import { instance } from "src/services/api-client";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface IFileStore {
  response: any;
  file: File | null;
  uploadFile: (file: File) => Promise<void>;
}

const useFileStore = create(
  devtools<IFileStore>((set) => ({
    response: null,
    file: null,
    uploadFile: async (file: File) => {
      try {
        const formData = new FormData();
        formData.append("file", file); // Убедись, что сервер ожидает этот ключ

        const { data } = await instance.put(
          `/api/users/avatar-logo`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        set({ response: data, file });
      } catch (error) {
        console.error("Ошибка загрузки файла:", error);
      }
    },
  }))
);

export default useFileStore;
