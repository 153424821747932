import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import useAuthStore from "src/store/auth-store";
import useSubscriptionStore from "src/store/subscription-store";
import { Button } from "src/components/Button";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/info.svg";
import { Users } from "src/@types/users";
import { Options } from "./Options";

const IMPORTANT_ITEM_CLASS = "relative mt-2 before:absolute before:-left-4";

interface Props {
  activeUser: Users;
}

export const About: FC<Props> = ({ activeUser }) => {
  const [isVisibleImportant, setIsVisibleImportant] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const getActiveUser = useAuthStore((state) => state.getActiveUser);
  const activeUserInfo = useAuthStore((state) => state.activeUserInfo);
  const activeUserSubscription = activeUserInfo?.user?.active_subscription;

  const fetchUserSubscription = useSubscriptionStore(
    (state) => state.fetchUserSubscription
  );
  const cancelSubscription = useSubscriptionStore(
    (state) => state.cancelSubscription
  );

  useEffect(() => {
    if (isChecking) {
      setMessage("Subscription cancelled...");
      setIsModalOpen(true);

      const interval = setInterval(async () => {
        getActiveUser();
      }, 5000); // Каждые 5 секунд

      return () => clearInterval(interval);
    }
  }, [isChecking]);

  useEffect(() => {
    if (isChecking && !activeUserSubscription) {
      setIsChecking(false);
      setIsModalOpen(false);
      setIsSuccessModalOpen(true);
    }
  }, [activeUserSubscription, isChecking]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggleImportant = () =>
    setIsVisibleImportant(!isVisibleImportant);

  const handleCancelSubscription = async () => {
    try {
      if (activeUserSubscription) {
        setIsChecking(true);
        await cancelSubscription(activeUserSubscription.id);
        getActiveUser();
      }
    } catch (error) {
      console.error("Ошибка отмены подписки:", error);
      setIsChecking(false);
      setIsModalOpen(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);

    const url = new URL(window.location.href);
    url.searchParams.delete("success");
    window.history.replaceState({}, document.title, url.pathname);
  };

  return (
    <div className="flex flex-1 flex-col">
      {isMobile && (
        <div className="order-1 mb-10 mt-0 text-center">
          {activeUserSubscription?.status === "active" && (
            <p
              className="mt-5 cursor-pointer text-[#7A7B8380] underline"
              onClick={handleCancelSubscription}
            >
              Cancel my Subscription
            </p>
          )}
        </div>
      )}

      <div className="order-2 flex-1 rounded-2xl bg-red-ligther p-10 lg:order-1">
        <h3 className="text-46 font-semibold text-black-ligth">Service plan</h3>
        <p className="text-black-ligth">Service description:</p>
        <hr className="mb-5 mt-7.5" />
        <Options activeUser={activeUser} />
        <hr className="mb-5 mt-7.5" />
        <Button
          className="w-full !justify-between"
          onClick={handleToggleImportant}
        >
          <div className="flex gap-3">
            <InfoIcon />
            <span>Important</span>
          </div>
          <ArrowIcon />
        </Button>
        {isVisibleImportant && (
          <div className="ml-5 mt-3.5">
            <p className={cn("before:content-['1.']", IMPORTANT_ITEM_CLASS)}>
              The platform does not charge a commission on each transaction.
            </p>
            <p className={cn("before:content-['2.']", IMPORTANT_ITEM_CLASS)}>
              Cleaners cannot advertise their websites.
            </p>
            <p className={cn("before:content-['3.']", IMPORTANT_ITEM_CLASS)}>
              The agreement prohibits clients and cleaners from working directly
              outside the platform - violations result in blocking.
            </p>
          </div>
        )}
      </div>

      {!isMobile && (
        <div className="order-3">
          {activeUserSubscription?.status === "active" && (
            <p
              className="mt-5 cursor-pointer text-[#7A7B8380] underline"
              onClick={handleCancelSubscription}
            >
              Cancel my Subscription
            </p>
          )}
        </div>
      )}

      <ModalWindow
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        isShownCloseButton={false}
        className="w-[600px]"
        isActiveCloseClickOutside={false}
      >
        <ModalPrimaryTemplate
          onClose={() => {
            handleCloseSuccessModal();
          }}
        >
          <div>Subscription canceled successfully</div>
        </ModalPrimaryTemplate>
      </ModalWindow>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={() => {}}
        isShownCloseButton={false}
        className="w-[600px]"
        isActiveCloseClickOutside={false}
      >
        <ModalPrimaryTemplate
          onClose={() => setIsModalOpen(false)}
          isShownCloseButton={false}
        >
          <div>{message}</div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </div>
  );
};
