import * as Yup from "yup";
import { TextInput } from "src/components/FormField/TextInput";
import { SortingDropdownList } from "src/components/SortDropdownList";
import {
  EMAIL_VALIDATION_SCHEMA,
  PASSWORD_VALIDATION_SCHEMA,
} from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "text-lg text-gray-strong";
const PLACEHOLDER = "Write here";

export const PERSONAL_DATA_INITIAL_VALUES: IFormikValues = {
  name: "",
  phone_number: "",
  zip_codes: [],
  email: "",
  // experience_years: {
  //   value: 0,
  //   label: "0",
  // },
  experience_years: 0,
  bio: "",
  cleaning_type_ids: [],
  team_members: [],
};

export const PERSONAL_DATA_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "full_name",
    type: "text",
    label: "Name & Surname",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "phone_number",
    type: "tel",
    label: "Phone",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },
  {
    name: "zip_code",
    type: "text",
    label: "Zip Code",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
  },

  {
    name: "bio",
    type: "text",
    label: "Bio",
    component: TextInput,
    isTextArea: true,
    labelClassName: LABEL_CLASSNAME,
    placeholder: PLACEHOLDER,
    fieldClassName: "col-span-2",
  },
];

export const PERSONAL_DATA_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string(),
  phone_number: Yup.string(),
  // zip_code: Yup.string()

  //   .matches(/^.{5}$||^.{9}$/, "Zip code must be either 5 or 9 digits")

  //   .required("Zip code is required"),
  email: Yup.string(),
});

export const YEARS_OF_EXPERIENCE_DATA = [
  {
    value: 0,
    label: "0",
  },

  {
    value: 1,
    label: "1",
  },

  {
    value: 3,
    label: "3",
  },

  {
    value: 4,
    label: "4",
  },
];
