import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AppRoutes from "src/routes/Router";
import useBookCleaningStore from "src/store/book-cleaning-store";
import { CleaningStepForm } from "src/page-components/book-cleaning/CleaningStepForm";
import { Button } from "src/components/Button";
import { BUTTON_STYLE_VARIANTS } from "src/components/Button/constants";
import { ButtonVariants } from "src/components/Button/types";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";

const BookCleaning: FC = () => {
  const navigate = useNavigate();
  const { step, onNextStep, onPreviousStep, validateStep } =
    useBookCleaningStore((state) => state);
  const isStepValidate = validateStep(step);

  const onNextClick = () => {
    if (!isStepValidate) {
      alert("Please fill all required fields before proceeding.");
      return;
    }
    onNextStep();
  };

  const onPrevClick = () => {
    onPreviousStep();
  };

  const onSelectCleanersClick = () => {
    if (!isStepValidate) {
      alert("Please fill all required fields before proceeding.");
      return;
    }

    if (step === 2) {
      navigate(PATHNAMES.AVAILABLE_CLEANERS);
      onNextStep();
    }
  };

  useEffect(() => {
    useBookCleaningStore.setState({ step: 0 }); // Сброс шага при заходе на страницу
  }, []);

  return (
    <PageWrapper mainClassName="py-25">
      <section>
        <div className="container !max-w-175 !overflow-visible">
          <h2 className="text-center">Cleaning Application</h2>
          <CleaningStepForm
            activeStep={step}
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
          />

          <div className="mt-8 flex flex-col-reverse items-center justify-between gap-3 lg:flex-row">
            {step > 0 ? (
              <Button
                variant={ButtonVariants.OPACITY_PRIMARY}
                size={Sizes.M}
                onClick={onPrevClick}
                className="w-full lg:w-1/3"
              >
                Previous Step
              </Button>
            ) : (
              <div />
            )}

            {step === 2 ? (
              // <Link to={PATHNAMES.AVAILABLE_CLEANERS}>
              <Button
                isDisabled={!isStepValidate}
                variant={ButtonVariants.PRIMARY}
                size={Sizes.M}
                onClick={onSelectCleanersClick}
                className="w-full lg:w-1/3"
              >
                Select Cleaners
              </Button>
            ) : (
              // </Link>
              <Button
                isDisabled={!isStepValidate}
                variant={ButtonVariants.PRIMARY}
                size={Sizes.M}
                onClick={onNextClick}
                className="w-full lg:w-1/3"
              >
                Next Step
              </Button>
            )}
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default BookCleaning;
