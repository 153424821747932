import { FC } from "react";
import { Props } from "react-select";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import NumberedRating from "src/components/Rating/NumberedRating";
import { ReactComponent as ThirdStarIcon } from "src/assets/icons/star-third.svg";
import CleanerItemImage from "src/assets/images/cleaner-item.png";
import NoAvatar from "src/assets/images/noAvatar.png";
import { ICleaningRequest, RequestStatus } from "src/@types/request";
import { Sizes } from "src/@types/sizes";
import { ReviewsCard } from "../home/Reviews/ReviewsList/ReviewsCard";

  const formatDateUTC = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    if (hours === 0) hours = 12;

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  };

const InfoItem = ({ title, info }: { title: string; info: string }) => {
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="text-xl font-medium capitalize leading-relaxed text-gray-dark">
        {title}
      </div>
      <div className="text-base font-semibold text-black-third">{info}</div>
    </div>
  );
};

interface ICleanerDetailsProps {
  request: ICleaningRequest;
  refetch?: any;
  closeModal?:any;
}

export const ClientDetails: FC<ICleanerDetailsProps> = ({ request, refetch, closeModal }) => {
  const {
    isFetched: isReviewsFetched,
    isFetching: isReviewsFetching,
    data: reviewsData,
  } = useQuery({
    queryKey: ["getReviewsToCleaner"],
    queryFn: async () =>
      clientServices.getReviewsToClient(request?.client.id),
    enabled: !!request,
  });

  const handleAcceptCleanerRequest = async (id: number) => {
    console.log("Accept cleaner request: ", id);
    await clientServices.acceptCleaningRequest(id);
    refetch();
    closeModal()
  };

  if (!request) {
    return <div>Loading request details...</div>;
  }
  const { client, cleaning_type } = request;

  console.log("Reviews data:", reviewsData);

  return (
    <div className="">
      <div className="mt-12 flex items-center justify-between rounded-t-[24px] bg-gray-dark p-2 lg:p-[42px]">
        <div className="flex items-center gap-8">
          <div className="flex h-[80px] w-[150px] items-center justify-center rounded-full bg-purple-base lg:h-[120px] lg:w-[120px]">
            <img
              src={request?.client?.avatar_url || NoAvatar}
              alt="Cleaner avatar"
            />
          </div>

          <div>
            <div className="text-white text-[18px] font-semibold lg:text-[32px]">
              {cleaning_type?.name}
            </div>

            <div className="w-2/3">
              <span className="text-[14px] font-medium leading-normal text-white-base lg:text-[16px]">
                <NumberedRating rating={client?.rating} /> Client: {client?.name}
              </span>
            </div>

            <div className="mt-4 inline-block rounded-[30px] bg-white-base px-[10px] py-2 text-[10px] font-bold text-green-base">
              {request.status}
            </div>
          </div>
        </div>
        {request?.status === RequestStatus.PENDING && (
          <div className="hidden lg:block">
            <Button size={Sizes.S} variant={ButtonVariants.PRIMARY}
                    onClick={() => handleAcceptCleanerRequest(request.id)}>
              Accept
            </Button>
          </div>
        )}
      </div>

      <div className="flex flex-col overflow-x-auto rounded-b-[24px] border border-gray-dark lg:flex-row">
        <div className="botder-r-0 flex w-full flex-col gap-10 border-b border-gray-dark p-10 lg:w-1/3 lg:border-b-0 lg:border-r">
          <InfoItem
            info={request?.address}
            title="Address"
          />
          <InfoItem
            info={`$${request?.hourly_rate * request.minimum_hours}`}
            title="Price"
          />
          <InfoItem
            info={request?.date ? formatDateUTC(request.date) : "Unknown Date"}
            title="Date"
          />
        </div>

        <div className="flex w-2/3 flex-col gap-10 p-10">
          <div>
            <h3 className="text-[32px] font-semibold text-gray-strong">
              Cleaning Information
            </h3>

            <div className="space-y-2 text-base font-light leading-normal text-black-third">
              <p>
                <strong>House Size:</strong> {request?.house_size}
              </p>
              <p>
                <strong>Number of floors:</strong> {request?.floors}
              </p>
              <p>
                <strong>Room Count:</strong> {request?.rooms}
              </p>
              <p>
                <strong>Bathroom Count:</strong> {request?.bathrooms}
              </p>
              <p>
                <strong>Bed linen change:</strong> {request?.bed_linen_change}
              </p>
              <p>
                <strong>Pets:</strong>{" "}
                {request?.has_pets ? "With Pets" : "Without Pets"}
              </p>
              <p>
                <strong>Kids:</strong>{" "}
                {request?.has_kids ? "With Kids" : "Without Kids"}
              </p>
              <p>
                <strong>Date and time:</strong>{" "}
                {request?.date ? formatDateUTC(request.date) : "Unknown Date"}
              </p>
            </div>
          </div>

          {isReviewsFetching ? (
            <div>Loading...</div>
          ) : isReviewsFetched && reviewsData?.length === 0 ? (
            <div>Reviews Not Found</div>
          ) : (
            <div className="flex  flex-col gap-6">
              <div className="flex items-center justify-between">
                <h3 className="text-[32px] font-semibold text-gray-strong">
                  Reviews
                </h3>
                <div className="flex items-center gap-2">
                  <ThirdStarIcon /> {client.rating.toFixed(1)}
                </div>
              </div>
              <div>
                <Swiper
                  slidesPerView="auto"
                  spaceBetween={40}
                  pagination={{
                    el: ".swiper-pagination-reviews",
                    clickable: true,
                  }}
                  modules={[Navigation, Pagination]}
                >
                  {/* <SwiperSlide className="w-[353px]">
                    <ReviewsCard
                      rating={4}
                      avatar={CleanerItemImage}
                      name="James Wong"
                      description="09-02-2024"
                      review="Team was on time, got everything done with smiles the whole time. Glass was sparkling, floors spotless, stove looked new."
                      url={""}
                    />
                  </SwiperSlide> */}
                </Swiper>
                <div className="mt-10 flex items-center justify-end gap-2">
                  <div className="swiper-pagination-reviews" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
