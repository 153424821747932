import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import clientServices from "src/services/client-service";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import NumberedRating from "src/components/Rating/NumberedRating";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as RatingStarIcon } from "src/assets/icons/star-rating.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import CleanerImage from "src/assets/images/cleaner-item2.png";
import NoAvatar from "src/assets/images/noAvatar.png";
import { IBookingRequest, RequestStatus } from "src/@types/request";
import { Sizes } from "src/@types/sizes";
import BookingBadge from "./RequestBadge";

interface IRequestItemProps {
  booking: IBookingRequest;
  refetch?: () => void;
}

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const RequestItem: FC<IRequestItemProps> = ({ booking, refetch }) => {
  const { cleaner, cleaning_type } = booking;
  const { user, type } = useClientStore((state) => state);

  const price = booking?.minimum_hours * booking?.hourly_rate;
  const emailAdress = booking?.cleaner?.email;

  const {
    data: reviews,
    isLoading: isLoadingReviews,
    isSuccess: isSuccessReviews,
  } = useQuery({
    queryKey: ["clientReviews"],
    queryFn: () => clientServices.getReviewsFromCLient(user?.id),
  });

  const hasReview =
    isSuccessReviews &&
    reviews?.some((review) => review.cleaning_id === booking.id);

  const formattedDate = booking.date
    ? formatDate(booking.date)
    : "Unknown Date";

  console.log("booking testtest: ", booking);

  return (
    <>
      {/* MOBILE */}
      <div className="flex items-start justify-between gap-6 border-b border-[#D1D1DC] pb-6 pl-5 lg:hidden">
        <img
          src={booking?.cleaner?.avatar_url || NoAvatar}
          className="h-18 w-18"
          alt="cleaner"
        />
        <div className="flex flex-1 gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex flex-col items-start gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                {cleaning_type?.name}
              </div>
              <div className="flex flex-col items-start justify-between">
                <div className="flex items-center gap-2">
                  <NumberedRating rating={booking?.cleaner?.rating} />
                  <p>Client: {booking?.cleaner?.name}</p>
                </div>
                <div>Address: {booking?.address}</div>
                <div>{formattedDate}</div>
              </div>
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                ${price}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            {booking?.status === RequestStatus.IN_PROGRESS && (
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
                onClick={() => {
                  window.location.href = `mailto:${emailAdress}`;
                }}
              >
                Contact
              </Button>
            )}
            {booking?.status === RequestStatus.DONE && !hasReview && (
              <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE} state={{ booking }}>
                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.OPACITY_PRIMARY}
                  className="w-full"
                >
                  Review
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="hidden items-center justify-between gap-6 border-b border-[#D1D1DC] pb-6 lg:flex">
        <img
          src={booking?.cleaner?.avatar_url || NoAvatar}
          className="h-18 w-18"
          alt="cleaner"
        />
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex items-center gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                {cleaning_type?.name}
              </div>
            </div>
            <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
              ${price}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <NumberedRating rating={booking?.cleaner?.rating} />
              <p>Client: {booking?.cleaner?.name}</p>
            </div>
            <div>Address: {booking?.address}</div>
            <div>{formattedDate}</div>
          </div>
        </div>
        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            {booking?.status === RequestStatus.IN_PROGRESS && (
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
                onClick={() => {
                  window.location.href = `mailto:${emailAdress}`;
                }}
              >
                Contact
              </Button>
            )}
            {booking?.status === RequestStatus.DONE && !hasReview && (
              <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE} state={{ booking }}>
                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.OPACITY_PRIMARY}
                  className="w-full"
                >
                  Review
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
