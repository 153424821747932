import React, { FC } from "react";
import cn from "classnames";
import NoAvatar from "src/assets/images/noAvatar.png";
// import avatar from "src/assets/images/avatar.png";
import { Sizes } from "src/@types/sizes";
import { AVATAR_SIZE_VARIANTS } from "./constants";

interface Props {
  className?: string;
  containerClassName?: string;
  sizeVariant?: Sizes;
  avatarUrl?: string;
  altText?: string;
}

export const Avatar: FC<Props> = ({
  className,
  sizeVariant = Sizes.S,
  avatarUrl,
  altText,
}) => {
  const combinedClassNames = cn(
    "object-cover rounded-full",
    AVATAR_SIZE_VARIANTS[sizeVariant],
    className
  );

  return (
    <img
      className={combinedClassNames}
      src={avatarUrl || NoAvatar}
      alt={altText}
    />
  );
};
