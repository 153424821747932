import React, { FC, useEffect, useState } from "react";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";
import { Logo } from "../Logo";
import { NAVIGATION_LINKS, SERVICES_LINKS } from "./constants";
import { ContactUs } from "./ContactUs";
import { CopyRight } from "./CopyRight";
import { Navigation } from "./Navigation";
import {ReactComponent as TwitterIcon} from 'src/assets/icons/twitter.svg'
import {ReactComponent as InstagramIcon} from 'src/assets/icons/instagram.svg'
import {ReactComponent as YoutubeIcon} from 'src/assets/icons/youtube.svg'

interface Props {
  isShown?: boolean;
}

export const Footer: FC<Props> = ({ isShown = true }) => {
  const { user } = useClientStore((state) => state);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setIsAuthorized(user.id !== 0);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  const NAVIGATION_LINKS = {
    title: "Navigation",
    links: [
      { id: 1, label: "Home", href: PATHNAMES.HOME },
      { id: 2, label: "About Us", href: PATHNAMES.ABOUT },
      { id: 3, label: "Services", href: PATHNAMES.SERVICES },
      ...(isAuthorized
        ? [{ id: 4, label: "Subscriptions", href: PATHNAMES.SUBSCRIPTION }]
        : []),
      // { id: 4, label: "FAQs", href: PATHNAMES.HOME },
      // { id: 5, label: "Contact Us", href: PATHNAMES.HOME },
    ],
  };

  if (!isShown) return null;

  return (
    <footer className="bg-gray-dark">
      <div className="container py-8">
        <div className="flex flex-col gap-24 lg:flex-row">
          <div className="flex w-full flex-col items-center gap-5 lg:w-[300px] lg:flex-row lg:items-start md:flex-wrap">
            <Logo />

            <p className="mt-3 text-center text-white-base lg:text-start">
              Leo elementum iaculis quam massa vitae odio sed. Morbi tincidunt
              senectus.
            </p>

            <ul className='flex gap-[10px]'>
              <li>
                <a href="https://x.com/SparkleTheClea1">
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sparkle.tcs.llc/">
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@sparklethecleaningservice5973">
                  <YoutubeIcon />
                </a>
              </li>
            </ul>

          </div>

          <div className="mt-8 hidden flex-1 gap-15 lg:flex">
            <Navigation {...NAVIGATION_LINKS} />
            <Navigation {...SERVICES_LINKS} />
            <ContactUs />
          </div>
          <div className="mt-8 w-full flex-1 flex-col justify-center gap-15 lg:hidden">
            <div className="mb-5 flex gap-15">
              <Navigation {...NAVIGATION_LINKS} />
              <Navigation {...SERVICES_LINKS} />
            </div>
            <ContactUs />
          </div>
        </div>

        <hr className="mb-8 mt-14 border-yellow-base" />

        <CopyRight />
      </div>
    </footer>
  );
};
