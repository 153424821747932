import { FC } from "react";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  newEmailAddress: string;
}

export const EmailSuccess: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  newEmailAddress,
}) => {
  return (
    <ModalWindow
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isShownCloseButton={false}
      isActiveCloseClickOutside={true}
      className="flex justify-center"
    >
      <ModalPrimaryTemplate
        className="flex w-[375px] flex-col items-center justify-center lg:w-[700px]"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex w-full flex-col items-center">
          <h3 className="text-center text-[28px] lg:text-left">
            Email updated successfully
          </h3>
          <p className="mt-5 text-center text-[16px]">
            Your email address has been updated.
          </p>
        </div>
      </ModalPrimaryTemplate>
    </ModalWindow>
  );
};
