import React, { FC } from "react";
import cn from "classnames";

interface Props {
  children: string;
  className?: string;
  isShownError: boolean;
}

const Error: FC<Props> = ({ children, className, isShownError }) => {
  if (!isShownError) return null;

  return (
    <div
      className={cn(
        "absolute -bottom-5 left-4 top-[79px] text-[12px] text-sm text-red-base",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Error;
