import React, { FC } from "react";
import { SectionHeader } from "src/components/SectionHeader";
import { Indicators } from "./Indicators";

export const AboutUs: FC = () => (
  <section>
    <div className="container flex flex-col gap-25 py-25 lg:flex-row">
      <SectionHeader
        textClassName="!text-start"
        titleClassName="text-start"
        className="flex-1"
        title="Your Trusted Cleaning Solution"
        subtitle="About Us"
        description="Our dedicated team is trained to deliver top-notch cleaning services
            tailored to your home or business."
      />

      <Indicators />
    </div>
  </section>
);
