import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import bookingServices from "src/services/booking-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import useBookCleaningStore from "src/store/book-cleaning-store";
import useClientStore from "src/store/client-store";
import CleanersList from "src/page-components/available-cleaners/CleanersList";
import NotFoundCleaners from "src/page-components/available-cleaners/NotFoundCleaners";
import PageBanner from "src/page-components/PageBanner";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Search } from "src/components/Search";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";

const AvailableCleanersMessage = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-col gap-32">
      <div className="flex flex-col gap-7">
        <h2 className="max-w-[936px] text-[50px] font-semibold uppercase tracking-[10px] !text-black-third">
          {text}
        </h2>
      </div>
    </div>
  );
};

const AvailableCleaners: FC = () => {
  const navigate = useNavigate();
  const { timeFrom, timeTo, date, team_size, cleaning_type, updateField } =
    useBookCleaningStore((state) => state); // Оставляем только необходимые поля
  const isAuthorized = useClientStore((state) => state.isAuthorized);

  const [customZipCode, setCustomZipCode] = useState(""); // Локальное состояние для zip-кода
  const [isDirty, setIsDirty] = useState(false);

  const {
    data: availableCleanersData,
    isFetching,
    isFetched,
    refetch,
  } = useQuery<IAvailableCleaner[]>({
    queryKey: ["getAvailableCleaners"], // Зависимость от customZipCode
    queryFn: async () => {
      debugger;

      if (!customZipCode) {
        return [];
      } // Если zip-код пустой, возвращаем пустой массив

      if (!isAuthorized) {
        return bookingServices.getCleanersByZipCode(customZipCode);
      } else {
        if (cleaning_type.id && team_size.value && date && timeFrom && timeTo) {
          const dateWithoutTimezone = new Date(
            `${date}T${timeFrom.padStart(2, "0")}:00:00Z`
          ).toISOString();
          const dateWithoutTimezoneTo = new Date(
            `${date}T${timeTo.padStart(2, "0")}:00:00Z`
          ).toISOString();

          console.log("Date from: ", dateWithoutTimezone);
          console.log("Date to: ", dateWithoutTimezoneTo);

          return bookingServices.getAvailableCleaners({
            zip_code: customZipCode, // Используем только customZipCode
            cleaning_type_id: cleaning_type.id,
            date_from: dateWithoutTimezone,
            date_to: dateWithoutTimezoneTo,
            team_size: team_size.value,
          });
        }
      }
    },
  });

  const handleSearch = () => {
    debugger;

    if (
      isAuthorized &&
      cleaning_type.id == 0 &&
      team_size.value == 0 &&
      date == "" &&
      timeFrom == "" &&
      timeTo == ""
    ) {
      console.log("Date: ", date);
      onBack();
    } else {
      refetch();
    }
  };

  const handleZipCodeChange = (value: string) => {
    setIsDirty(true);
    setCustomZipCode(value); // Обновляем только локальное состояние
  };

  const onBack = () => {
    setTimeout(() => {
      navigate(PATHNAMES.BOOK_CLEANING);
    }, 0);
  };

  console.log("Available cleaners: ", availableCleanersData);

  return (
    <PageWrapper>
      <PageBanner
        className="w-full bg-cleaner-banner bg-cover bg-top bg-no-repeat py-[78px]"
        title="Enter Your Postal Code To Get Started"
        titleClassName="max-w-[682px]"
      >
        <div className="flex flex-col-reverse items-center gap-6 lg:max-w-[650px] lg:flex-row">
          <Button
            onClick={onBack}
            size={Sizes.S}
            variant={ButtonVariants.OPACITY_PRIMARY}
            className="w-full lg:w-auto"
          >
            Previous Step
          </Button>
          <Search
            onSearch={() => {
              setIsDirty(false);
              console.log("Search button clicked");
              handleSearch();
            }}
            className="flex-1"
            value={customZipCode}
            onChange={(e) => handleZipCodeChange(e.target.value)}
            customZipCode={customZipCode}
          />
        </div>
      </PageBanner>

      <section className="bg-blue-ligther py-[100px]">
        <div className="container">
          {isFetching ? (
            <div>Loading...</div>
          ) : isFetched && availableCleanersData?.length === 0 ? (
            <NotFoundCleaners />
          ) : isFetched && availableCleanersData?.length !== 0 ? (
            <CleanersList cleaners={availableCleanersData} />
          ) : (
            <AvailableCleanersMessage text="Please confirm your Zip Code to view available cleaners." />
          )}
        </div>
      </section>
    </PageWrapper>
  );
};

export default AvailableCleaners;
