import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ILink } from "src/@types";

interface Props {
  title: string;
  links: ILink[];
}

export const Navigation: FC<Props> = ({ title, links }) => {
  const navigate = useNavigate();

  const handleServicesClick = (href: string) => {
    const topElement = document.getElementById("services-section");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
    navigate(href);
  };

  return (
    <div>
      <span className="font-open-sans text-2xl text-white-base">{title}</span>

      <ul className="mt-4 flex flex-col gap-1.5 text-white-base">
        {links.map(({ id, label, href }) => (
          <li key={id}>
            <Link
              className="font-open-sans font-light hover:opacity-90"
              to={href}
              onClick={() => handleServicesClick(href)}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
