import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { withFormikField } from "src/hoc/withFormikField";
import bookingServices, { ICleaningType } from "src/services/booking-service";
import clientServices, {
  ICleanerFullUpdateRequest,
} from "src/services/client-service";
import useClientStore from "src/store/client-store";
import PhoneInput from "src/ui/PhoneInput";
import Select from "src/ui/Select";
import { TextInput } from "src/ui/TextInput";
import { ChangeEmail } from "src/page-components/ChangeEmail/ChangeEmail";
import { ConfirmationModal } from "src/page-components/ChangeEmail/ConfirmationModal";
import { EmailSuccess } from "src/page-components/ChangeEmail/EmailSuccess";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import FileUploader from "src/components/FileUploader";
import { TextInput as SecondInput } from "src/components/FormField/TextInput";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { RenderFormFields } from "src/components/RenderFormFields";
import { SortingDropdownList } from "src/components/SortDropdownList";
import { isCleanerType } from "src/utils/getUserType";
import { NotificationService } from "src/helpers/notifications";
import { ReactComponent as FileIcon } from "src/assets/icons/file.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/material-symbols_info-outline.svg";
import { Sizes } from "src/@types/sizes";
import {
  ICleaningType as ICleaningTypeUser,
  IProfileStatusLabels,
  IZipCodes,
} from "src/@types/users";
import { IProfileStatus } from "src/@types/users";
import { StatusIndicator } from "../AccountProfileHeader";
import CleanerTeamEditForm from "../CleanerTeamEditForm";
import { ResetPassword } from "../ResetPassword";
import {
  RESET_PASSWORD_FORM_FIELDS,
  RESET_PASSWORD_INITIAL_VALUES,
} from "../ResetPassword/constants";
import {
  PERSONAL_DATA_FORM_FIELDS,
  PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
  PERSONAL_DATA_INITIAL_VALUES,
  YEARS_OF_EXPERIENCE_DATA,
} from "./constants";
import Tooltip from "./Tooltip";
import { IFormikValues } from "./types";

export const CleanerEditForm: FC = () => {
  const {
    user,
    isChangingPersonalData,
    updateUserField,
    updateField,
    type,
    getClient,
  } = useClientStore((state) => state);

  const profileStatus = user.profile_status;

  const formatStatus = (status: string) => {
    return (
      IProfileStatusLabels[status as keyof typeof IProfileStatusLabels] ||
      status
    );
  };

  const formattedStatus = formatStatus(profileStatus);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [newEmailAddress, setNewEmailAddress] = React.useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isSuccesModalOpen, setIsSuccesModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const [zip_code, setZipCode] = useState<any>(); // Current zip code (optional)
  const [selectedZipCodes, setSelectedZipCodes] = useState<any>([]); // Selected zip code
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [newLicenseFile, setNewLicenseFile] = useState<File | null>();
  const [newInsuranceFile, setNewInsuranceFile] = useState<File | null>();

  useEffect(() => {
    debugger;

    if (user.zip_codes && user.zip_codes.length > 0) {
      // Если хочешь передать все zip_codes, делай это так:
      setSelectedZipCodes(user.zip_codes.map((code) => code.zip_code));

      // Если хочешь передать только первый zip_code:
      // setSelectedZipCodes([user.zip_codes[0].zip_code]);
    }
  }, [user.zip_codes]); // Следим за изменениями массива zip_codes

  const handleAddZipCode = (zipCode: any) => {
    const zipCodeRegex = /^[0-9]+$/; // Регулярное выражение для проверки цифр
    if (
      (zipCode.length === 5 || zipCode.length === 9) &&
      zipCodeRegex.test(zipCode)
    ) {
      if (!selectedZipCodes.some((item) => item === zipCode)) {
        setSelectedZipCodes((prev) => [...prev, zipCode]);
        setZipCode("");
      }
    } else setZipCodeError("The postal code must be 5 or 9 digits long.");
  };

  const handleRemoveZipCode = (zipCode: any) => {
    setSelectedZipCodes((prev) => prev.filter((item) => item !== zipCode));
  };

  const [cleanerLicense, setCleanerLicense] = React.useState<File>(null);

  const isEditable = user.profile_status !== IProfileStatus.UNCOMPLETED_PROFILE;
  const [isModalOpenEditable, setIsModalOpenEditable] = useState(false);

  const [resetPasswordFields, setResetPasswordFields] = React.useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const { data: licenseData } = useQuery({
    queryKey: ["getLicense"],
    queryFn: () => {
      clientServices.getLicense().then((res) => {
        updateUserField("license", res.file_url);
      });
    },
  });

  const { data: insuranceData } = useQuery({
    queryKey: ["getInsurance"],
    queryFn: () => {
      clientServices.getInsurance().then((res) => {
        updateUserField("insurance", res.file_url);
      });
    },
  });

  const getChangedFields = (values, initialValues) => {
    debugger;
    const changedFields = {};

    Object.keys(values).forEach((key) => {
      if (JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])) {
        changedFields[key] = values[key];
      }
    });

    return changedFields;
  };

  const handleSubmitFull = async (values: ICleanerFullUpdateRequest) => {
    const cleanedPhoneNumber = values.phone_number?.replace(/\s+/g, "");

    // Update the phone number in the form values
    const updatedValues = {
      ...values,
      phone_number: cleanedPhoneNumber,
      cleaning_type_ids: cleaningTypesData.map(
        (type: ICleaningTypeUser) => type.id
      ),
      // zip_codes: values.zip_codes.map((z: IZipCodes) => String(z.zip_code)),
      zip_codes: selectedZipCodes,

      license: user.license,
      insurance: user.license, //change later
    };

    await clientServices.updateCleanerFull(updatedValues);
    await getClient();
  };

  const handleSubmit = async (
    values: IFormikValues,
    initialValues: IFormikValues
  ) => {
    debugger;
    if (newLicenseFile) {
      const licenseFormData = new FormData();
      licenseFormData.append("file", newLicenseFile);
      await clientServices.updateLicense(licenseFormData);
      setNewLicenseFile(null); // Сброс файла после отправки
    } else {
      if (!user.license) {
        NotificationService.error("Please upload a license file.");
      }
    }
    // }

    // if (newInsuranceFile) {
    if (newInsuranceFile) {
      const insuranceFormData = new FormData();
      insuranceFormData.append("file", newInsuranceFile);
      await clientServices.updateInsurance(insuranceFormData);
      setNewInsuranceFile(null); // Сброс файла после отправки
    } else {
      if (!user.insurance) {
        NotificationService.error("Please upload an insurance file.");
      }
    }
    const cleanedPhoneNumber = values.phone_number?.replace(/\s+/g, "");

    // Update the phone number in the form values
    const updatedValues = {
      ...values,
      phone_number: cleanedPhoneNumber,
      zip_codes: selectedZipCodes,
      cleaning_type_ids: cleaningTypesData.map(
        (type: ICleaningTypeUser) => type.id
      ),
      // zip_codes: values.zip_codes.map((z: IZipCodes) => String(z.zip_code)),
    };

    const changedFields: Record<string, any> = getChangedFields(
      updatedValues,
      initialValues
    );

    if (Object.keys(changedFields).length > 0) {
      changedFields.team_members = user.team_members; // Додаємо team_members явно

      await clientServices.updateCleaner(changedFields);
      await getClient();
    }

    // if (newLicenseFile) {

    // }

    // Remove spaces from the phone number

    // clientServices.updateClient(updatedValues); // Send only changed fields
    // await await getClient();
    handleIsChangingPersonalData(false);
  };

  const { data: cleaningTypes, isLoading } = useQuery({
    queryKey: ["cleaningTypes"],
    queryFn: bookingServices.getCleaningTypes,
  });

  const [cleaningTypesData, setCleaningTypesData] = useState<any[]>(
    user.cleaning_types
  );

  const isFormCompleted = () => {
    return (
      formik.values.name &&
      formik.values.phone_number &&
      formik.values.email &&
      selectedZipCodes.length > 0 &&
      formik.values.experience_years > 0 &&
      // formik.values.bio &&
      formik.values.cleaning_type_ids.length > 0 &&
      user.license &&
      user.insurance
    );
  };

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: {
      name: user.name || "",
      phone_number: user?.phone_number?.replace(/\s+/g, "") || "",
      email: user.email || "",
      zip_codes: user.zip_codes?.map((z) => ({ zip_code: z.zip_code })) || [],

      experience_years: user.experience_years || 0,
      bio: user.bio || "",
      cleaning_type_ids: user.cleaning_types.map((type) => type.id) || [],
      team_members: user.team_members || [],
    },
    validationSchema: PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      if (user.profile_status === IProfileStatus.UNCOMPLETED_PROFILE) {
        const fullRequest: ICleanerFullUpdateRequest = {
          ...values,
          insurance: user.insurance, // Заглушка, если файла нет
          license: user.license, // Заглушка, если файла нет
          zip_codes: selectedZipCodes,
        };

        let isAccess = isFormCompleted();
        if (isAccess) {
          handleSubmitFull(fullRequest);
        } else {
          setIsModalOpenEditable(true);
        }
      } else {
        handleSubmit(values, formikProps.initialValues);
      }
      if (
        resetPasswordFields.old_password &&
        resetPasswordFields.password &&
        resetPasswordFields.password_confirmation
      ) {
        if (
          resetPasswordFields.password !==
          resetPasswordFields.password_confirmation
        ) {
          setPasswordError("Passwords do not match");
        } else if (resetPasswordFields.password.length < 8) {
          setPasswordError("Password must be at least 8 characters long");
        } else {
          setPasswordError(""); // Сбрасываем ошибку, если всё ок
          handleChangePassword();
        }
      }
    },
  };

  const formik = useFormik(formikProps);
  useEffect(() => {}, [formik.initialValues]);

  const setField = (field: string, value: string | number | File | any) => {
    formik.setValues({
      ...formik.values,
      [field]: value,
    });
  };

  const handleIsChangingPersonalData = (value: boolean) => {
    updateField("isChangingPersonalData", value);
  };

  const handleChangePassword = async () => {
    await clientServices.updatePassword({
      old_password: resetPasswordFields.old_password,
      password: resetPasswordFields.password,
    });
  };

  const handleInputChange = (
    field: string,
    value: string | number | File | any
  ) => {
    updateUserField(field, value);
  };

  const handleInputSecondChange = (fieldName: string, value: string) => {
    if (value === "") {
      setField("experience_years", "");
      return;
    }

    if (/^\d+$/.test(value)) {
      setField("experience_years", Number(value));
    }
  };

  // const updateInsurancePromise =
  //   user.insurance && typeof user.insurance !== "string"
  //     ? clientServices.updateInsurance(InsuranceFormData)
  //     : Promise.resolve();
  // const updateLicensePromise =
  //   user.license && typeof user.license !== "string"
  //     ? clientServices.updateLicense(LicenseFormData)
  //     : Promise.resolve();

  return (
    <>
      {!isEditable && (
        <p className="text-[16px] text-red-500">
          You have uncompleted profile. Please complete all fields!
        </p>
      )}

      <FormikProvider value={formik}>
        <Form className="mt-10">
          <div className="grid grid-cols-1 items-stretch gap-6 px-4 md:grid-cols-2 md:px-0">
            <div className="col-span-2 md:col-span-1">
              <TextInput
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Write here"
                label="Full Name *"
                className="!rounded-10"
                disabled={!isChangingPersonalData}
              />
            </div>

            <div className="col-span-2 md:col-span-1">
              <PhoneInput
                name="phone_number"
                value={formik.values.phone_number}
                onChange={(e) =>
                  // handleInputChange(e.target.name, e.target.value)
                  setField("phone_number", e.target.value)
                }
                placeholder="Write here"
                label="Cell Phone *"
                className="!rounded-10"
                disabled={!isChangingPersonalData}
              />
            </div>

            <div className="relative col-span-2 flex w-full flex-row-reverse md:col-span-1">
              <p
                className="text-md absolute right-[10px] z-10 cursor-pointer items-end font-semibold text-[#F4B10B] underline lg:text-lg"
                onClick={() => setIsModalOpen(true)}
              >
                Change
              </p>
              <TextInput
                name="email"
                value={formik.values.email}
                onChange={(e) =>
                  // handleInputChange(e.target.name, e.target.value)
                  setField("email", e.target.value)
                }
                placeholder="Write here"
                label="Email *"
                className="!rounded-10"
                disabled={true}
              />
            </div>

            <div className="col-span-2 md:col-span-1">
              {/* <TextInput
                name="zip_codes"
                value={formik.values.zip_codes
                  .map((z) => z.zip_code)
                  .join(", ")}
                onChange={(e) => {
                  const newZipCodes = e.target.value
                    .split(",")
                    .map((z) => ({ zip_code: z.trim() }));

                  const isValid = newZipCodes.every(({ zip_code }) =>
                    /^[0-9]{5,9}$/.test(zip_code)
                  );

                  if (isValid) {
                    formik.setFieldValue("zip_codes", newZipCodes);
                  } else {
                    console.log("Invalid zip code(s)");
                  }
                }}
                placeholder="Write here"
                label="Service Zip Codes *"
                className="!md:col-span-1 !col-span-2 !rounded-10"
                disabled={!isChangingPersonalData}
              /> */}
              <div className="col-span-2 flex flex-col">
                <div className="flex items-center justify-center">
                  <TextInput
                    name="zip_codes"
                    className="!rounded-[10px]"
                    label="Zip codes"
                    value={zip_code}
                    onChange={(e) => {
                      setZipCodeError("");
                      setZipCode(e.target.value);
                    }}
                    disabled={!isChangingPersonalData}
                  />
                  {isChangingPersonalData && (
                    <button
                      type="button"
                      onClick={() => handleAddZipCode(zip_code)}
                      className="text-white ml-2 mt-5 !rounded-[10px] bg-[#F4B10B] px-2 py-1"
                    >
                      Enter
                    </button>
                  )}
                </div>
                <div className="mt-3 flex flex-wrap gap-2">
                  {zipCodeError && (
                    <p className="text-red-500">{zipCodeError}</p>
                  )}
                  {selectedZipCodes?.map((zipCode, index) => (
                    <div
                      key={index}
                      className="text-white flex items-center gap-2 !rounded-[10px] bg-[#F4B10B] px-2 py-1"
                    >
                      <span>{zipCode}</span>
                      {isChangingPersonalData && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent the click from triggering the div's onClick
                            handleRemoveZipCode(zipCode);
                          }}
                          className="text-white cursor-pointer"
                        >
                          <span>&#10005;</span> {/* This is the "X" symbol */}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-span-2 md:col-span-1">
              <SecondInput
                type="text"
                name="experience_years"
                placeholder="Choose years of your experience"
                value={formik.values.experience_years}
                onChange={(e) => {
                  const value = e.target.value;

                  const numericValue = value.replace(/\D/g, "");

                  handleInputSecondChange(e.target.name, numericValue);
                }}
                label="Experience *"
                className="!rounded-10"
                disabled={!isChangingPersonalData}
                maxLength={4}
              />
            </div>

            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="col-span-2 md:col-span-1">
                <Select
                  options={cleaningTypes}
                  value={cleaningTypesData}
                  getOptionLabel={(option: ICleaningType) => option.name}
                  getOptionValue={(option: ICleaningType) => option.id}
                  // onChange={(value) => handleInputChange("cleaning_types", value)}
                  onChange={(value) => {
                    const selectedValues = Array.isArray(value)
                      ? value
                      : [value];

                    setField("cleaning_type_ids", value);
                    setCleaningTypesData(selectedValues);
                  }}
                  isMulti
                  headLabel="Type of cleaning *"
                  placeholder="Write here"
                  isDisabled={!isChangingPersonalData}
                />
              </div>
            )}

            <div className="col-span-1 flex flex-col gap-6 lg:col-span-2">
              <div className="">
                <div className="">
                  {isChangingPersonalData ? (
                    <FileUploader
                      value={user.license}
                      setValue={(value) => {
                        handleInputChange("license", value);
                        setNewLicenseFile(value);
                      }}
                      // setValue={(value) => setField("license", value)}
                      label="Your License"
                      title="Attach your license here"
                      name="License"
                      acceptedFileTypes={["application/pdf"]}
                    />
                  ) : typeof user.license === "string" ? (
                    <Link
                      className="inline-flex items-center gap-2 text-blue-500"
                      to={user.license}
                    >
                      Your License <FileIcon />
                    </Link>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-end gap-4 pr-15">
                <div className="flex items-center gap-1">
                  <Tooltip>
                    <InfoIcon />
                  </Tooltip>
                  <p>Status: </p>
                </div>
                <div className="flex items-center gap-1">
                  <StatusIndicator status={profileStatus} />
                  <p>{formattedStatus}</p>
                </div>
              </div>
            </div>

            <div className="col-span-2">
              {isChangingPersonalData ? (
                <FileUploader
                  value={user.insurance}
                  setValue={(value) => {
                    handleInputChange("insurance", value);
                    setNewInsuranceFile(value);
                  }}
                  // setValue={(value) => setField("insurance", value)}
                  label="Your Insurance"
                  title="Attach your insurance here"
                  name="Insurance"
                  acceptedFileTypes={["application/pdf"]}
                />
              ) : typeof user.insurance === "string" ? (
                <Link
                  className="inline-flex items-center gap-2 text-blue-500"
                  to={user.insurance}
                >
                  Your insurance <FileIcon />
                </Link>
              ) : null}
            </div>
          </div>

          {isChangingPersonalData && (
            <ResetPassword setResetPasswordFields={setResetPasswordFields} />
          )}
          {passwordError && (
            <p className="text-sm text-red-500">{passwordError}</p>
          )}

          {isCleanerType(type) &&
            ((user.team_members && user.team_members.length > 0) ||
              isChangingPersonalData) && <CleanerTeamEditForm />}

          <div className="mt-10 px-4 lg:px-0">
            {isChangingPersonalData ? (
              <div className="flex justify-between">
                <Button
                  onClick={() => handleIsChangingPersonalData(false)}
                  size={Sizes.S}
                  variant={ButtonVariants.CANCEL}
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            ) : (
              <div className="flex justify-end">
                <Button
                  onClick={() => handleIsChangingPersonalData(true)}
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                >
                  Change information
                </Button>
              </div>
            )}
          </div>
        </Form>
      </FormikProvider>

      <ModalWindow
        isOpen={isModalOpenEditable}
        onClose={() => setIsModalOpenEditable(false)}
        isShownCloseButton={false}
        isActiveCloseClickOutside={true}
        className="flex justify-center"
      >
        <ModalPrimaryTemplate
          className="w-[375px] lg:w-[700px]"
          onClose={() => setIsModalOpenEditable(false)}
        >
          <div className="flex w-full flex-col items-center">
            <h3>
              You have uncompleted profile! Please complete it! Send all
              documents and fill the form!
            </h3>
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>

      <ChangeEmail
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newEmailAddress={newEmailAddress}
        setNewEmailAddress={setNewEmailAddress}
        setIsConfirmationModalOpen={setIsConfirmModalOpen}
        setPassword={setPassword}
      />
      <ConfirmationModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        newEmailAddress={newEmailAddress}
        setIsNextModalOpen={setIsSuccesModalOpen}
        password={password}
      />
      <EmailSuccess
        isModalOpen={isSuccesModalOpen}
        setIsModalOpen={setIsSuccesModalOpen}
        newEmailAddress={newEmailAddress}
      />
    </>
  );
};
