import { NotificationService } from "src/helpers/notifications";
import { IZipCodes } from "src/@types/users";
import { instance } from "../api-client";

interface IClientUpdateRequest {
  name?: string;
  phone_number?: string;
  state?: string;
  address?: string;
}

export interface ICleanerFullUpdateRequest {
  insurance: File;
  license: File;
  name: string;
  phone_number: string;
  zip_codes: string[];
  // zip_codes: IZipCodes[];

  experience_years: number;
  cleaning_type_ids: number[];
  team_members: {
    name: string;
    experience_years: number;
  }[];
}

export interface ICleanerUpdateRequest {
  name?: string;
  phone_number?: string;
  zip_code?: string;
  experience_years?: number;
  bio?: string;
  cleaning_type_ids?: number[];
  team_members?: { name: string; experience_years: number }[];
}

export interface ErrorResponse {
  response: {
    data: any;
  };
}

export interface ICreateCleanerSchedules {
  weekday: number;
  start_time: string;
  end_time: string;
}

export interface IResetPasswordRequest {
  password: string;
  old_password: string;
}

export interface ICreateReviewParams {
  rating: number;
  comment: string;
}

export const clientServices = {
  async updateClient(clientData: IClientUpdateRequest) {
    const { data } = await instance.patch("api/clients/me", clientData);

    return data;
  },

  async updatePassword(data: IResetPasswordRequest) {
    const { old_password, password } = data;
    const { data: responseData } = await instance.post(
      "api/users/password/change",
      {
        old_password,
        password,
      }
    );

    return responseData;
  },

  async getCleanersBookings() {
    const { data } = await instance.get("api/cleaners/me/cleaning-requests");

    return data;
  },

  async rejectCleaningRequest(id: number) {
    try {
      const { data } = await instance.patch(
        `api/cleaners/me/cleaning-requests/${id}/rejected`
      );
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async acceptCleaningRequest(id: number) {
    try {
      const { data } = await instance.patch(
        `api/cleaners/me/cleaning-requests/${id}/accepted`
      );
      NotificationService.success('The request was accepted successfully.')
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async getClientBookings() {
    const { data } = await instance.get("api/clients/me/cleaning-requests");
    console.log("DATA: ", data);
    return data;
  },

  async updateCleaner(cleanerData: ICleanerUpdateRequest) {
    try {
      console.log("cleanerData: ", cleanerData);
      const { data } = await instance.patch("api/cleaners/me", cleanerData);
      NotificationService.success("Cleaner updated successfully");
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async updateCleanerFull(profileData: ICleanerFullUpdateRequest) {
    const formData = new FormData();

    // Добавляем файлы
    formData.append("insurance", profileData.insurance);
    formData.append("license", profileData.license);

    // Добавляем остальные данные в формате JSON
    formData.append("data", JSON.stringify(profileData));

    try {
      const { data } = await instance.put("api/cleaners/me", formData);
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async getInsurance() {
    const { data } = await instance.get("api/cleaners/me/file/insurance");

    return data;
  },

  async updateInsurance(body: FormData) {
    const { data } = await instance.patch(
      "api/cleaners/me/file/insurance",
      body
    );

    return data;
  },

  async getSignedDocuments() {
    try {
      const { data } = await instance.get("api/cleaners/has-signed-documents");
      return true; // Если статус 200, документы подписаны
    } catch (error) {
      if (error.response?.status === 403) {
        return false; // Если статус 403, документы не подписаны
      }
      throw error; // Если другая ошибка, выбрасываем её
    }
  },

  async getLicense() {
    const { data } = await instance.get("api/cleaners/me/file/license");

    return data;
  },

  async updateLicense(body: FormData) {
    const { data } = await instance.patch("api/cleaners/me/file/license", body);

    return data;
  },

  async getClientsSubscriptions() {
    const { data } = await instance.get("api/subscriptions/clients");

    return data;
  },

  async getCleanersSubscriptions() {
    const { data } = await instance.get("api/subscriptions/cleaners");

    return data;
  },

  async getCleanerSchedules() {
    const { data } = await instance.get("/api/cleaners/me/schedules");

    return data;
  },

  async createCleanerSchedules(body: ICreateCleanerSchedules) {
    const { data } = await instance.post("/api/cleaners/me/schedules", body);

    return data;
  },

  async updateCleanerSchedules(weekday: number, body: ICreateCleanerSchedules) {
    const { data } = await instance.patch(
      `/api/cleaners/me/schedules/${weekday}`,
      body
    );

    return data;
  },

  async deleteCleanerSchedules(weekday: number) {
    const { data } = await instance.delete(
      `/api/cleaners/me/schedules/${weekday}`
    );

    return data;
  },

  async deleteCleaner() {
    const { data } = await instance.delete(`api/cleaners/delete-account`);

    return data;
  },

  async deleteClients() {
    const { data } = await instance.delete(`api/clients/delete-account`);

    return data;
  },

  async getReviewsToCleaner(cleaner_id: number) {
    const { data } = await instance.get(
      `/api/cleaners/${cleaner_id}/reviews/received`
    );

    return data;
  },

  async getReviewsToClient(client_id: number) {
    const { data } = await instance.get(
      `/api/clients/${client_id}/reviews/received`
    );
    return data;
  },

  async createReviewFromCleaner(request_id: number, body: ICreateReviewParams) {
    try {
      const { data } = await instance.post(
        `/api/cleaners/me/cleaning-requests/${request_id}/review`,
        body
      );
      NotificationService.success('Review submitted successfully.')
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async createReviewFromClient(request_id: number, body: ICreateReviewParams) {
    try {
      const { data } = await instance.post(
        `/api/clients/me/cleaning-requests/${request_id}/review`,
        body
      );
      NotificationService.success('Review submitted successfully.')
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async getReviewFromCLeaner(request_id: number) {
    try {
      const { data } = await instance.get(
        `api/cleaners/${request_id}/reviews/given`
      );
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },

  async getReviewsFromCLient(request_id: number) {
    try {
      const { data } = await instance.get(
        `api/clients/${request_id}/reviews/given`
      );
      return data;
    } catch (error: ErrorResponse | any) {
      NotificationService.error(error.response.data.detail);
    }
  },
};

export default clientServices;
