import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import useClientStore from "src/store/client-store";
import useFileStore from "src/store/file-store";
import { Avatar } from "src/components/Avatar";
import { Button } from "src/components/Button";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import { Sizes } from "src/@types/sizes";

export const StatusIndicator: FC<{ status: string }> = ({ status }) => {
  const statusColors: Record<string, string> = {
    UNCOMPLETED_PROFILE: "bg-gray-400",
    UNSIGNED_AGREEMENT: "bg-yellow-400",
    ON_MODERATION: "bg-blue-400",
    FAILED_MODERATION: "bg-red-400",
    UNSUBSCRIBED: "bg-purple-400",
    PUBLISHED: "bg-green-400",
  };

  const colorClass = statusColors[status] || "bg-gray-400";

  return <div className={`h-3 w-3 rounded-full ${colorClass}`} />;
};

export const AccountProfileHeader: FC = () => {
  const navigate = useNavigate();
  const { user, isChangingPersonalData, getClient } = useClientStore(
    (state) => state
  );
  const { response, uploadFile } = useFileStore((state) => state);
  const subscription = useClientStore((state) => state.subscription);
  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(
    user?.avatar_url
  );

  const profileStatus = user?.profile_status || "UNCOMPLETED_PROFILE";

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        uploadFile(file);
        setAvatarUrl(URL.createObjectURL(file));
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div className="mt-10 flex flex-row items-center justify-start gap-3 px-4 lg:flex-col lg:px-0">
      <div className="relative">
        <Avatar sizeVariant={Sizes.M} avatarUrl={avatarUrl} />
        <div className="absolute bottom-2 right-0">
          <StatusIndicator status={profileStatus} />
        </div>
        {isChangingPersonalData && (
          <label className="absolute bottom-0 right-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
            <EditIcon className="h-5 w-5 text-gray-600" />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleAvatarChange}
            />
          </label>
        )}
      </div>

      <Button
        className="gap-2 text-yellow-base"
        onClick={() => navigate(PATHNAMES.SUBSCRIPTION)}
      >
        <span className="text-[16px] font-[600]">{subscription}</span>
        <EditIcon />
      </Button>
    </div>
  );
};
