import React, { FC } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CleanerCard } from "./CleanerCard";
import { TEMP_CLEANER_ITEMS } from "./temp/contants";

export const OurTeamList: FC = () => (
  <>
    <div className="mt-12.5 w-full lg:mt-0">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        className="lg:hidden"
      >
        {TEMP_CLEANER_ITEMS.map(({ id, ...card }) => (
          <SwiperSlide key={id}>
            <CleanerCard {...card} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div className="hidden gap-6 lg:flex">
      {TEMP_CLEANER_ITEMS.map(({ id, ...card }) => (
        <CleanerCard key={id} {...card} />
      ))}
    </div>
  </>
);
