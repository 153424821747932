import { FC } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useAuthStore from "src/store/auth-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { RESET_PASSWORD_FORM_VALIDATION_SCHEMA } from "../account/personal-data/ResetPassword/constants";
import {
  AUTH_FORM_VALIDATION_SCHEMA,
  RESET_PASSWORD_FORM_FIELDS,
  RESET_PASSWORD_INITIAL_VALUES,
} from "./constant";
import { IFormikValues } from "./types";

interface Props {
  token: string;
}

export const ResetPasswordForm: FC<Props> = ({ token }) => {
  const navigate = useNavigate();

  const { resetPassword } = useAuthStore();

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: RESET_PASSWORD_INITIAL_VALUES,
    validationSchema: AUTH_FORM_VALIDATION_SCHEMA,

    onSubmit: (values) => {
      resetPassword(values.new_password, token);
      navigate(PATHNAMES.SIGN_IN);
    },
  };

  const formik = useFormik(formikProps);

  return (
    <div>
      <FormikProvider value={formik}>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-4">
            <Form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-6"
            >
              <div className="flex flex-col gap-6">
                <RenderFormFields fields={RESET_PASSWORD_FORM_FIELDS} />
                {formik.errors.new_password && (
                  <div className="mt-[-15px] text-red-500">
                    {formik.errors.new_password}
                  </div>
                )}
              </div>

              <Button
                className="w-full"
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                type="submit"
              >
                Reset Password
              </Button>
            </Form>

            <p className="text-center">
              Back to
              <Link className="ml-1 text-yellow-base" to={PATHNAMES.SIGN_IN}>
                Login
              </Link>
            </p>
          </div>
        </div>
      </FormikProvider>
    </div>
  );
};
