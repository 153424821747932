import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { StatusIndicator } from "src/page-components/account/personal-data/AccountProfileHeader";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { IProfileStatusLabels, Users } from "src/@types/users";
import { ReactComponent as ArrowDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as LogoutSvg } from "../../assets/icons/logout.svg";
import { Avatar } from "../Avatar";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";

const NAVIGATION_LINKS = [
  { id: 1, label: "Home", href: PATHNAMES.HOME },
  { id: 2, label: "About Us", href: PATHNAMES.ABOUT },
  { id: 3, label: "Services", href: PATHNAMES.SERVICES },
  { id: 4, label: "Subscriptions", href: PATHNAMES.SUBSCRIPTION },
];

export const ActionsButtons: FC = () => {
  const { user, type } = useClientStore((state) => state);
  const getActiveUser = useAuthStore((state) => state.getActiveUser);

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      setIsAuthorized(user.id !== 0);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getActiveUser();
  }, [getActiveUser]);

  const { logout } = useAuthStore((state) => state);
  const { getClient, clearClient } = useClientStore((state) => state);

  const userType = useState(Users.CLEANER);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLIENT });
  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const formatStatus = (status: string) => {
    return (
      IProfileStatusLabels[status as keyof typeof IProfileStatusLabels] ||
      status
    );
  };

  const profileStatus = user?.profile_status || "UNCOMPLETED_PROFILE"; // Используйте реальное поле статуса

  const formattedStatus = formatStatus(profileStatus);

  const scroll = () => {
    const topElement = document.getElementById("services-section");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex w-full flex-col items-center gap-2 lg:flex-row lg:items-center lg:justify-start">
      {!isAuthorized || type === Users.CLIENT ? (
        <Link
          to={bookNowHref}
          onClick={scroll}
          className="flex w-full justify-center lg:w-auto"
        >
          <Button
            size={Sizes.S}
            variant={ButtonVariants.OPACITY_PRIMARY}
            className="w-full !text-yellow-400 lg:w-auto lg:!text-white-base"
          >
            Book Now
          </Button>
        </Link>
      ) : null}
      <div className="flex w-full items-center gap-2 lg:w-auto">
        <div
          className={`text- relative w-full items-center gap-2 lg:flex ${isAuthorized ? "lg:absolute lg:w-[105px]" : "lg:w-auto"}`}
          ref={dropdownRef}
        >
          <Link
            to={cleanWithUsHref}
            className={`flex w-full justify-center lg:w-auto ${isAuthorized && isMobile ? "hidden" : ""}`}
          >
            <Button
              size={Sizes.S}
              variant={
                !isAuthorized ? ButtonVariants.OPACITY_PRIMARY : undefined
              }
              className={`${isAuthorized ? "pr-2" : ""} w-full !text-yellow-400 lg:w-auto lg:!text-white-base`}
            >
              {isAuthorized ? (
                <div className="relative flex items-center gap-2">
                  <Avatar avatarUrl={user?.avatar_url} />
                  <div className="absolute bottom-2 right-0 top-0">
                    <StatusIndicator status={profileStatus} />
                  </div>
                </div>
              ) : (
                <span>Clean With Us</span>
              )}
            </Button>
          </Link>
          {!isAuthorized && (
            <Link
              to={PATHNAMES.SIGN_IN}
              className="flex w-full justify-center text-[14px] font-bold text-yellow-base underline lg:flex lg:w-auto lg:text-white-base"
            >
              <Button size={Sizes.S}>Login</Button>
            </Link>
          )}
          {/* </div> */}

          {isAuthorized && (
            <button
              onClick={handleClick}
              className="flex items-center focus:outline-none"
              aria-expanded={isOpen}
              aria-haspopup="true"
            >
              <ArrowDown
                className={`transform transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
              />
            </button>
          )}
          <div
            className={`absolute right-0 top-16 w-[200px] ${isOpen && isAuthorized ? "block" : "hidden"} z-[9999]`}
            style={{
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              border: "1px solid #D0D5DD",
              borderRadius: "0.5rem",
            }}
          >
            <div className="flex flex-col py-2">
              <div className="py-2">
                <div className="flex gap-3 px-4">
                  <Avatar avatarUrl={user?.avatar_url} />
                  <div>
                    <p className="text-[16px] font-bold text-[#F4B10B]">
                      {user.name}
                    </p>
                    <p className="font-montserrat text-[14px] font-normal">
                      {type?.charAt(0).toUpperCase() + type?.slice(1)}
                    </p>
                  </div>
                </div>

                {user && (
                  <div className="aling-center flex flex-wrap items-center gap-2 px-4 py-2">
                    <p>Status: </p>
                    <span>{formattedStatus}</span>
                    <StatusIndicator status={profileStatus} />
                  </div>
                )}
              </div>
              {/* {NAVIGATION_LINKS.map((link) => (
              <Link
                key={link.id}
                to={link.href}
                className="px-4 py-2 text-lg text-gray-700 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                {link.label}
              </Link>
            ))} */}
              <div className="my-2" />
              <Link
                to={PATHNAMES.ACCOUNT}
                className="border-t-[1px] border-t-[#D0D5DD] px-4 py-2 text-lg text-gray-700 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                Your Account
              </Link>
              <button
                className="flex gap-1 border-t-[1px] border-t-[#D0D5DD] px-4 py-2 text-left text-lg hover:bg-gray-50"
                onClick={() => {
                  logout();
                  clearClient();
                }}
              >
                <LogoutSvg />
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsButtons;
