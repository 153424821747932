import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import clientServices from "src/services/client-service";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { RequestItem } from "src/page-components/account/bookings/RequestItem";
import { RequestItem as ClientrequestItem } from "src/page-components/account/bookings/RequestItem";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as RatingStarIcon } from "src/assets/icons/star-rating.svg";
import CleanerImage from "src/assets/images/cleaner-item2.png";
import { RequestStatus } from "src/@types/request";
import { Users } from "src/@types/users";

const Bookings: FC = () => {
  const {
    data: bookings,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["clientBookings"],
    queryFn: () => {
      return clientServices.getClientBookings();
    },
  });
  const navigate = useNavigate();

  const [showHistory, setShowHistory] = useState(true);
  // const { isOpenModal, openModal, closeModal } = useModal(false);

  const toggleHistory = () => {
    setShowHistory((prev) => !prev);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  console.log("Bookings: ", bookings);

  return (
    <div className="pt-[54px]">
      {isLoading && <div>Loading...</div>}

      {isSuccess && (
        <div className="flex flex-col gap-10">
          <div className="flex w-full justify-between p-2 lg:hidden">
            <Title title="Booking" subtitle="" />
            <p onClick={handleBackClick}>Back</p>
          </div>

          {bookings && bookings.length > 0 ? (
            <>
              <div className="flex flex-col gap-6">
                <h2 className="text-xl font-semibold capitalize leading-[37.20px] tracking-tight text-gray-strong lg:text-2xl">
                  Booking in progress
                </h2>

                <div className="flex flex-col gap-6">
                  {bookings
                    .filter(
                      (bookings) =>
                        bookings.status === RequestStatus.IN_PROGRESS
                    )
                    .map((booking) => (
                      <RequestItem key={booking.id} booking={booking} />
                    ))}
                </div>
              </div>

              <div className="flex flex-col gap-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-semibold capitalize leading-[37.20px] tracking-tight text-gray-strong">
                    Booking History
                  </h2>
                  <button
                    onClick={toggleHistory}
                    className="text-sm font-medium text-blue-500 hover:underline"
                  >
                    <ArrowIcon />
                  </button>
                </div>

                {showHistory && (
                  <div className="flex flex-col gap-6">
                    {bookings
                      .filter(
                        (booking) => booking.status === RequestStatus.DONE
                      )
                      .map((booking) => (
                        <RequestItem key={booking.id} booking={booking} />
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500">
              You currently have no bookings yet. Complete your profile and
              submit a request to get started on your first cleaning service!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Bookings;
