import React, { FC, useEffect, useState } from "react";
import { useQueries } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import { SubscriptionServices } from "src/services/subscription-service";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import useSubscriptionStore from "src/store/subscription-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import { USER_PLANS_ITEMS } from "./constants";
import { PlanItem } from "./PlanItem";
import RateInformation from "./RateInformation";

interface Props {
  activeUser: Users;
}

export const SubscriptionPlans: FC<Props> = ({ activeUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePlanId, setActivePlanId] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState(null);

  const fetchUserSubscription = useSubscriptionStore(
    (state) => state.fetchUserSubscription
  );
  const userSubscription = useSubscriptionStore(
    (state) => state.userSubscription
  );
  const createSubscription = SubscriptionServices.createSubscription;
  const cancelSubscription = useSubscriptionStore(
    (state) => state.cancelSubscription
  );

  const getActiveUser = useAuthStore((state) => state.getActiveUser);
  const activeUserInfo = useAuthStore((state) => state.activeUserInfo);

  const queries = useQueries({
    queries: [
      {
        queryKey: ["CleanersSubscriptions"],
        queryFn: clientServices.getCleanersSubscriptions,
      },
      {
        queryKey: ["ClientsSubscriptions"],
        queryFn: clientServices.getClientsSubscriptions,
      },
    ],
  });

  const [CleanersSubscriptionsData, ClientsSubscriptionsData] = queries.map(
    (query) => query.data
  );

  const [CleanersSubscriptionsFetching, ClientsSubscriptionsFetching] =
    queries.map((query) => query.isFetching);

  const plans =
    activeUser === "cleaner"
      ? CleanersSubscriptionsData
      : ClientsSubscriptionsData;

  const handleOnClickPlan = (id: number) => {
    setActivePlanId(id);
  };

  const handleCreateSubscription = async (id: number) => {
    try {
      const link = await createSubscription({ plan_id: id });
      window.location.href = link.approval_url;
    } catch (error) {
      console.error("Error creating subscription:", error);
    } finally {
      fetchUserSubscription();
    }
  };

  useEffect(() => {
    const activeSubscription = Array.isArray(userSubscription)
      ? userSubscription.find((sub) => sub.status === "active")
      : null;
    setActiveSubscriptions(activeSubscription);
  }, [userSubscription]);

  const handleChangePlan = async (id: number) => {
    if (activeSubscriptions) {
      cancelSubscription(activeSubscriptions.id);
      createSubscription({ plan_id: id });
    }
  };

  useEffect(() => {
    getActiveUser();
  }, [getActiveUser]);

  return (
    <div className="w-full flex-1">
      {CleanersSubscriptionsFetching || ClientsSubscriptionsFetching ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col gap-3">
          {plans.map((plan, index) => (
            <PlanItem
              key={plan.id}
              isActive={activePlanId === plan.id}
              isEven={index % 2 === 0}
              onClick={handleOnClickPlan}
              {...plan}
            />
          ))}
        </div>
      )}

      <div className="mt-10 flex flex-wrap-reverse gap-4 lg:flex-nowrap">
        <Button
          className="w-full"
          size={Sizes.S}
          variant={ButtonVariants.OPACITY_PRIMARY}
          onClick={() => setIsModalOpen(true)}
        >
          Rate Information
        </Button>
        <Button
          className="w-full"
          size={Sizes.S}
          variant={ButtonVariants.PRIMARY}
          onClick={() => handleCreateSubscription(activePlanId)}
          isDisabled={activeUserInfo?.user?.has_active_subscription}
        >
          Get Started
        </Button>

        {/* {activeUserInfo?.user?.has_active_subscription ? (
          <Button
            className="w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            onClick={() => handleChangePlan(activePlanId)}
          >
            Change plan
          </Button>
        ) : (
          <Button
            className="w-full"
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            onClick={() => handleCreateSubscription(activePlanId)}
          >
            Get Started
          </Button>
        )} */}
      </div>

      <ModalWindow
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isShownCloseButton={false}
      >
        <ModalPrimaryTemplate onClose={() => setIsModalOpen(false)}>
          <RateInformation />
        </ModalPrimaryTemplate>
      </ModalWindow>
    </div>
  );
};
