import React from "react";
import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";

const CallToAction = () => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const { type } = useClientStore((state) => state);

  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLIENT });

  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  const scroll = () => {
    const topElement = document.getElementById("services-section");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="p-5 lg:hidden lg:p-0">
        <section className="container flex flex-col items-center gap-10 rounded-lg bg-about-us-cta bg-cover bg-center bg-no-repeat py-[76px] lg:rounded-none">
          <div className="flex flex-col items-center gap-2">
            <h3 className="max-w-[716px] text-center text-[56px] font-medium capitalize leading-[78.40px] !text-white-base">
              Lorem ipsum dolor sit amet consectetur
            </h3>
            <p className="max-w-[588px] text-center text-base font-normal leading-7 !text-white-base">
              Whether you need a deep clean or regular maintenance, we’re
              dedicated to creating a pristine environment that enhances your
              well-being.
            </p>
          </div>

          <div className="flex items-center gap-4">
            <Button size={Sizes.S} variant={ButtonVariants.PRIMARY}>
              Book a Cleaner Now
            </Button>

            <Button size={Sizes.S} variant={ButtonVariants.OPACITY_PRIMARY}>
              Become A Cleaner With Us
            </Button>
          </div>
        </section>
      </div>
      <section className="container flex flex-col items-center gap-10 rounded-lg bg-about-us-cta bg-cover bg-center bg-no-repeat py-[76px] lg:rounded-none">
        <div className="flex flex-col items-center gap-2">
          <h3 className="max-w-[716px] text-center text-[56px] font-medium capitalize leading-[78.40px] !text-white-base">
            Lorem ipsum dolor sit amet consectetur
          </h3>
          <p className="max-w-[588px] text-center text-base font-normal leading-7 !text-white-base">
            Whether you need a deep clean or regular maintenance, we’re
            dedicated to creating a pristine environment that enhances your
            well-being.
          </p>
        </div>

        <div className="flex items-center gap-4">
          {!(isAuthorized && type === "cleaner") && (
          <Link to={bookNowHref} onClick={scroll}>
            <Button size={Sizes.S} variant={ButtonVariants.PRIMARY}>
              Book a Cleaner Now
            </Button>
          </Link>
          )}

          <Link to={cleanWithUsHref} onClick={scroll}>
            <Button size={Sizes.S} variant={ButtonVariants.OPACITY_PRIMARY}>
              Become A Cleaner With Us
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default CallToAction;
