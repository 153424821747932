import React, { FC, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { SectionHeader } from "src/components/SectionHeader";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { OurServicesList } from "./OurServicesList";

export const OurServices: FC = () => {
  const navigate = useNavigate();

  const handleServicesClick = () => {
    const topElement = document.getElementById("services-section");
    if (topElement) {
      topElement.scrollIntoView({ behavior: "smooth" });
    }
    navigate(PATHNAMES.SERVICES);
  };

  return (
    <section>
      <div className="container pb-18 pt-25">
        <div className="flex items-center justify-between">
          <SectionHeader
            title="Services We Provide"
            subtitle="Our Services"
            description="Faucibus commodo a aenean et sit quisque ipsum. Consequat eu id ut
              dolor felis quis. Sagittis a sapien pulvinar etiam."
          />
          <div className="hidden lg:block">
            <Button
              variant={ButtonVariants.PRIMARY}
              size={Sizes.S}
              onClick={handleServicesClick}
            >
              All Services
            </Button>
          </div>
        </div>
        <OurServicesList />
        <div className="block lg:hidden">
          <Button
            variant={ButtonVariants.PRIMARY}
            size={Sizes.S}
            className="w-full"
            onClick={handleServicesClick}
          >
            All Services
          </Button>
        </div>
      </div>
    </section>
  );
};
