import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import NumberedRating from "src/components/Rating/NumberedRating";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import NoAvatar from "src/assets/images/noAvatar.png";
import { ICleaningRequest, RequestStatus } from "src/@types/request";
import { Sizes } from "src/@types/sizes";

interface Props {
  request: ICleaningRequest;
  refetch: () => void;
  onSelectCleaner?: () => void;
  onClickDetail?: () => void;
  viewMode?: boolean;
}

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`; //${day}-${month}-${year}
};

const RequestItem: FC<Props> = ({
                                  request,
                                  refetch,
                                  onClickDetail,
                                  viewMode = false,
                                }) => {
  const price = request?.minimum_hours * request?.hourly_rate;
  const { user } = useClientStore((state) => state);
  const emailAddress = request?.client?.email;

  const formattedDate = request.date
    ? formatDate(request.date)
    : "Unknown Date";

  const { data: reviews, isSuccess: isSuccessReviews } = useQuery({
    queryKey: ["clientReviews"],
    queryFn: () => clientServices.getReviewFromCLeaner(user?.id),
  });

  const hasReview =
    isSuccessReviews &&
    reviews?.some((review) => review.cleaning_id === request.id);

  const handleAcceptCleanerRequest = async (id: number) => {
    console.log("Accept cleaner request: ", id);
    await clientServices.acceptCleaningRequest(id);
    refetch();
  };

  const handleRejectCleanerRequest = async (id: number) => {
    console.log("Reject cleaner request: ", id);
    await clientServices.rejectCleaningRequest(id);
    refetch();
  };

  return (
    <>
      {/* MOBILE */}
      <div
        className="flex items-start justify-between gap-6 border-b border-[#D1D1DC] pb-6 pl-5 lg:hidden"
        onClick={viewMode ? undefined : onClickDetail}
      >
        <img
          src={request?.client?.avatar_url || NoAvatar}
          className="h-18 w-18"
          alt="cleaner"
        />
        <div className="flex min-w-[255px] flex-1 gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex flex-col items-start gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                {request.cleaning_type.name}
              </div>
              <div className="flex flex-col items-start justify-between">
                <div className="flex items-center gap-2">
                  <NumberedRating rating={request?.client?.rating} />
                  <p>Client: {request?.client?.name}</p>
                </div>
                <div>Address: {request?.address}</div>
                <div>{formattedDate}</div>
              </div>
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                ${price}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            {request?.status === RequestStatus.PENDING && (
              <>
                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                  className="w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewMode
                      ? onClickDetail && onClickDetail()
                      : handleAcceptCleanerRequest(request.id);
                  }}
                >
                  {viewMode ? "View" : "Accept"}
                </Button>
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRejectCleanerRequest(request.id);
                  }}
                  width={50}
                  height={50}
                />
              </>
            )}
            {request?.status === RequestStatus.IN_PROGRESS && (
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
                onClick={() => {
                  window.location.href = `mailto:${emailAddress}`;
                }}
              >
                Contact
              </Button>
            )}
            {(request?.status === RequestStatus.DONE ||
                request?.status === RequestStatus.DECLINED) &&
              !hasReview && (
                <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE} state={{ request }}>
                  <Button
                    size={Sizes.S}
                    variant={ButtonVariants.OPACITY_PRIMARY}
                    className="w-full"
                  >
                    Review
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
      {/* DESKTOP */}
      <div
        onClick={viewMode ? undefined : onClickDetail}
        className="hidden items-center justify-between gap-6 border-b border-[#D1D1DC] pb-6 lg:flex"
      >
        <img
          src={request?.client?.avatar_url || NoAvatar}
          className="h-18 w-18"
          alt="cleaner"
        />
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex items-center gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                {request.cleaning_type.name}
              </div>
            </div>
            <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
              ${price}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <NumberedRating rating={request?.client?.rating} />
              <p>Client: {request?.client?.name}</p>
            </div>
            <div>Address: {request?.address}</div>
            <div>{formattedDate}</div>
          </div>
        </div>
        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            {request?.status === RequestStatus.PENDING && (
              <>
                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                  className="w-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewMode
                      ? onClickDetail && onClickDetail()
                      : handleAcceptCleanerRequest(request.id);
                  }}
                >
                  {viewMode ? "View" : "Accept"}
                </Button>
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRejectCleanerRequest(request.id);
                  }}
                  width={50}
                  height={50}
                />
              </>
            )}
            {request?.status === RequestStatus.IN_PROGRESS && (
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
                onClick={() => {
                  window.location.href = `mailto:${emailAddress}`;
                }}
              >
                Contact
              </Button>
            )}
            {(request?.status === RequestStatus.DONE ||
                request?.status === RequestStatus.DECLINED) &&
              !hasReview && (
                <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE} state={{ request }}>
                  <Button
                    size={Sizes.S}
                    variant={ButtonVariants.OPACITY_PRIMARY}
                    className="w-full"
                  >
                    Review
                  </Button>
                </Link>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestItem;
