import { withFormikField } from "src/hoc/withFormikField";
import { TextInput } from "src/ui/TextInput";
import * as Yup from "yup";
import { PASSWORD_VALIDATION_SCHEMA } from "src/constants/formValidation";
import { IRenderFormField } from "src/@types/form";
import { IFormikValues } from "./types";

const LABEL_CLASSNAME = "font-medium text-gray-base";

export const RESET_PASSWORD_INITIAL_VALUES: IFormikValues = {
  new_password: "",
};

const FormikTextInput = withFormikField(TextInput);

export const RESET_PASSWORD_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "new_password",
    type: "password",
    label: "New password",
    component: FormikTextInput,
    labelClassName: LABEL_CLASSNAME,
  },
];

export const AUTH_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  new_password: PASSWORD_VALIDATION_SCHEMA,
});
