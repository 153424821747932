import React, { FC, ReactNode } from "react";
import cn from "classnames";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";

interface Props {
  titleClassName?: string;
  textClassName?: string;
  className?: string;
  title: string;
  subtitle: string;
  description?: string | ReactNode;
  isCenter?: boolean;
}

export const SectionHeader: FC<Props> = ({
  className,
  titleClassName,
  textClassName,
  title,
  subtitle,
  description,
  isCenter,
}) => (
  <div
    className={cn("flex flex-col gap-3.5", className, {
      "items-start lg:items-center": isCenter,
    })}
  >
    <div className="flex items-start gap-5 lg:items-center">
      <StarIcon />
      <span className="uppercase text-yellow-base">{subtitle}</span>
    </div>

    <h2
      className={cn(
        titleClassName,
        "text-start text-[28px] lg:text-center lg:text-[46px]"
      )}
    >
      {title}
    </h2>

    {description && (
      <p
        className={cn(textClassName, "text-start lg:text-center", {
          "text-center": isCenter,
          "max-w-122.5": !isCenter,
        })}
      >
        {description}
      </p>
    )}
  </div>
);
