import React, { FC, useEffect, useState } from "react";
import useSubscriptionStore from "src/store/subscription-store";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { SubscriptionSection } from "src/components/Subscription";

const Subscription: FC = () => {
  const isLoading = useSubscriptionStore((state) => state.isLoading);

  return (
    <PageWrapper>
      {isLoading && <div>Loading...</div>}
      <SubscriptionSection />
    </PageWrapper>
  );
};

export default Subscription;
