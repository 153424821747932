import React, { FC, useEffect } from "react";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useBookCleaningStore from "src/store/book-cleaning-store";
import { TextInput } from "src/components/FormField/TextInput";
import { StepControlsProps } from "../../types";
import { Title } from "../Title";
import {
  FILLING_FORM_VALIDATION_SCHEMA,
  FILLING_INITIAL_VALUES,
  TIME_OPTIONS,
} from "./constants";
import Select from "./Sekect";
import { IFormikDataAndTimeValues } from "./types";

const FIELD_CLASSNAME = "!rounded-[10px] border-gray-ligther";

export const DateAndTime: FC<StepControlsProps> = ({ title, subtitle }) => {
  const { updateField, date, timeFrom, timeTo, additional_notes } =
    useBookCleaningStore();

  const formikProps: FormikConfig<IFormikDataAndTimeValues> = {
    initialValues: FILLING_INITIAL_VALUES,
    validationSchema: FILLING_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {},
  };

  const formik = useFormik(formikProps);

  const handleInputChange = (fieldName: string, value: any) => {
    updateField(fieldName, value);
  };

  return (
    <div className="flex flex-col gap-10">
      <Title title={title} subtitle={subtitle} />

      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-6">
          <div className="flex flex-col items-start justify-between gap-6 lg:flex-row lg:items-center">
            <div className="w-full flex-1">
              <TextInput
                type="date"
                className={FIELD_CLASSNAME}
                placeholder="Choose cleaning date"
                label="Date *"
                name="date"
                value={date}
                onChange={(e) => handleInputChange("date", e.target.value)}
              />
            </div>

            <div
              className={`flex flex-1 flex-col items-center gap-2 ${
                timeFrom > timeTo ? "mt-5" : "mt-0"
              }`}
            >
              <div className="flex flex-col gap-5 lg:flex-row">
                <Select
                  name="timeFrom"
                  options={TIME_OPTIONS}
                  value={timeFrom}
                  onChange={(option) => {
                    handleInputChange("timeFrom", option);
                    formik.setFieldValue("timeFrom", option);
                  }}
                  placeholder="From"
                  label="From *"
                  className="w-[300px] lg:w-auto"
                />
                <Select
                  name="timeTo"
                  options={TIME_OPTIONS}
                  value={timeTo}
                  onChange={(option) => {
                    handleInputChange("timeTo", option);
                    formik.setFieldValue("timeTo", option);
                  }}
                  placeholder="To"
                  label="To *"
                  className="w-[300px] lg:w-auto"
                />
              </div>

              {Number(timeFrom) > Number(timeTo) && (
                <p className="text-red text-xs font-semibold">Invalid time</p>
              )}
            </div>
          </div>

          <TextInput
            className={`${FIELD_CLASSNAME} min-h-[115px]`}
            placeholder="Message"
            isTextArea
            label="Additional comments"
            name="additional_notes"
            value={additional_notes}
            onChange={(e) =>
              handleInputChange("additional_notes", e.target.value)
            }
          />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default DateAndTime;
