import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { ClientDetails } from "src/page-components/requests/ClientDetails";
import RequestItem from "src/page-components/requests/RequestItem";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { useModal } from "src/hooks/useModal";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ICleaningRequest, RequestStatus } from "src/@types/request";

export const Requests: FC = () => {
  const clearClient = useClientStore((state) => state.clearClient);
  const [detailRequest, setDetailRequest] = useState<ICleaningRequest | null>(null);
  const { user } = useClientStore((state) => state);

  const {
    data: requests,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["clientRequests"],
    queryFn: () => clientServices.getCleanersBookings(),
  });

  const { data: reviews, isLoading: isLoadingReviews, isSuccess: isSuccessReviews } = useQuery({
    queryKey: ["clientReviews"],
    queryFn: () => clientServices.getReviewFromCLeaner(user?.id),
  });

  const navigate = useNavigate();
  const [showHistory, setShowHistory] = useState(true);
  const { isOpenModal, openModal, closeModal } = useModal(false);

  const toggleHistory = () => {
    setShowHistory((prev) => !prev);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDetailsCleaner = (request: ICleaningRequest) => {
    console.log("SELECTED CLEANER: ", request);
    setDetailRequest(request);
    openModal();
  };

  return (
    <div className="pt-[54px]">
      {isLoading && <div>Loading...</div>}

      {isSuccess && (
        <div className="flex flex-col gap-10">
          <div className="flex w-full justify-between p-2 lg:hidden">
            <Title title="Booking" subtitle="" />
            <p onClick={handleBackClick}>Back</p>
          </div>

          {requests && requests.length > 0 ? (
            <>
              <div className="flex flex-col gap-6">
                <h2 className="text-xl font-semibold capitalize leading-[37.20px] tracking-tight text-gray-strong lg:text-2xl">
                  New requests
                </h2>
                <div className="flex flex-col gap-6">
                  {requests
                    .filter((booking) => booking.status === RequestStatus.PENDING)
                    .map((booking) => (
                      <RequestItem
                        key={booking.id}
                        request={booking}
                        refetch={refetch}
                        viewMode={true}
                        onClickDetail={() => handleDetailsCleaner(booking)}
                      />
                    ))}
                </div>
              </div>

              {/* In Progress */}
              <div className="flex flex-col gap-6">
                <h2 className="text-xl font-semibold capitalize leading-[37.20px] tracking-tight text-gray-strong lg:text-2xl">
                  In progress
                </h2>
                <div className="flex flex-col gap-6">
                  {requests
                    .filter((booking) => booking.status === RequestStatus.IN_PROGRESS)
                    .map((booking) => (
                      <RequestItem
                        key={booking.id}
                        request={booking}
                        refetch={refetch}
                        onClickDetail={() => handleDetailsCleaner(booking)}
                      />
                    ))}
                </div>
              </div>

              {/* Request History */}
              <div className="flex flex-col gap-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-semibold capitalize leading-[37.20px] tracking-tight text-gray-strong">
                    Request History
                  </h2>
                  <button
                    onClick={toggleHistory}
                    className="text-sm font-medium text-blue-500 hover:underline"
                  >
                    <ArrowIcon />
                  </button>
                </div>
                {showHistory && (
                  <div className="flex flex-col gap-6">
                    {requests
                      .filter((booking) => booking.status === RequestStatus.DONE)
                      .map((booking) => (
                        <RequestItem
                          key={booking.id}
                          request={booking}
                          refetch={refetch}
                          onClickDetail={() => handleDetailsCleaner(booking)}
                        />
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500">
              You currently have no bookings yet. Complete your profile and submit a request to get started on your first cleaning service!
            </div>
          )}
        </div>
      )}

      <ModalWindow
        isShownCloseButton={false}
        isOpen={isOpenModal}
        className="flex justify-center"
        onClose={closeModal}
      >
        <ModalPrimaryTemplate
          className="!max-w-[1400px] items-center justify-center p-4"
          onClose={closeModal}
        >
          <div className="min-w-[350px] lg:min-w-[800px]">
            <ClientDetails request={detailRequest} refetch={refetch} closeModal={closeModal} />
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </div>
  );
};
