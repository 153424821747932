import { FC } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import ForgetPasswordForm from "src/page-components/forget-password/ForgetPasswordForm";
import { ResetPasswordForm } from "src/page-components/reset-password";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Logo } from "src/components/Logo";
import { PATHNAMES } from "src/constants/routes";

export const ResetPassword: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  return (
    <PageWrapper isShownFooter={false} isShownHeader={false}>
      <section className="flex h-screen flex-col justify-between bg-registration-banner bg-contain bg-no-repeat lg:hidden">
        <div className="flex min-h-[210px] items-start justify-between bg-[#00000033] px-4 py-3">
          <Logo />
          <Link
            to={PATHNAMES.HOME}
            className="mr-2 mt-5 text-lg font-bold text-white-base"
          >
            &larr; Back to Home
          </Link>
        </div>

        <div className="flex-1 rounded-t-3xl bg-[#FBFBFB] px-6 pt-5 shadow-lg">
          <h1 className="text-center text-xl font-bold text-gray-800">
            Reset Your Password
          </h1>
          <p className="mt-2 text-center text-base text-gray-600">
            Enter your new password below to regain access to your account.
          </p>
          <ResetPasswordForm token={token} />
          <div className="mt-5 flex flex-col gap-3">
            <p className="text-center text-base font-normal leading-normal text-[#666666] lg:text-start">
              Having trouble resetting your password?
            </p>

            <ul className="list-disc pl-5 text-base font-normal leading-normal text-[#666666]">
              <li>Make sure you’re using the correct reset link.</li>
              <li>Check your spam or junk folder for the email.</li>
              <li>
                If you need further help, reach out to our
                <Link className="ml-1 text-yellow-base" to={PATHNAMES.HOME}>
                  support team&nbsp;
                </Link>
                for assistance.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="hidden h-full bg-registration-banner bg-cover bg-no-repeat lg:block">
        <div className="z-20 flex h-full w-1/2 flex-col justify-between rounded-r-40 bg-white-base px-20 pb-[100px] pt-8">
          <Logo />

          <h1 className="text-2xl font-bold text-gray-800">
            Reset Your Password
          </h1>
          <p className="mt-2 text-base text-gray-600">
            Enter your new password below to regain access to your account.
          </p>
          <ResetPasswordForm token={token} />

          <div className="flex flex-col gap-10">
            <div className="h-[1px] w-full bg-gray-base" />

            <div className="flex flex-col gap-3">
              <p className="text-base font-normal leading-normal text-[#666666]">
                Having trouble resetting your password?
              </p>

              <ul className="list-disc pl-5 text-base font-normal leading-normal text-[#666666]">
                <li>Make sure you’re using the correct reset link.</li>
                <li>Check your spam or junk folder for the email.</li>
                <li>
                  If you need further help, reach out to our
                  <Link className="ml-1 text-yellow-base" to={PATHNAMES.HOME}>
                    support team&nbsp;
                  </Link>
                  for assistance.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};
