import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import authServices, {
  IResendEmailVerificationBody,
} from "src/services/auth-services";
import useAuthStore from "src/store/auth-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Confirmation } from "src/components/ModalWindow/templates/Confirmation";
import { Sizes } from "src/@types/sizes";

const EmailVerification = () => {
  const { email } = useAuthStore((state) => state);
  const { data, mutate } = useMutation({
    mutationFn: (data: IResendEmailVerificationBody) =>
      authServices.resendEmailVerevi(data),
  });

  const [timer, setTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (timer === 0) {
      setIsButtonDisabled(false);
      return;
    }

    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    mutate({ email });
  }, []);

  const handleButtonClick = () => {
    mutate({ email });

    setIsButtonDisabled(true);
    setTimer(60);
  };

  return (
    <div className="flex w-full flex-col items-center gap-4 text-center !item-center">
      <h2 className="text-[28px] font-medium leading-[44.80px] text-gray-strong lg:text-[32px]">
        Verify Your Email Address
      </h2>
      <p className="text-center text-base font-normal leading-tight text-black-third">
        We now need to verify your email address. We've sent an email to your
        email. Please check your inbox to verify your address. Please click the
        link in that email to continue.
      </p>

      {timer !== 0 && (
        <p className="text-lg font-semibold text-gray-500">
          Time remaining: {timer}s
        </p>
      )}

      <div className="mt-5 flex items-center gap-4">
        <Button
          size={Sizes.S}
          variant={ButtonVariants.PRIMARY}
          onClick={handleButtonClick}
          isDisabled={isButtonDisabled}
        >
          Resend link
        </Button>
      </div>
    </div>
  );
};

export default EmailVerification;
