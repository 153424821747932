import React, { FC, useEffect, useState } from "react";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import useSubscriptionStore from "src/store/subscription-store";
import { Users } from "src/@types/users";
import { ModalWindow } from "../ModalWindow";
import ModalPrimaryTemplate from "../ModalWindow/templates/ModalPrimaryTemplate";
import { SectionHeader } from "../SectionHeader";
import { AvailableServices } from "./AvailableServices";
import { CurrentSubscription } from "./CurrentSubscription";
import { ServicePlan } from "./ServicePlan";
import { ToggleButton } from "./ToggleButton";

export const SubscriptionSection: FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const isLoading = useSubscriptionStore((state) => state.isLoading);
  const [isChecking, setIsChecking] = useState(false);

  const [activeUser, setActiveUser] = useState(Users.CLIENT);

  const getActiveUser = useAuthStore((state) => state.getActiveUser);
  const activeUserInfo = useAuthStore((state) => state.activeUserInfo);
  const activeUserSubscription = activeUserInfo?.user?.active_subscription;

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);

    const url = new URL(window.location.href);
    url.searchParams.delete("success");
    window.history.replaceState({}, document.title, url.pathname);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");

    if (success === "true" || success === "True") {
      setMessage("Subscription is underway...");
      setIsModalOpen(true); // Открываем модальное окно
      setIsChecking(true);
      const interval = setInterval(async () => {
        getActiveUser();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [getActiveUser]);

  useEffect(() => {
    if (isChecking && activeUserSubscription?.status === "active") {
      setIsChecking(false);
      setIsModalOpen(false); // Закрываем модалку, если подписка активна
      setIsSuccessModalOpen(true);
    }
  }, [activeUserSubscription, isChecking]);

  // const fetchUserSubscription = useSubscriptionStore(
  //   (state) => state.fetchUserSubscription
  // );

  // const activeUserSubscription = useSubscriptionStore(
  //   (state) => state.activeUserSubscription
  // );

  useEffect(() => {
    getActiveUser();
  }, [getActiveUser]);

  useEffect(() => {
    if (activeUserInfo) {
      setActiveUser(activeUserInfo.type);
    }
  }, [activeUserInfo]);

  return (
    <section>
      <div className="container py-24">
        <CurrentSubscription userSubscriptions={activeUserSubscription} />
        <div className="flex flex-col items-start gap-6 lg:items-center">
          <SectionHeader
            title="Choose the Right Plan for You"
            subtitle="Pricing Table"
            description="3 weeks of cleaning for only $19.99. Book as many times as you like. Cancel anytime."
            isCenter
          />

          {/* <ToggleButton activeUser={activeUser} setActiveUser={setActiveUser} /> */}
        </div>

        <ServicePlan activeUser={activeUser} />
        <AvailableServices />
      </div>
      <ModalWindow
        isOpen={isSuccessModalOpen}
        onClose={() => {}}
        isShownCloseButton={false}
        className="w-[600px]"
      >
        <ModalPrimaryTemplate
          onClose={() => {
            handleCloseSuccessModal();
          }}
        >
          <div>Subscription connected successfully</div>
        </ModalPrimaryTemplate>
      </ModalWindow>
      <ModalWindow
        isOpen={isModalOpen}
        onClose={() => {}}
        isShownCloseButton={false}
        className="w-[600px]"
        isActiveCloseClickOutside={false}
      >
        <ModalPrimaryTemplate
          onClose={() => setIsModalOpen(false)}
          isShownCloseButton={false}
        >
          <div>{message}</div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </section>
  );
};
