import React, { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import {
  Account,
  AvailableCleaners,
  BookCleaning,
  Bookings,
  ForgetPassword,
  Home,
  PersonalData,
  Review,
  SignIn,
  SignUp,
  Subscription,
} from "src/pages";
import AboutUs from "src/pages/AboutUs";
import CleanerCalendar from "src/pages/Account/CleanerCalendar";
import CreateReview from "src/pages/Account/CreateReview";
import { Requests } from "src/pages/Account/Requests";
import BookingDetails from "src/pages/BookingDetails";
import { ResetPassword } from "src/pages/ResetPassword";
import Services from "src/pages/Services";
import useClientStore from "src/store/client-store";
import { PrivateRoute } from "src/components/PrivateRoute";
import { PATHNAMES } from "src/constants/routes";

const ROUTES = [
  {
    element: <Home />,
    path: PATHNAMES.HOME,
  },
  {
    element: <AboutUs />,
    path: PATHNAMES.ABOUT,
  },

  {
    element: <Services />,
    path: PATHNAMES.SERVICES,
  },
  {
    element: <PrivateRoute component={Subscription} />,
    path: PATHNAMES.SUBSCRIPTION,
  },
  {
    element: <PersonalData />,
    path: PATHNAMES.ACCOUNT_PERSONAl_DATA_MOBILE,
  },
  {
    element: <Bookings />,
    path: PATHNAMES.ACCOUNT_BOOKINGS_MOBILE,
  },
  {
    element: <Requests />,
    path: PATHNAMES.ACCOUNT_REQUESTS_MOBILE,
  },
  {
    element: <CleanerCalendar />,
    path: PATHNAMES.ACCOUNT_CALENDAR_MOBILE,
  },
  {
    element: <Review />,
    path: PATHNAMES.ACCOUNT_REVIEW_MOBILE,
  },
  {
    element: <CreateReview />,
    path: PATHNAMES.ACCOUNT_REVIEW_CREATE_MOBILE,
  },
  {
    element: <PrivateRoute component={Account} />,
    path: PATHNAMES.ACCOUNT,
    children: [
      {
        element: <PersonalData />,
        index: true,
      },
      {
        element: <PersonalData />,
        path: PATHNAMES.ACCOUNT_PERSONAl_DATA,
      },
      {
        element: <CleanerCalendar />,
        path: PATHNAMES.ACCOUNT_CALENDAR,
      },
      {
        element: <Bookings />,
        path: PATHNAMES.ACCOUNT_BOOKINGS,
      },
      {
        element: <Requests />,
        path: PATHNAMES.ACCOUNT_REQUESTS,
      },
      {
        element: <Review />,
        path: PATHNAMES.ACCOUNT_REVIEW,
      },
      {
        element: <CreateReview />,
        path: PATHNAMES.ACCOUNT_REVIEW_CREATE,
      },
    ],
  },
  {
    element: <BookCleaning />,
    path: PATHNAMES.BOOK_CLEANING,
  },
  {
    element: <SignUp />,
    path: PATHNAMES.SIGN_UP,
  },
  {
    element: <SignIn />,
    path: PATHNAMES.SIGN_IN,
  },
  {
    element: <ForgetPassword />,
    path: PATHNAMES.FORGET_PASSWORD,
  },
  {
    element: <ResetPassword />,
    path: PATHNAMES.RESET_PASSWORD,
  },
  {
    element: <AvailableCleaners />,
    path: PATHNAMES.AVAILABLE_CLEANERS,
  },
  {
    element: <BookingDetails />,
    path: PATHNAMES.BOOKING_DETAILS,
  },
];

const AppRoutes: FC = () => {
  const getClient = useClientStore((state) => state.getClient);

  useEffect(() => {
    getClient();
  }, []);

  return useRoutes(ROUTES);
};

export default AppRoutes;
