import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import bookingServices from "src/services/booking-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import useBookCleaningStore from "src/store/book-cleaning-store";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { TEXT_INPUT_STYLE_VARIANTS } from "../FormField/constants";

interface Props {
  className?: string;
  onSearch?: () => void;
  value: string;
  customZipCode?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const Search: FC<Props> = ({
  className,
  value,
  customZipCode,
  onChange,
  onSearch,
}) => {
  const { cleaning_type, team_size, date, zip_code, timeFrom, timeTo } =
    useBookCleaningStore();
  const isAuthorized = useClientStore((state) => state.isAuthorized);

  const {
    data: availableCleanersData,
    isFetching,
    isFetched,
    refetch,
    refetch: refetchGetAvailableCleaners,
  } = useQuery<IAvailableCleaner[]>({
    queryKey: ["getAvailableCleaners", zip_code],
    queryFn: async () => {

      if (!customZipCode) return []; // Если zip-код пустой, возвращаем пустой массив

      if (!isAuthorized) {
        return bookingServices.getCleanersByZipCode(customZipCode);
      } else {
        if (cleaning_type.id && team_size.value && date && timeFrom && timeTo) {
          const dateWithoutTimezone = new Date(
            `${date}T${timeFrom.padStart(2, "0")}:00:00Z`
          ).toISOString();
          const dateWithoutTimezoneTo = new Date(
            `${date}T${timeTo.padStart(2, "0")}:00:00Z`
          ).toISOString();



          return bookingServices.getAvailableCleaners({
            zip_code: customZipCode, // Используем только customZipCode
            cleaning_type_id: cleaning_type.id,
            date_from: dateWithoutTimezone,
            date_to: dateWithoutTimezoneTo,
            team_size: team_size.value,
          });
        }
      }
    },
    enabled: !!zip_code, // Запрос запускается только если zip_code есть
  });

  const handleSearchClick = () => {
    // onSearch()
    // debugger;
    if (onSearch) {
      onSearch();
    }
    // refetchGetAvailableCleaners();
  };

  return (
    <div className={cn("group flex w-full flex-col sm:flex-row", className)}>
      <input
        className={cn(
          TEXT_INPUT_STYLE_VARIANTS.primary,
          "w-full lg:rounded-r-none"
        )}
        placeholder="Enter your postal code"
        value={value}
        onChange={onChange}
      />

      <Link to={PATHNAMES.AVAILABLE_CLEANERS}>
        <Button
          className="mt-2 w-full min-w-56 group-active:translate-y-0 sm:mt-0 sm:-translate-x-20 lg:min-w-35"
          variant={ButtonVariants.PRIMARY}
          size={Sizes.S}
          onClick={handleSearchClick}
        >
          Search
        </Button>
      </Link>
    </div>
  );
};
