import { TextInput } from "src/ui/TextInput";
import * as Yup from "yup";
import {
  EMAIL_VALIDATION_SCHEMA,
  PASSWORD_VALIDATION_SCHEMA,
} from "src/constants/formValidation";

const LABEL_CLASSNAME = "font-medium text-gray-base";

export const NEW_EMAIL_INITIAL_VALUES = {
  password: "",
  new_email: "",
};

export const NEW_EMAIL_FORM_FIELDS = [
  {
    name: "password",
    type: "password",
    label: "Current password",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
  },
  {
    name: "new_email",
    type: "email",
    label: "New email",
    component: TextInput,
    labelClassName: LABEL_CLASSNAME,
  },
];

export const NEW_EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  new_email: EMAIL_VALIDATION_SCHEMA,
});
