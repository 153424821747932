import React from "react";
import Select from "react-select";

export interface ITimeInputProps {
  id?: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

// Функция для генерации списка времени с шагом в 30 минут
const generateTimeOptions = () => {
  const options = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 30) {
      // шаг в 30 минут
      const hour = h % 12 === 0 ? 12 : h % 12; // для 12 часов
      const period = h < 12 ? "AM" : "PM"; // AM/PM
      const time = `${hour.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")} ${period}`;
      options.push({ value: time, label: time });
    }
  }
  return options;
};

const timeOptions = generateTimeOptions();

const TimeInput = ({
  id,
  label,
  value,
  onChange,
  disabled,
}: ITimeInputProps) => {
  console.log("VALUEEE");
  return (
    <div className="flex flex-col">
      {label && (
        <label
          htmlFor={id}
          className="mb-2 block text-sm font-medium text-gray-900"
        >
          {label}
        </label>
      )}
      <Select
        id={id}
        options={timeOptions}
        value={timeOptions.find((option) => option.value === value)}
        onChange={(selectedOption) => onChange(selectedOption?.value || "")}
        isDisabled={disabled}
        className="w-full"
      />
    </div>
  );
};

export default TimeInput;
