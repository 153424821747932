import React, { FC } from "react";
import cn from "classnames";
import { ReactComponent as StarIcon } from "src/assets/icons/star-rating.svg";

interface INumberedRatingProps {
  className?: string;
  starClassName?: string;
  numberClassName?: string;
  rating: number;
}

const NumberedRating: FC<INumberedRatingProps> = ({
                                                    rating,
                                                    starClassName,
                                                    numberClassName,
                                                    className,
                                                  }) => {
  const safeRating = rating || 0;

  const displayRating = safeRating.toFixed(1).replace(".", ",");

  return (
    <div className={cn("flex items-center gap-1", className)}>
      <StarIcon className={cn("h-5 w-5", starClassName)} />
      <span className={cn("text-sm font-normal leading-5", numberClassName)}>
        {displayRating}
      </span>
    </div>
  );
};

export default NumberedRating;
