import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import clientServices, {
  ICreateReviewParams,
} from "src/services/client-service";
import useClientStore from "src/store/client-store";
import * as Yup from "yup";
import BlockTitle from "src/page-components/account/BlockTitle";
import RatingPicker from "src/page-components/account/review/RatingPicker";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { TextInput } from "src/components/FormField/TextInput";
import { Header } from "src/components/Header";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";

const REVIEW_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  rating: Yup.number()
    .min(1, "Rating is required")
    .required("Rating is required"),
  cleaner: Yup.string().required("Cleaner is required"),
  // comment: Yup.string().required("Comment is required"),
});

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const CreateReview: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const request = location.state?.request || location.state?.booking;
  const { type } = useClientStore((state) => state);

  const isBooking = !!location.state?.booking;
  const entity = isBooking ? request.cleaner : request.client;

  const { cleaning_type, date } = request;

  const formikProps: FormikConfig<any> = {
    initialValues: {
      rating: null,
      cleaner: entity?.name || "",
      comment: "",
    },
    validationSchema: REVIEW_FORM_VALIDATION_SCHEMA,
    validateOnMount: true,
    onSubmit: (values) => {
      //form logic
    },
  };

  const formik = useFormik(formikProps);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSaveReview = () => {
    const fullData: ICreateReviewParams = {
      rating: formik.values.rating,
      comment: formik.values.comment,
    };

    if (type === Users.CLIENT) {
      clientServices.createReviewFromClient(request.id, fullData);
    } else {
      clientServices.createReviewFromCleaner(request.id, fullData);
    }
    handleBackClick();
  };

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div className="flex w-full items-center justify-between px-2">
        <Title title="Review" subtitle="" />
        <p onClick={handleBackClick}>Back</p>
      </div>
      <FormikProvider value={formik}>
        <Form>
          <div className="flex flex-col gap-10 px-5 pt-[54px] lg:px-0">
            <BlockTitle
              title={`${type === Users.CLIENT ? "Rate your Cleaner" : "Rate your Client"}`}
            />

            <div className="flex items-start justify-between">
              <RatingPicker
                rating={formik.values.rating}
                setRating={(value) => formik.setFieldValue("rating", value)}
              />

              <div className="flex flex-col gap-1 text-sm font-normal capitalize leading-snug tracking-tight text-gray-strong">
                <div>{cleaning_type.name}</div>
                <div>{formatDate(date)}</div>
              </div>
            </div>

            <TextInput
              disabled
              className="!rounded-[10px]"
              label={`${type === Users.CLIENT ? "Your Cleaner" : "Your Client"}`}
              placeholder="Write your review here"
              name="cleaner"
            />

            <TextInput
              className="min-h-[120px] !rounded-[10px]"
              isTextArea
              label="Comment"
              placeholder="Write your review here"
              name="comment"
            />

            <div className="self-end">
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                onClick={handleSaveReview}
                isDisabled={!formik.isValid}
              >
                Save Review
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <div className="mt-5 block lg:hidden">
        <Footer />
      </div>
    </>
  );
};

export default CreateReview;
